import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Checkbox,
  tableCellClasses,
  InputLabel,
  FormControl,
  TableCell,
  Paper,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import InputField from "components/Input";
import { useForm } from "react-hook-form";
import { ErrorToaster } from "components/Toaster";
import moment from "moment";
import ClientServices from "services/Client";
import { Check, Close } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import FinanceServices from "services/Finance";
import { SuccessToaster } from "components/Toaster";
import { useNavigate } from "react-router-dom";
import CurrencyServices from "services/Currency";
import { useSelector } from "react-redux";
import { CircleLoading } from "components/Loaders";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExportServices from "services/Export";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CommaSeparator, handleExportWithComponent } from "utils";
import { PDFExport } from "@progress/kendo-react-pdf";
import ExportPDF from "components/Pdf/ExportPdf";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    padding: "15px",
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function ExportShippingSOA() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { usdExchangeRate, cadExchangeRate } = useSelector(
    (state) => state.navigationReducer
  );
  let TotalBalance = 0;
  // *For Total Amount
  const [totalAmount, setTotalAmount] = useState([]);

  const [allSums, setAllSums] = useState();

  // *For Client Dropdown
  const [selectedClient, setSelectedClient] = useState(null);

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // *for Payment Type

  const [paymentType, setPaymentType] = useState("aed");

  const [totalVCC, settotalVCC] = useState();

  // *For Paid Status
  const [selectedStatus, setSelectedStatus] = useState(null);

  // *For Cashier Account
  const [cashierAccounts, setCashierAccounts] = useState([]);
  const [selectedCashierAccount, setSelectedCashierAccount] = useState(null);

  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const [shippingSOA, setShippingSOA] = useState();

  const [shippingVault, setShippingVault] = useState();
  const [vehicleVault, setVehicleVault] = useState();
  // const [vehicleStatus, setVehicleStatus] = useState('')
  // const [particularStatus, setParticularStatus] = useState('')

  // *For Vin and Lot
  const [selectedVin, setSelectedVin] = useState();
  const [selectedLot, setSelectedLot] = useState();

  // *For Filters
  const [filters, setFilters] = useState({});
  const [mergedOptions, setMergedOptions] = useState([]);
  const [agentOptions, setAgentOptions] = useState([]);
  const [brokerOptions, setBrokerOptions] = useState([]);

  const [totalBalance, setTotalBalance] = useState(0);

  // *For Currencies
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState();
  const [particularStatus, setParticularStatus] = useState('Vehicle');
  const [vehicleStatus, setVehicleStatus] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);

  // Vehicle-specific statuses
  const vehicleStatuses = [
    { id: false, name: "Booked" },
    { id: false, name: "With GWS Yard" },
    { id: false, name: "At GWS Port" },
    { id: false, name: "Loaded By GWS" },
    { id: false, name: "Broker Port" },
    { id: false, name: "Broker Border" },
    { id: false, name: "On The Way to Destination" },
    { id: false, name: "Arrived At Dist." },
    { id: false, name: "Handover" },
  ];

  // Container-specific statuses
  const containerStatuses = [
    { id: false, name: "Booked" },
    { id: false, name: "With GWS Yard" },
    { id: false, name: "At GWS Port" },
    { id: false, name: "Loaded By GWS" },
    { id: false, name: "Handover" },
  ];

  // Handle Particular (Vehicle/Container) selection
  const handleParticularSelect = (value) => {
    setParticularStatus(value);
    setVehicleStatus('');
    if (value?.name === "Vehicle") {
      setStatusOptions(vehicleStatuses);
      setVisibleColumns(vehicleTableHead)
    } else if (value?.name === "Container") {
      setStatusOptions(containerStatuses);
      setVisibleColumns(containerTableHead)

    }
  };
  // *for button Disable
  const [showTable, setShowTable] = useState(false);
  const contentRef = useRef(null);

  const classes = useStyles();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue,
    getValues: getValues2,
    formState: { errors: errors2 },
    reset,
  } = useForm();

  const vehicleTableHead = [
    "SL. No",
    "Invoice #",
    "CMR Date",
    "CMR No.",
    "Model",
    "Make",
    "Vin/Container.#",
    "COLOR",
    "Status",
    "Final Destination",
    // "Final Dest.Date",
    "SHIPPING CHARGE",
    "Other Charges",
    "Discount",
    "Net Due",
    "Paid Amount",
    "Balance Due",
    "Final Paid Date",
    "Past Due Days"
  ];

  const containerTableHead = [
    "SL. No",
    "Invoice #",
    "Vin/Container.#",
    "Status",
    "Final Destination",
    "SHIPPING CHARGE",
    "Other Charges",
    "Discount",
    "Net Due",
    "Paid Amount",
    "Balance Due",
    "Final Paid Date",
    "Past Due Days"
  ];

  const [visibleColumns, setVisibleColumns] = useState([
    ...Array(vehicleTableHead?.length).keys(),
  ]);
  let NoOfVCC = 0;

  ///For get Customers
  const getCustomers = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        name: search,
      };
      const { data } = await ExportServices.getExportCustomers(params);
      const customerOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...customerOptions]);
      // setCustomersOptions(data?.customers?.rows);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*For get Broker
  const getBroker = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "2",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setBrokerOptions(data?.customers?.rows);
      const brokerOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...brokerOptions]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  //*For get Agents
  const getAgent = async (search) => {
    try {
      let params = {
        page: 1,
        limit: 100,
        search: search,
        broker_type: "1",
      };
      const { data } = await ExportServices.getExportCustomers(params);
      setAgentOptions(data?.customers?.rows);
      const agentOptions = data?.customers?.rows || [];
      setMergedOptions((prev) => [...prev, ...agentOptions]);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Currencies
  const getCurrencies = async (currency) => {
    try {
      let params = {
        detailed: true,
      };
      const { data } = await CurrencyServices.getCurrencies(params);

      setCurrencyExchangeRate(data.currencies[2].conversion_rate);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Client Costing
  const getClientCosting = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
        invoice: true,
      };
      params = { ...params, ...Filter };
      const { data } = await ExportServices.getExportVehicles(params);
      settotalVCC(0);
      let totalShippingCharges = 0;
      let totalLateFee = 0;
      let totalStorage = 0;
      let totalCategoryA = 0;
      let totalBrokerFee = 0;
      let totalTitleFee = 0;
      let totalOtherCharges = 0;
      let totalCustomDuty = 0;
      let totalVAT5Percent = 0;
      let totalReceivingAmount = 0;
      let subtotal = 0;
      let total = 0;
      let balance = 0;

      // Iterate through the array and accumulate the shipping charges

      data?.vehicles?.rows.forEach((item) => {
        if (item?.vehicle?.vcc) {
          NoOfVCC++;
        }
        settotalVCC(NoOfVCC);
        totalShippingCharges += parseFloat(item?.shipping_charges);
        totalLateFee += parseFloat(item?.late_fee);
        totalStorage += parseFloat(item?.storage);
        totalCategoryA += parseFloat(item?.category_a);
        totalBrokerFee += parseFloat(item?.broker_fee);
        totalTitleFee += parseFloat(item?.title_fee);
        totalOtherCharges += parseFloat(item?.other_charge);
        totalCustomDuty += parseFloat(item?.custom_duty);
        totalVAT5Percent += parseFloat(item?.vat);
        totalReceivingAmount += parseFloat(item?.receiving_amount);
        subtotal += parseFloat(item?.subtotal);
        balance +=
          parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
        total += parseFloat(item?.total);
      });
      setTotalBalance(balance);
      setAllSums(balance);

      setShippingSOA(data?.vehicles?.rows);

      setTotalCount(data?.vehicles?.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  // *For Apply Filters
  const applyFilter = async () => {
    try {
      let data = {
        customer_id: selectedClient?.id,
        status: selectedStatus?.id,
        vin: selectedVin?.id,
        lot: selectedLot?.id,
        container: getValues("container"),
      };

      getClientCosting(1, "", data);
      getVaultDashboard(1, "", { vendor_id: selectedClient?.id });
    } catch (error) {
      ErrorToaster(error);
    }
  };
  // *For Get Vault Dashboard
  const getVaultDashboard = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
      };
      params = { ...params, ...Filter };
      const {
        data: { detail },
      } = await FinanceServices.getVehicleSumLedger(params);
      setShippingVault(
        detail?.rows[0].accounts?.find(
          (account) =>
            account.type_code === "L2" && account.primary_series === 50005
        )
      );
      setVehicleVault(
        detail?.rows[0].accounts?.find(
          (account) =>
            account.type_code === "L2" && account.primary_series === 50004
        )
      );
      setTotalCount(detail?.count);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  const sortData = (e, type, item) => {
    e.preventDefault();
    if (type === "ascending" && item == "Buyer ID") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.buyer?.name.localeCompare(b.booking?.buyer?.name);
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "Buyer ID") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.buyer?.name.localeCompare(a.booking?.buyer?.name);
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "Model") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.veh_model?.name.localeCompare(
          b.booking?.veh_model?.name
        );
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "Model") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.veh_model?.name.localeCompare(
          a.booking?.veh_model?.name
        );
      });

      setShippingSOA(sortedData);
    }
    if (type === "ascending" && item == "Make") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.veh_make?.name.localeCompare(
          b.booking?.veh_make?.name
        );
      });

      setShippingSOA(sortedData);
    }
    if (type === "descending" && item == "Make") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.veh_make?.name.localeCompare(
          a.booking?.veh_make?.name
        );
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item === "LOT") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        const costA = parseFloat(a.booking?.lot_number) || 0;
        const costB = parseFloat(b.booking?.lot_number) || 0;
        return costA - costB;
      });

      setShippingSOA(sortedData);
    }
    if (type === "descending" && item === "LOT") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        const costA = parseFloat(a.booking?.lot_number) || 0;
        const costB = parseFloat(b.booking?.lot_number) || 0;
        return costB - costA;
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "VIN") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.vin.localeCompare(b.booking?.vin);
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "VIN") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.vin.localeCompare(a.booking?.vin);
      });

      setShippingSOA(sortedData);
    }

    if (type === "ascending" && item == "Color") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return a.booking?.color.localeCompare(b.booking?.color);
      });

      setShippingSOA(sortedData);
    }

    if (type === "descending" && item == "Color") {
      const sortedData = [...shippingSOA].sort((a, b) => {
        // Use the localeCompare method for string comparison
        return b.booking?.color.localeCompare(a.booking?.color);
      });

      setShippingSOA(sortedData);
    }
  };

  const handleColumnChange = (event) => {
    const selectedColumns = event.target.value;
    // Sort the selected columns to maintain the correct order
    const sortedColumns = selectedColumns.sort((a, b) => a - b);
    setVisibleColumns(sortedColumns);
  };

  const renderCellContent = (colIndex, item, isActive, rowIndex) => {
    TotalBalance +=
      parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
    switch (colIndex) {
      case 0:
        return rowIndex + 1;
      case 1:
        return item && parseFloat(item?.paymentDetails?.amount || 0) > 0
          ? `GSI${item?.id}`
          : `GEVOTW${item?.id}`
      case 2:
        return item?.cmr_date
          ? moment(item?.cmr_date || item?.cmr_date).format("DD-MMM-YYYY")
          : "-";
      case 3:
        return item?.cmr_number ?? "-";
      case 4:
        return item?.model?.name ?? "-";
      case 5:
        return item?.make.name ?? "-";
      case 6:
        return item?.vin ?? "-";
      case 7:
        return item?.color ?? "-";
      case 8:
        return item?.status?.name ?? "-";
      case 9:
        return item?.destination?.name ?? "-";
      // case 10:
      //   return item?.eta
      //     ? moment(item?.eta || item?.created_at).format("DD-MMM-YYYY")
      //     : "-";
      case 10:
        return CommaSeparator(parseFloat(item?.price).toFixed(2)) ?? "-";
      case 11:
        return CommaSeparator(parseFloat(item?.other_charges).toFixed(2)) ?? "-";
      case 12:
        return CommaSeparator(parseFloat(item?.discount).toFixed(2)) ?? "-";
      case 13:
        return CommaSeparator(parseFloat(item?.price).toFixed(2)) ?? "-";
      case 14:
        return <b>USD {CommaSeparator(parseFloat(item?.vendor_paid).toFixed(2))}</b>;
      case 15:
        return (
          <b>
            USD{" "}
            {CommaSeparator(parseFloat(
              parseFloat(item?.final_price) - parseFloat(item?.vendor_paid)
            ).toFixed(2))}
          </b>
        );
      default:
        return "-";
      case 16:
        return item?.date
          ? moment(item?.date || item?.created_at).format("DD-MMM-YYYY")
          : "-";
      case 17:
        if (item?.date) {
          const daysPast = moment().diff(moment(item?.date), 'days');
          return `${daysPast} days past`;
        } else {
          return "-";
        }
    }
  };

  const downloadExcel = () => {
    const headers = vehicleTableHead;
    const rows = shippingSOA?.map((item, colIndex) => {
      TotalBalance +=
        parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
      return [
        colIndex + 1,
        item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "-",
        item?.model?.name ?? "-",
        item?.make.name ?? "-",
        item?.vin ?? "-",
        item?.color ?? "-",
        parseFloat(item?.price).toFixed(2) ?? "-",
        parseFloat(item?.discount).toFixed(2) ?? "-",
        `USD ${parseFloat(item?.vendor_paid).toFixed(2)}`,
        `USD ${parseFloat(
          parseFloat(item?.final_price) - parseFloat(item?.vendor_paid)
        ).toFixed(2)}`,
      ];
    });

    const totalRows = [
      "",
      "",
      "",
      "",
      "",
      "Total Due",
      "",
      "",
      "",
      `USD ${parseFloat(allSums).toFixed(2)}`,
    ];

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows, totalRows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(new Blob([buf]), "data.xlsx");
  };

  useEffect(() => {
    if (paymentType) {
      const cashierDetail = cashierAccounts.find(
        (e) => e.currency === paymentType
      );
      setValue("cash", cashierDetail?.name);
      setSelectedCashierAccount(cashierDetail);
    }
  }, [paymentType]);

  useEffect(() => {
    getCustomers();
    getBroker();
    getAgent();
    getCurrencies();
  }, []);
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box>
          <PDFExport
            ref={contentRef}
            paperSize={["210mm", "400mm"]}
            fileName="ExportShippingSOA"
            dpi={96}
            landscape={true}
            keepTogether=".bottom-box"
            margin={{ bottom: 80 }}
          >
            <Box className="pdf-show" display="none">
              <ExportPDF
                invoiceName="Export Shipping Statement Of Account"
                selectedCustomer={selectedClient}
                mode="landscape"
                // fromdate={fromDate}
                totalBalance={totalBalance}
                // todate={toDate}
                tableHead={vehicleTableHead}
                resultData={shippingSOA}
                selectedStatus={selectedStatus}
                shippingVault={shippingVault}
                vehicleVault={vehicleVault}
              // totalPaid={totalPaid}
              // otherCharges={otherCharges}
              // totalVehicleAmount={totalVehicleAmount}
              // netBalance={allsums}
              // dueBreakup={dueBreakup}
              />
            </Box>
          </PDFExport>
        </Box>

        <Grid item xs={12} sm={12} md={11}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mr: 4,
              my: 4,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: Colors.charcoalGrey,
                fontFamily: FontFamily.NunitoRegular,
                ml: "5px",
              }}
            >
              Export Shipping SOA
            </Typography>
            {shippingSOA?.length > 0 && (
              <Box >
                <PrimaryButton
                  title="Download PDF"
                  type="button"
                  style={{ backgroundColor: Colors.bluishCyan, marginRight: 8 }}
                  onClick={() => handleExportWithComponent(contentRef)}
                />
                <PrimaryButton
                  title={"Download Excel"}
                  onClick={() => downloadExcel()}
                />

              </Box>
            )}
          </Box>
        </Grid>

        <Grid item md={11}>
          <Box
            sx={{
              m: "20px 0 20px 0",
              p: "20px",
              bgcolor: Colors.feta,
              border: `1px solid ${Colors.iron}`,
              borderRadius: "9px",
            }}
          >
            <Grid
              container
              spacing={1}
              alignItems={"center"}
              component={"form"}
              onSubmit={handleSubmit(applyFilter)}
            >
              <Grid item xs={12} md={3}>
                <SelectField
                  size="small"
                  label={"Select Customer"}
                  options={mergedOptions}
                  selected={selectedClient}
                  onSelect={(value) => {
                    setSelectedClient(value);
                    applyFilter();
                    setShowTable(true);
                  }}
                  error={errors?.client?.message}
                  register={register("client", {
                    required: "Please select client.",
                  })}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <SelectField
                  size="small"
                  label={"Particular"}
                  options={[
                    { id: false, name: "Vehicle" },
                    { id: true, name: "Container" },
                  ]}
                  selected={particularStatus}
                  onSelect={handleParticularSelect}
                  error={errors?.vehicleStatus?.message}
                  register={register("particular")}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SelectField
                  size="small"
                  label={"Vehicle / Container Status"}
                  options={statusOptions}
                  selected={vehicleStatus}
                  onSelect={(value) => setVehicleStatus(value)}
                  error={errors?.vehicleStatus?.message}
                  register={register("vehicleStatus")}
                />
              </Grid>

              <Grid item md={3}>
                <SelectField
                  size="small"
                  label={"Status"}
                  options={[
                    { id: false, name: "All" },
                    { id: true, name: "Paid" },
                    { id: false, name: "Unpaid" },
                  ]}
                  selected={selectedStatus}
                  onSelect={(value) => setSelectedStatus(value)}
                  error={errors?.status?.message}
                  register={register("status")}
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Box
                  sx={{
                    mt: "12px",
                  }}
                >
                  <PrimaryButton type="submit" title="Search" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item md={11}>
          {shippingSOA && (
            <Box>
              <Grid container mb={2}>
                <Grid item xs={5}>
                  <FormControl>
                    <InputLabel>Columns</InputLabel>
                    <Select
                      size={"small"}
                      multiple
                      value={visibleColumns}
                      label={"Columns"}
                      onChange={handleColumnChange}
                      renderValue={() => "Show/Hide"}
                    >
                      {vehicleTableHead.map((column, index) => {
                        if (
                          column !== "Balance" &&
                          column !== "Past Due Days" &&
                          column !== "Paid"
                        ) {
                          return (
                            <MenuItem key={index} value={index}>
                              <Checkbox
                                checked={visibleColumns.includes(index)}
                              />
                              <ListItemText primary={column} />
                            </MenuItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {shippingSOA && (
                <Box>
                  <TableContainer
                    component={Paper}
                    sx={{
                      boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                      borderRadius: 2,
                      maxHeight: "calc(100vh - 330px)",
                    }}
                  >
                    <Table stickyHeader sx={{ minWidth: 500 }}>
                      {/* Table Header */}
                      <TableHead>
                        <TableRow>
                          {visibleColumns.map((index) => (
                            <Cell key={index}>
                              {vehicleTableHead[index]}{" "}
                              {vehicleTableHead[index] == "S.No" ||
                                vehicleTableHead[index] == "Past Due Days" ||
                                vehicleTableHead[index] == "Buy Date" ||
                                vehicleTableHead[index] == "Other Charges" ||
                                vehicleTableHead[index] == "Paid" ||
                                vehicleTableHead[index] == "Balance" ||
                                vehicleTableHead[index] == "SHIPPING CHARGE" ||
                                vehicleTableHead[index] == "LATE FEE" ||
                                vehicleTableHead[index] == "STORAGE" ||
                                vehicleTableHead[index] == "CATEGORY A" ||
                                vehicleTableHead[index] == "BROKER FEE" ||
                                vehicleTableHead[index] == "TITLE FEE" ||
                                vehicleTableHead[index] == "OTHER CHARGE" ||
                                vehicleTableHead[index] == "CUSTOM DUTY" ||
                                vehicleTableHead[index] == "VAT" ||
                                vehicleTableHead[index] == "TOTAL" ||
                                vehicleTableHead[index] == "Discount" ||
                                vehicleTableHead[index] == "Net Due" ||
                                vehicleTableHead[index] == "BUY DATE" ||
                                vehicleTableHead[index] == "Arrived Date" ||
                                vehicleTableHead[index] == "SL. No" ||
                                vehicleTableHead[index] == "VCC" ? (
                                ""
                              ) : (
                                <>
                                  {" "}
                                  <ArrowUpwardIcon
                                    sx={{
                                      color: "white",
                                      fontSize: "15px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      sortData(e, "ascending", vehicleTableHead[index])
                                    }
                                  />{" "}
                                  <ArrowDownwardIcon
                                    sx={{
                                      color: "white",
                                      fontSize: "15px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) =>
                                      sortData(
                                        e,
                                        "descending",
                                        vehicleTableHead[index]
                                      )
                                    }
                                  />{" "}
                                </>
                              )}
                            </Cell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* Table Body */}
                      <TableBody>
                        {!loader ? (
                          shippingSOA?.length > 0 ? (
                            <Fragment>
                              {shippingSOA.map((item, rowIndex) => {
                                const isActive = true;
                                return (
                                  <Row
                                    key={rowIndex}
                                    sx={{
                                      bgcolor: rowIndex % 2 !== 0 && "#EFF8E7",
                                    }}
                                  >
                                    {visibleColumns.map((colIndex) => (
                                      <Cell key={colIndex}>
                                        {renderCellContent(
                                          colIndex,
                                          item,
                                          isActive,
                                          rowIndex
                                        )}
                                      </Cell>
                                    ))}
                                  </Row>
                                );
                              })}
                              <Row>
                                <Cell colSpan={visibleColumns?.length - 1}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    Total Due
                                  </Typography>
                                </Cell>
                                <Cell>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    USD {CommaSeparator(parseFloat(allSums).toFixed(2))}
                                  </Typography>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell colSpan={visibleColumns?.length - 5}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    Net Due Total
                                  </Typography>
                                </Cell>

                                <Cell colSpan={3}>
                                  <Typography
                                    variant="body1"
                                    sx={{ fontWeight: 700 }}
                                  >
                                    USD{" "}
                                    {CommaSeparator(parseFloat(
                                      parseFloat(totalBalance) -
                                      parseFloat(
                                        shippingVault?.nature === "credit"
                                          ? isNaN(
                                            parseFloat(
                                              shippingVault?.total_credit
                                            ) -
                                            parseFloat(
                                              shippingVault?.total_debit
                                            )
                                          )
                                            ? parseFloat(0).toFixed(2)
                                            : parseFloat(
                                              parseFloat(
                                                shippingVault?.total_credit
                                              ) -
                                              parseFloat(
                                                shippingVault?.total_debit
                                              )
                                            ).toFixed(2)
                                          : isNaN(
                                            parseFloat(
                                              shippingVault?.total_debit
                                            ) -
                                            parseFloat(
                                              shippingVault?.total_credit
                                            )
                                          )
                                            ? parseFloat(0).toFixed(2)
                                            : parseFloat(
                                              parseFloat(
                                                shippingVault?.total_debit
                                              ) -
                                              parseFloat(
                                                shippingVault?.total_credit
                                              )
                                            ).toFixed(2)
                                      )
                                    ).toFixed(2))}
                                  </Typography>
                                </Cell>
                                <Cell></Cell>
                              </Row>
                            </Fragment>
                          ) : (
                            <Row>
                              <Cell
                                colSpan={vehicleTableHead.length + 1}
                                align="center"
                                sx={{ fontWeight: 600 }}
                              >
                                No Data Found
                              </Cell>
                            </Row>
                          )
                        ) : (
                          <TableRow>
                            <TableCell
                              colSpan={visibleColumns?.length + 2}
                              align="center"
                              sx={{ fontWeight: 600 }}
                            >
                              <Box className={classes.loaderWrap}>
                                <CircularProgress />
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

              {loader && <CircleLoading />}
            </Box>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ExportShippingSOA;
