import { FontFamily } from "assets";

const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles(() => ({
  columnStyle: {
    fontSize: "6px !important",
    fontWeight: 700,
    fontFamily: FontFamily.openSans,
    margin: 0,
    padding: '4px 0px',
  },
  keeptogether: {
    pageBreakInside:'avoid'
  }
  
}));
