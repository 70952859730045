import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";
import { FontFamily } from "assets";

const WalletDashboard = ({ resultData }) => {
  const classes = useStyles();
  // *For Total Amounts
  let TotalVaultBalance = 0;
  // *For Total Amounts
  let TotalVaultBalance2 = 0;
  let TotalVaultBalance3 = 0;
  let TotalVaultBalance4 = 0;
  return (
    <>
      <TableBody>
        {resultData?.map((item, index) => {
          let subTotal = 0;
          let subTotal2 = 0;
          let subTotal3 = 0;
          let subTotal4 = 0;
          // *For Accounts Id
          let vehicleAccountId = "";
          let shippingAccountId = "";
          let vehicleAccountNature = "";
          let shippingAccountNature = "";
          let GVV;
          let GVV2;
          let GSV;
          let GSV2;
          let GVVNature = item?.accounts.find(
            (account) =>
              account.type_code === "L2" && account.primary_series === 50004
          );
          let GSVNature = item?.accounts.find(
            (account) =>
              account.type_code === "L2" && account.primary_series === 50005
          );

          GVV = GVVNature.total_credit - GVVNature.total_debit;
          GSV = GSVNature.total_credit - GSVNature.total_debit;
          GVV2 = GVVNature.total_credit_cur - GVVNature.total_debit_cur;
          GSV2 = GSVNature.total_credit_cur - GSVNature.total_debit_cur;
          vehicleAccountId = GVVNature?.id;
          shippingAccountId = GSVNature?.id;
          vehicleAccountNature = GVVNature?.nature;
          shippingAccountNature = GSVNature?.nature;

          for (let i = 0; i < item?.accounts.length; i++) {
            const element = item?.accounts[i];
            const credit = isNaN(element?.total_credit)
              ? 0
              : element?.total_credit ?? 0;
            const debit = isNaN(element?.total_debit)
              ? 0
              : element?.total_debit ?? 0;
            const credit2 = isNaN(element?.total_credit_cur)
              ? 0
              : element?.total_credit_cur ?? 0;
            const debit2 = isNaN(element?.total_debit_cur)
              ? 0
              : element?.total_debit_cur ?? 0;
            if (
              element?.type_code === "L2" &&
              element?.primary_series === 50004
            ) {
              if (element?.currency === "usd") {
                subTotal =
                  element?.nature === "credit"
                    ? (parseFloat(credit) - parseFloat(debit)).toFixed(2)
                    : parseFloat(0).toFixed(2);
                subTotal2 =
                  element?.nature === "debit"
                    ? (parseFloat(debit) - parseFloat(credit)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalVaultBalance += parseFloat(subTotal);
                //sdasda
                subTotal3 =
                  element?.nature === "credit"
                    ? (parseFloat(credit2) - parseFloat(debit2)).toFixed(2)
                    : parseFloat(0).toFixed(2);
                subTotal4 =
                  element?.nature === "debit"
                    ? (parseFloat(debit2) - parseFloat(credit2)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalVaultBalance3 += parseFloat(subTotal3);
              }
            }
            if (
              element?.type_code === "L2" &&
              element?.primary_series === 50005
            ) {
              if (element?.currency === "usd") {
                subTotal =
                  element?.nature === "credit"
                    ? (parseFloat(credit) - parseFloat(debit)).toFixed(2)
                    : parseFloat(0).toFixed(2);
                subTotal2 =
                  element?.nature === "debit"
                    ? (parseFloat(debit) - parseFloat(credit)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalVaultBalance2 += parseFloat(subTotal);

                //asdsda
                subTotal3 =
                  element?.nature === "credit"
                    ? (parseFloat(credit2) - parseFloat(debit2)).toFixed(2)
                    : parseFloat(0).toFixed(2);
                subTotal4 =
                  element?.nature === "debit"
                    ? (parseFloat(debit2) - parseFloat(credit2)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalVaultBalance4 += parseFloat(subTotal3);
              }
            }
          }
          return (
            <>
              <TableRow
                sx={{
                  fontSize: "8px !important",
                  background: index % 2 !== 0 ? "#EFF8E7" : "",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.ref_id ?? "-"}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.name ?? "-"}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  Vault-{item?.id ?? "-"}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {GVV}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(GVV2).toFixed(2))}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {CommaSeparator(GSV)}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(GSV2).toFixed(2))}
                </TableCell>
              </TableRow>
            </>
          );
        })}
        <TableRow>
          <TableCell
            colSpan={6}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            Total:
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            {CommaSeparator(parseFloat(TotalVaultBalance).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVaultBalance3).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            {CommaSeparator(parseFloat(TotalVaultBalance2).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVaultBalance4).toFixed(2))}{" "}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};

export default WalletDashboard;
