import React, { Suspense, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { CircleLoading } from "components/Loaders";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ErrorToaster } from "components/Toaster";
import {
  addNavigation,
  setAedExchangeRate,
  setCadExchangeRate,
  setPermission,
  setUsdExchangeRate,
} from "redux/slices/navigationDataSlice";
import { useDispatch } from "react-redux";
import AuthServices from "services/Auth";
import { addChildRoutes, getPermissionsRoutes } from "utils";
import ProtectedRoute from "routes/ProtectedRoutes";
import SystemServices from "services/System";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import MenuIcon from "@mui/icons-material/Menu";
import EastIcon from "@mui/icons-material/East";
import "animate.css";
import Colors from "assets/Style/Colors";
function DashboardLayout() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [toggleStatus, setToggleStatus] = useState(true);

  // *For Get Side Navigation
  const getSideNavigation = async () => {
    try {
      const { data } = await AuthServices.getSideNavigation();
      dispatch(addNavigation(addChildRoutes(data?.permissions)));
      dispatch(setPermission(getPermissionsRoutes(data?.permissions)));
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Get Currencies
  const getCurrencies = async () => {
    try {
      let params = {
        detailed: true,
      };
      const { data } = await SystemServices.getCurrencies(params);
      dispatch(
        setAedExchangeRate(
          parseFloat(
            data?.currencies.find((e) => e.currency === "aed")?.conversion_rate
          )
        )
      );
      dispatch(
        setUsdExchangeRate(
          parseFloat(
            data?.currencies.find((e) => e.currency === "usd")?.conversion_rate
          )
        )
      );
      dispatch(
        setCadExchangeRate(
          parseFloat(
            data?.currencies.find((e) => e.currency === "cad")?.conversion_rate
          )
        )
      );
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const toggleStatusFunc = () => {
    setToggleStatus(!toggleStatus);
  };

  useEffect(() => {
    getSideNavigation();
  }, []);
  // useEffect(() => {
  //   getNotifications()
  // }, [location.pathname])

  return (
    <Box sx={{ bgcolor: "#FCFCFC" }}>
      {/* ========== Header ========== */}
      <Header onClick={toggleStatusFunc} status={toggleStatus} />

      <Box component="main" sx={{ display: "flex" }}>
        {/* ========== Sidebar ========== */}
        <Box
          sx={{
            height: `100vh`,
            overflowY: "auto",
            borderRight: `1px solid #EAEAEA`,
            width: "300px",
            px: !toggleStatus ? 1.25 : 1.5,
            py: !toggleStatus ? 1 : 2,
            transition: "width 0.5s ease-in-out !important", // Add a transition property
            width: toggleStatus ? "300px" : "50px", // Adjust width based on toggleStatus
            visibility: toggleStatus ? "visible" : "visible", // Hide when not toggled
            background: "linear-gradient(180deg, #0C6135 10.84%, #5BBC0F 100%)",
          }}
        >
          {toggleStatus ? (
            <Sidebar onClick={toggleStatusFunc} status={toggleStatus} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <MenuIcon
                onClick={toggleStatusFunc}
                sx={{
                  fontSize: "25px",
                  cursor: "pointer",
                  color: Colors.white,
                }}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            height: `calc(100vh)`,
            width: 1,
            position: "relative",
            overflowY: "auto",
          }}
        >
          <Suspense fallback={<CircleLoading />}>
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardLayout;
