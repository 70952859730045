import React, { Fragment, useEffect, useRef, useState } from "react";
import {
    Box,
    CircularProgress,
    Grid,
    CardMedia,
    Typography,
    Container,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Colors from "assets/Style/Colors";
import { ErrorToaster } from "components/Toaster";
import { PrimaryButton } from "components/Buttons";
import { CommaSeparator, GeneratePDF, handleExportWithComponent } from "utils";
import VehiclePaymentServices from "services/VehiclePayment";
import LogoImage from "assets/Images/header_voucher.svg";

import {
    FontFamily,
    Images,
    InvoiceGlobal,
    InvoiceLocation,
    InvoiceMail,
    InvoicePhone,
} from "assets";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import CurrencyServices from "services/Currency";
import FinanceServices from "services/Finance";
import { PDFExport } from "@progress/kendo-react-pdf";
import SystemServices from 'services/System';

const useStyle = makeStyles({
    headingBg: {
        margin: "32px 0px",
        padding: "12px 0px",
        textAlign: "center",
    },
    heading: {
        color: Colors.white,
        textTransform: "uppercase",
        fontWeight: 300,
        fontFamily: FontFamily.openSans,
    },
    text: {
        color: Colors.smokeyGrey,
        fontWeight: 300,
        fontFamily: FontFamily.openSans,
    },
    tableCell: {
        backgroundColor: Colors.aliceBlue,
        border: "0.25px solid #D9D9D9",
        "& .MuiTypography-root": {
            padding: "4px 12px",
        },
    },
});

function ReceiptVoucher() {
    const { id } = useParams();
    const contentRef = useRef(null);
    const classes = useStyle();
    const [loader, setLoader] = useState(true);

    // *For Invoice Detail
    const [receiptDetail, setReceiptDetail] = useState();
    // *For Currency
    const [exchangeRateAed, setExchangeRateAed] = useState();
    const [exchangeRateUsd, setExchangeRateUsd] = useState(0);


    // *For Get Invoice Detail
    const getReceiptDetail = async () => {

        setLoader(true)
        try {
            let params = {
                voucher_id: atob(id)
            }
            const { data } = await FinanceServices.getReceiptPreview(params)
            setReceiptDetail(data.voucher)

        } catch (error) {
            ErrorToaster(error)
        } finally {
            setLoader(false)
        }
    }
    const getCurrencies = async () => {
        try {
            let params = {
                detailed: true,
            };
            const { data } = await SystemServices.getCurrencies(params);

            setExchangeRateUsd(
                data?.currencies[2]?.conversion_rate
            );
        } catch (error) {
            ErrorToaster(error);
        }
    };

    useEffect(() => {
        getCurrencies()
        if (id) {
            getReceiptDetail();
        }
    }, [id]);

    return (
        <Container>
            {!loader && (
                <Box sx={{ textAlign: "right", p: 4 }}>
                    <PrimaryButton
                        title="Download PDF"
                        type="button"
                        style={{ backgroundColor: Colors.bluishCyan }}
                        onClick={() => handleExportWithComponent(contentRef)}
                    />
                </Box>
            )}
            <PDFExport ref={contentRef} fileName="Receipt Voucher">
                <Box
                    sx={{
                        width: "1000px",
                        mx: 4,
                        my: 2,
                        bgcolor: Colors.white,
                        boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                    }}
                >
                    {!loader ? (
                        <Box>
                            <Grid container spacing={0}>
                                <Grid item xs={3.5} sm={3.5} md={3.5}>
                                    <Box
                                        component={"img"}
                                        src={LogoImage}
                                        sx={{ width: "100%", alignItems: "center" }}
                                    />
                                </Grid>
                                <Grid item xs={8.5} sm={8.5} md={8.5}>
                                    <CardMedia image={Images.invoiceHeader} sx={{ mb: 2 }}>
                                        <Typography
                                            variant="h3"
                                            sx={{ py: 3, textAlign: "center", color: Colors.white }}
                                        >
                                            Galaxy Used Cars Tr. LLC
                                        </Typography>
                                    </CardMedia>
                                    <Grid
                                        container
                                        spacing={1.5}
                                        alignItems={"center"}
                                        justifyContent={"space-evenly"}
                                    >
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <InvoicePhone />
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: Colors.smokeyGrey,
                                                        fontFamily: FontFamily.openSans,
                                                    }}
                                                >
                                                    +971 6 510 2000
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <InvoiceMail />
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: Colors.smokeyGrey,
                                                        fontFamily: FontFamily.openSans,
                                                    }}
                                                >
                                                    info@galaxyshipping.com
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <InvoiceGlobal />
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: Colors.smokeyGrey,
                                                        fontFamily: FontFamily.openSans,
                                                    }}
                                                >
                                                    https://galaxyshipping.com
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <InvoiceLocation />
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        color: Colors.smokeyGrey,
                                                        fontFamily: FontFamily.openSans,
                                                    }}
                                                >
                                                    Ind Area#4 P.O Box 83126, Sharjah , UAE
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box
                                sx={{
                                    my: 5,
                                    position: "relative",
                                    bgcolor: Colors.bluishCyan,
                                    width: 1,
                                    height: "12px",
                                }}
                            >
                                <Typography
                                    component={"span"}
                                    variant="h2"
                                    sx={{
                                        color: Colors.charcoalGrey,
                                        bgcolor: Colors.white,
                                        p: 2,
                                        letterSpacing: "3px",
                                        position: "absolute",
                                        right: "90px",
                                        top: "-40px",
                                    }}
                                >
                                    RECEIPT VOUCHER
                                </Typography>
                            </Box>
                            <Grid container spacing={2} justifyContent={"space-between"}>
                                <Grid item xs={5.5} sm={5.5} md={5.5}>
                                    <Box sx={{ ml: 4, mb: 7, height: "107px" }}>
                                        <Grid container spacing={0.5}>
                                            <Grid item xs={5} sm={5} md={5}>
                                                <Typography variant="body1" className={classes.text}>
                                                    From Account:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography

                                                    variant="body1"
                                                    className={classes.text}
                                                >
                                                    {receiptDetail?.account?.name ?? "-"}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5}>
                                                <Typography variant="body1" className={classes.text}>
                                                    COA Code:
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <Typography
                                                    noWrap
                                                    variant="body1"
                                                    className={classes.text}
                                                >
                                                    {receiptDetail?.account?.account_code ?? "-"}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item xs={5} sm={5} md={5}>
                        <Typography variant="body1" className={classes.text}>
                          To Account :
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          noWrap
                          variant="body1"
                          className={classes.text}
                        >
                          {receiptDetail?.cashier_usd_account
                            ? receiptDetail?.cashier_usd?.name
                            : receiptDetail?.cashier_aed_account
                            ? receiptDetail?.cashier_aed_account?.name
                            : receiptDetail?.bank?.name}
                        </Typography>
                      </Grid> */}
                                            {/* <Grid item xs={5} sm={5} md={5}>
                        <Typography variant="body1" className={classes.text}>
                          Account ID:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        <Typography
                          noWrap
                          variant="body1"
                          className={classes.text}
                        >
                          {receiptDetail?.cashier_usd_account
                            ? receiptDetail?.cashier_usd_account
                            : receiptDetail?.cashier_aed_account
                            ? receiptDetail?.cashier_aed_account
                            : receiptDetail?.bank_account_id}
                        </Typography>
                      </Grid> */}
                                        </Grid>
                                    </Box>
                                    <Box
                                        className={classes.headingBg}
                                        sx={{ bgcolor: Colors.primary }}
                                    >
                                        <Typography variant="h4" className={classes.heading}>
                                            Received Currency Details
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 3, mb: 8 }}>
                                        <Grid container spacing={0} justifyContent={"flex-end"}>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Currency
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    FCY
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Ex. Rate
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    LCY
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "center" }}
                                                >
                                                    AED
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {receiptDetail?.aed_amount_fcy ? CommaSeparator(receiptDetail?.aed_amount_fcy) : "0.00"}

                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {parseFloat(1)?.toFixed(2)}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {receiptDetail?.aed_amount_lcy ? CommaSeparator(parseFloat(receiptDetail?.aed_amount_lcy).toFixed(2)) : "0.00"}
                                                    {/* {isNaN(
                            CommaSeparator(parseFloat(receiptDetail?.aed_amount_lcy)?.toFixed(
                              2
                            ))
                          )
                            ? "0.00"
                            : CommaSeparator(parseFloat(
                                receiptDetail?.aed_amount_lcy
                              )?.toFixed(2))} */}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "center" }}
                                                >
                                                    USD
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {receiptDetail?.usd_amount_fcy ?
                                                        CommaSeparator(parseFloat(receiptDetail?.usd_amount_fcy)?.toFixed(
                                                            2
                                                        ))

                                                        : "0.00"
                                                    }
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {parseFloat(
                                                        receiptDetail?.updated_ex_rate ? receiptDetail?.updated_ex_rate : receiptDetail?.usd_amount_exrate || 0
                                                    )?.toFixed(3)}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={3}
                                                sm={3}
                                                md={3}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {CommaSeparator(parseFloat(receiptDetail?.usd_amount_lcy || 0)?.toFixed(
                                                        2
                                                    ))}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "left",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Total in AED
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {CommaSeparator(parseFloat(receiptDetail?.aed_total)?.toFixed(2))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Box
                                        className={classes.headingBg}
                                        sx={{ bgcolor: Colors.bluishCyan }}
                                    >
                                        <Typography variant="h4" className={classes.heading}>
                                            Remarks
                                        </Typography>
                                    </Box>
                                    <Box sx={{ ml: 4, mr: 2 }}>
                                        <Typography variant="body1" className={classes.text}>
                                            {receiptDetail?.remarks}
                                        </Typography>
                                    </Box>
                                    <Box
                                        className={classes.headingBg}
                                        sx={{ pl: "32px !important", bgcolor: Colors.aliceBlue }}
                                    >
                                        <Typography
                                            variant="body1"
                                            className={classes.heading}
                                            sx={{
                                                textAlign: "left",
                                                color: `${Colors.charcoalGrey} !important`,
                                            }}
                                        >
                                            <b>Received by :</b>
                                            <Typography
                                                component={"span"}
                                                variant="body2"
                                                sx={{ textTransform: "none" }}
                                            >
                                                &nbsp; {receiptDetail?.creator?.name}
                                            </Typography>
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={5.5} sm={5.5} md={5.5}>
                                    <Box sx={{ mr: 3, mt: 0, height: "100px", mb: 7 }}>
                                        <Grid container spacing={0} justifyContent={"flex-end"}>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    Receipt Voucher #:
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    GRV-{receiptDetail?.id}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    Processing Date:
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    {moment(
                                                        receiptDetail?.created_at
                                                    ).format("DD-MMM-YYYY hh:mm")}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    Customize Date:
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    {moment(
                                                        receiptDetail?.c_date
                                                    ).format("DD-MMM-YYYY hh:mm")}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    Last Updated on:
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                sm={6}
                                                md={6}
                                                className={classes.tableCell}
                                            >
                                                <Typography variant="body1" className={classes.text}>
                                                    {moment(receiptDetail?.updated_at).format(
                                                        "DD-MMM-YYYY hh:mm"
                                                    )}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box
                                        className={classes.headingBg}
                                        sx={{ bgcolor: Colors.primary, marginTop: 10 }}
                                    >
                                        <Typography variant="h4" className={classes.heading}>
                                            Received Amount
                                        </Typography>
                                    </Box>
                                    <Box sx={{ mr: 3, mb: 2 }}>
                                        <Grid container spacing={0} justifyContent={"flex-end"}>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    AED
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Ex. Rate
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "right",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    USD
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {CommaSeparator(parseFloat(receiptDetail?.aed_total)?.toFixed(2))}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {parseFloat(
                                                        receiptDetail?.received_ex_rate ? receiptDetail?.received_ex_rate : exchangeRateUsd
                                                    )?.toFixed(3)}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important" }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{ textAlign: "right" }}
                                                >
                                                    {CommaSeparator(parseFloat(receiptDetail?.aed_total / receiptDetail?.received_ex_rate)?.toFixed(2))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    {
                                        receiptDetail?.bank ? (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                // className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important", mb: 2, }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Bank Account
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                // className={classes.tableCell}
                                                sx={{ backgroundColor: "white !important", mb: 2 }}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    className={classes.text}
                                                    sx={{
                                                        textAlign: "center",
                                                        fontWeight: "bold !important",
                                                    }}
                                                >
                                                    Cashier Accounts
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                    <Box sx={{ mr: 3, mb: 8 }}>
                                        <Grid container spacing={0} justifyContent={"flex-end"}>
                                            {receiptDetail?.bank && (
                                                <>

                                                    <Grid
                                                        item
                                                        xs={9}
                                                        sm={9}
                                                        md={9}
                                                        className={classes.tableCell}
                                                        sx={{ backgroundColor: "white !important" }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.text}
                                                            sx={{ textAlign: "right" }}
                                                        >
                                                            {receiptDetail?.bank?.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sm={3}
                                                        md={3}
                                                        className={classes.tableCell}
                                                        sx={{ backgroundColor: "white !important" }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            className={classes.text}
                                                            sx={{ textAlign: "right" }}
                                                        >
                                                            {receiptDetail?.usd_amount_fcy > 0
                                                                ? CommaSeparator(parseFloat(
                                                                    receiptDetail?.usd_amount_fcy
                                                                )?.toFixed(2))
                                                                : CommaSeparator(parseFloat(
                                                                    receiptDetail?.aed_amount_fcy
                                                                )?.toFixed(2))}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            )}

                                            {!receiptDetail?.bank && (
                                                <>
                                                    {receiptDetail?.cashier_aed &&
                                                        <>

                                                            <Grid
                                                                item
                                                                xs={9.5}
                                                                sm={9.5}
                                                                md={9.5}
                                                                className={classes.tableCell}
                                                                sx={{ backgroundColor: "white !important" }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.text}
                                                                    sx={{ textAlign: "center" }}
                                                                >
                                                                    {receiptDetail?.cashier_aed?.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2.5}
                                                                sm={2.5}
                                                                md={2.5}
                                                                className={classes.tableCell}
                                                                sx={{ backgroundColor: "white !important" }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.text}
                                                                    sx={{ textAlign: "right" }}
                                                                >
                                                                    {receiptDetail?.cashier_aed
                                                                        ? CommaSeparator(parseFloat(
                                                                            receiptDetail?.aed_amount_lcy
                                                                        )?.toFixed(2))
                                                                        : ""}
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                    }
                                                    {receiptDetail?.cashier_usd &&
                                                        <>

                                                            <Grid
                                                                item
                                                                xs={9.5}
                                                                sm={9.5}
                                                                md={9.5}
                                                                className={classes.tableCell}
                                                                sx={{ backgroundColor: "white !important" }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.text}
                                                                    sx={{ textAlign: "center" }}
                                                                >
                                                                    {receiptDetail?.cashier_usd?.name}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2.5}
                                                                sm={2.5}
                                                                md={2.5}
                                                                className={classes.tableCell}
                                                                sx={{ backgroundColor: "white !important" }}
                                                            >
                                                                <Typography
                                                                    variant="body1"
                                                                    className={classes.text}
                                                                    sx={{ textAlign: "right" }}
                                                                >
                                                                    {receiptDetail?.cashier_usd
                                                                        ? CommaSeparator(parseFloat(
                                                                            receiptDetail?.usd_amount_fcy
                                                                        )?.toFixed(2))
                                                                        : ""}
                                                                </Typography>
                                                            </Grid>
                                                        </>}
                                                </>
                                            )}
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={2}
                                padding={2}
                                pr={4}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                            >
                                <Grid item xs={10}>
                                    <Typography variant="body1" sx={{ mb: 2 }}>
                                        PLEASE READ CAREFULLY THE T&C:
                                    </Typography>
                                    <Box
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: "12px",
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            1- Kindly check or review the invoice/voucher and cash
                                            before leaving the counter.
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            2- Our establishment will not be responsible for any
                                            delay, mistakes, or omissions in transmission.
                                        </Typography>{" "}
                                        <Typography
                                            variant="body2"
                                            className={classes.text}
                                            sx={{ mb: 1 }}
                                        >
                                            3- Our liability is limited to making all necessary
                                            amendments in the shortest possible time.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        sx={{
                                            height: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            m: 4,
                                        }}
                                    >
                                        <QRCodeCanvas
                                            value={
                                                window.location.origin +
                                                `/receipt-preview/${btoa("receipt-" + id)}`
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box sx={{ pl: 4, pr: 3, py: 1, bgcolor: Colors.primary }}>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: Colors.white,
                                        fontFamily: FontFamily.openSans,
                                    }}
                                >
                                    Customer care Contact: Mohammed husni - +971523195682 (Arabic
                                    & English ) Ardamehr Shoev - +971545836028 (English ,Arabic,
                                    Tajik & Farsi)
                                </Typography>
                                <Typography
                                    variant="caption"
                                    sx={{
                                        color: Colors.white,
                                        fontFamily: FontFamily.openSans,
                                    }}
                                >
                                    Ravin abdul kareem - +971528293801 (Kurdish , Arabic &
                                    English) Magsat Gylyjov - +97158666403 (Turken , Russian &
                                    English)
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <Box sx={{ textAlign: "center", py: 3 }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </PDFExport>
        </Container>
    );
}

export default ReceiptVoucher;
