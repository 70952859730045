import { CancelOutlined, CheckCircle } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography, Grid } from '@mui/material'; // Added Grid import
import { FontFamily } from 'assets';
import Colors from 'assets/Style/Colors';
import { PrimaryButton } from 'components/Buttons';
import DatePicker from 'components/DatePicker';
import { useState } from 'react'; // Import useState
import { v4 as uuidv4 } from "uuid";
import { CleanTypes, getFileSize } from "utils";
import { ErrorToaster } from 'components/Toaster';
import instance from "config/axios";
import routes from "services/System/routes";
import UploadFile from "components/UploadFile";
import Uploading from "components/Uploading";
import { useForm } from 'react-hook-form';

function GatePassReceiveDialog({ open, onClose, updateStatus, page }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [documentLink, setDocumentLink] = useState('');
  const allowFilesType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [documentDetail, setDocumentDetail] = useState([]);
  const {
    register,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = () => {
    // Pass the selected date and document link to the parent component
    updateStatus(selectedDate, documentLink);
  };

  const handleUploadDocument = async (e) => {

    try {
      const file = e.target.files[0];
      if (allowFilesType.includes(file.type)) {
        let arr = [
          {
            id: uuidv4(),
            name: file?.name,
            file: "",
            type: file?.type.split("/")[1],
            size: getFileSize(file.size),
            isUpload: false,
          },
        ];

        setDocumentDetail(arr);
        handleUpload(file, arr);
      } else {
        ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats are supported`);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("document", file);
      const { data } = await instance.post(routes.uploadDocuments, formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round((uploadedBytes * 100) / progressEvent.total);
          setProgress(percentCompleted);
          setUploadedSize(getFileSize(uploadedBytes));
        },
      });
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setDocumentDetail(docs);
        setDocumentLink(data?.data?.nations); 
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  return (
    <Dialog
      open={open}
      sx={{ '& .MuiDialog-paper': { width: '30%', height: "auto", borderRadius: 2, py: { xs: 2, md: 4 }, px: { xs: 3, md: 6 } } }}
    >
      <IconButton onClick={() => onClose()} sx={{ position: 'absolute', right: 13, top: 13 }}>
        <CancelOutlined />
      </IconButton>
      <Box sx={{ textAlign: 'center' }}>
        <Typography variant="h5" sx={{ color: Colors.charcoalGrey, fontFamily: FontFamily.NunitoRegular, mt: 1, mb: 1.5 }}>
          Status
        </Typography>
        <Grid item xs={12} md={2} sm={2.5}>
          <DatePicker
            label={page === "Makasa" ? "Makasa RCV Date" : "CEC RCV Date"}
            size="small"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1" sx={{ color: Colors.charcoalGrey }}>
            Attachment
          </Typography>
          <UploadFile
            custom={true}
            // multiple={true}
            style={{ backgroundColor: "#e7efeb", width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', padding: '20px', border: '1px dashed #aeb1b0', borderRadius: '10px' }}
            accept={allowFilesType}
            register={register("scanned2", {
              required: "Please attach Decelaration.",
              onChange: (e) => handleUploadDocument(e)
            })}
          />
        </Grid>
        {documentDetail.length > 0 && (
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" sx={{ color: Colors.charcoalGrey, mb: 1 }}>
              Uploaded Files
            </Typography>
            <Box sx={{ maxHeight: 300, overflow: 'auto', pr: 1 }}>
              {documentDetail?.map((item, index) => (
                <Uploading key={index} data={item} uploadedSize={uploadedSize} progress={progress} />
              ))}
            </Box>
          </Grid>
        )}
        <Box sx={{ mt: 4, display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-evenly', '.MuiSvgIcon-root': { fontSize: '32px !important' } }}>
          <PrimaryButton
            title={"Pending"}
            startIcon={<CheckCircle sx={{ color: Colors.white }} />}
            style={{ backgroundColor: Colors.danger }}
          />
          <PrimaryButton
            title={page === "Makasa" ? "Makasa" : "Received"}
            onClick={handleSubmit}
            startIcon={<CheckCircle sx={{ color: Colors.white }} />}
            style={{ backgroundColor: Colors.bluishCyan }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}


export default GatePassReceiveDialog;
