import VendorLedger from "pages/Dashboard/Accounts/VendorLedger";
import VehicleAvailableForTT from "pages/Dashboard/Client/VehiclesAvailableForTT";
import GalaxyCustomers from "pages/Dashboard/Customers/GalaxyCustomers";
import MyCustomers from "pages/Dashboard/Customers/MyCustomers";
import MyVehicles from "pages/Dashboard/Customers/MyVehicles";
import Finance from "pages/Dashboard/DashboardPages/Finance";
import WalletDashBoard from "pages/Dashboard/DashboardPages/WalletDashBoard";
import GlobalSearch from "pages/Dashboard/DashboardPages/GlobalSearch";
import AddVendorAgent from "pages/Dashboard/Export/AddVendorAgent";
import BroderCosting from "pages/Dashboard/Export/BorderCosting";
import BorderCostingApproval from "pages/Dashboard/Export/BorderCostingApproval";
import BorderCostingDetail from "pages/Dashboard/Export/BorderCostingDetail";
import BorderCostingList from "pages/Dashboard/Export/BorderCostingList";
import ContainerCosting from "pages/Dashboard/Export/ContainerCosting";
import ExportContainerInvoice from "pages/Dashboard/Export/ContainerInvoice";
import ContainerList from "pages/Dashboard/Export/ContainerList";
import ContainerPayment from "pages/Dashboard/Export/ContainerPayment";
import ExportContainerPayments from "pages/Dashboard/Export/ContainerPaymentList";
// import EditContainer from "pages/Dashboard/Export/EditContainer";
import ExportContainerPaymentReceipt from "pages/Dashboard/Export/ExportContainerPaymentReceipt";
import ExportCostProfit from "pages/Dashboard/Export/ExportCostProfit";
import ExportCountry from "pages/Dashboard/Export/ExportCountry";
import ExportVehiclePayments from "pages/Dashboard/Export/ExportInvoicesList";
import ExportPaymentReceipt from "pages/Dashboard/Export/ExportPaymentDetail";
import ExportClientRates from "pages/Dashboard/Export/ExportRates";
import ExportShippingRAAging from "pages/Dashboard/Export/ExportReceiveAging";
import ExportRecoverySOA from "pages/Dashboard/Export/ExportRecoverySOA";
import ExportShippingSOA from "pages/Dashboard/Export/ExportShippingSoa";
import ExportVendorAppliedFunds from "pages/Dashboard/Export/ExportVendorAppliedFunds";
import ExportVendorFundsApply from "pages/Dashboard/Export/ExportVendorFundsApply";
import ExportVendorLedger from "pages/Dashboard/Export/ExportVendorLedger";
import UnpaidInvoices from "pages/Dashboard/Export/UnpaidInvoices";
import UpdateBorderCosting from "pages/Dashboard/Export/UpdateBorderCosting";
import UpdateCMR from "pages/Dashboard/Export/UpdateCMR";
import UpdateRecovery from "pages/Dashboard/Export/UpdateRecovery";
import VendorAgentList from "pages/Dashboard/Export/VendorAgentList";
import FinalDestination from "pages/Dashboard/ExportFinance/FinalDestination";
// import FinalDestiantion from "pages/Dashboard/ExportFinance/FinalDestination";
import UpdateVaultTopUp from "pages/Dashboard/Finance/EditVaultTopUp";
import UpdatePaymentVoucher from "pages/Dashboard/Finance/UpdatePaymentVoucher";
import UpdateReceiptVoucher from "pages/Dashboard/Finance/UpdateReceiptVoucher";
import GatePassList from "pages/Dashboard/GatePass/GatePassList";
import MobayaList from "pages/Dashboard/GatePass/MobayaList";
import MobayaReversals from "pages/Dashboard/GatePass/MobayaReversals";
import AuctionHouseDueList from "pages/Dashboard/ReportingAnalytics/AuctionHouseDue";
import VatDueList from "pages/Dashboard/ReportingAnalytics/VatDueList";
import UpdateBusinessCountries from "pages/Dashboard/Settings/UpdateBussinessCountries";
import IssueVccList from "pages/Dashboard/Vcc/IssueVccList";
import VccDownloadedList from "pages/Dashboard/Vcc/VccDownloadedList";
import UpdateTT from "pages/Dashboard/Vendors/UpdateTT";
import VendorPayableList from "pages/Dashboard/Vendors/VendorPayableList";
import React, { lazy } from "react";
import BuyerIdDashboard from "pages/Dashboard/DashboardPages/BuyerIdDashboard";
import EditContainer from "pages/Dashboard/Export/EditContainer";
import PackingListInvoice from "pages/Dashboard/Export/PackingListinvoice";
import UpdatePackingList from "pages/Dashboard/Export/UpdatePackingList";

const CreateCustomer = lazy(() =>
  import("pages/Dashboard/Customers/CreateCustomer")
);
const CustomerQueue = lazy(() =>
  import("pages/Dashboard/Customers/CustomerQueue")
);
const ExportCustomers = lazy(() =>
  import("pages/Dashboard/Customers/ExportCustomers")
);
const Dashboard1 = lazy(() =>
  import("pages/Dashboard/DashboardPages/Dashboard1")
);
const GalaxyMotors = lazy(() =>
  import("pages/Dashboard/DashboardPages/GalaxyMotors")
);
const CustomerWiseVCCReports = lazy(() =>
  import("pages/Dashboard/DashboardPages/CustomerWiseVCCReports")
);
const CustomerService = lazy(() =>
  import("pages/Dashboard/DashboardPages/CustomerService")
);
const Operations = lazy(() =>
  import("pages/Dashboard/DashboardPages/Operations")
);
const CustomerDetail = lazy(() =>
  import("pages/Dashboard/Customers/CustomerDetail")
);
const BuyerIdDetail = lazy(() =>
  import("pages/Dashboard/Customers/BuyerIdDetail")
);
const Message = lazy(() => import("pages/Dashboard/Customers/Message"));
const CreateBuyerId = lazy(() =>
  import("pages/Dashboard/BuyerId/CreateBuyerId")
);
const UpdateBuyerId = lazy(() =>
  import("pages/Dashboard/BuyerId/UpdateBuyerId")
);
const BuyerIdList = lazy(() => import("pages/Dashboard/BuyerId/BuyerIdList"));
const BuyerIdHistoryLog = lazy(() =>
  import("pages/Dashboard/BuyerId/BuyerIdHistoryLog")
);
const RequestBuyerId = lazy(() =>
  import("pages/Dashboard/BuyerId/RequestBuyerId")
);
const CreateAuctionHouse = lazy(() =>
  import("pages/Dashboard/AuctionHouse/CreateAuctionHouse")
);
const UpdateAuctionHouse = lazy(() =>
  import("pages/Dashboard/AuctionHouse/UpdateAuctionHouse")
);
const AuctionHouseList = lazy(() =>
  import("pages/Dashboard/AuctionHouse/AuctionHouseList")
);
const CreateVehicleBooking = lazy(() =>
  import("pages/Dashboard/VehicleBooking/CreateVehicleBooking")
);
const UpdateVehicleBooking = lazy(() =>
  import("pages/Dashboard/VehicleBooking/UpdateVehicleBooking")
);
const VehicleBookingList = lazy(() =>
  import("pages/Dashboard/VehicleBooking/VehicleBookingList")
);
const VehicleBookingDetail = lazy(() =>
  import("pages/Dashboard/VehicleBooking/VehicleBookingDetail")
);
const ContainerBookingDetail = lazy(() =>
  import("pages/Dashboard/VehicleBooking/ContainerBookingDetail")
);
const ApprovalList = lazy(() =>
  import("pages/Dashboard/VehicleBooking/ApprovalList")
);
const ExchangeRate = lazy(() => import("pages/Dashboard/Finance/ExchangeRate"));
const ExchangeRateLog = lazy(() =>
  import("pages/Dashboard/Finance/ExchangeRateLog")
);
const CreateVehiclePayment = lazy(() =>
  import("pages/Dashboard/VehicleBooking/CreateVehiclePayment")
);
const VehiclePaymentHistory = lazy(() =>
  import("pages/Dashboard/VehicleBooking/VehiclePaymentHistory")
);
const InvoiceList = lazy(() =>
  import("pages/Dashboard/VehicleBooking/InvoiceList")
);
const ManualInvoiceList = lazy(() =>
  import("pages/Dashboard/VehicleBooking/ManualInvoices")
);
const ViewManualInvoice = lazy(() =>
  import("pages/Dashboard/VehicleBooking/ViewManualInvoice")
);
const ViewInvoice = lazy(() =>
  import("pages/Dashboard/VehicleBooking/ViewInvoice")
);
const PaymentList = lazy(() =>
  import("pages/Dashboard/VehicleBooking/PaymentList")
);
const PaymentReceipt = lazy(() =>
  import("pages/Dashboard/VehicleBooking/PaymentReceipt")
);
const CreateShipping = lazy(() =>
  import("pages/Dashboard/Shipping/CreateShipping")
);
const EditShipping = lazy(() =>
  import("pages/Dashboard/Shipping/EditShipping")
);
const UpdateShippingVehicle = lazy(() =>
  import("pages/Dashboard/Shipping/UpdateShippingVehicle")
);
const UpdateShipping = lazy(() =>
  import("pages/Dashboard/Shipping/UpdateShipping")
);
const ShippingList = lazy(() =>
  import("pages/Dashboard/Shipping/ShippingList")
);
const CreateVcc = lazy(() => import("pages/Dashboard/Vcc/CreateVcc"));
const VatPayment = lazy(() => import("pages/Dashboard/Vcc/VatPayment"));
const IssueVcc = lazy(() => import("pages/Dashboard/Vcc/IssueVcc"));
const RefundVcc = lazy(() => import("pages/Dashboard/Vcc/RefundVcc"));
const VccApprovalList = lazy(() =>
  import("pages/Dashboard/Vcc/VccApprovalList")
);
const VccIssuerList = lazy(() => import("pages/Dashboard/Vcc/VccIssuerList"));
const VccDeposit = lazy(() => import("pages/Dashboard/Vcc/VccDeposit"));
const IssueGatePass = lazy(() =>
  import("pages/Dashboard/GatePass/IssueGatePass")
);
const CreateGatePass = lazy(() =>
  import("pages/Dashboard/GatePass/CreateGatePass")
);
const ReCreateGatePass = lazy(() =>
  import("pages/Dashboard/GatePass/ReCreateGatePass")
);
const PayViewGatePass = lazy(() =>
  import("pages/Dashboard/GatePass/PayViewGatePass")
);
const GatePassYard = lazy(() =>
  import("pages/Dashboard/GatePass/GatePassYard")
);
const ReceiveExitPaper = lazy(() =>
  import("pages/Dashboard/Vcc/ReceiveExitPaper")
);
const Makasa = lazy(() => import("pages/Dashboard/Vcc/Makasa"));
const IssueMobaya = lazy(() => import("pages/Dashboard/GatePass/IssueMobaya"));
const CreateAccount = lazy(() =>
  import("pages/Dashboard/Accounts/CreateAccount")
);
const ExportCreateAccount = lazy(() =>
  import("pages/Dashboard/ExportFinance/CreateAccount")
);
const UpdateAccount = lazy(() =>
  import("pages/Dashboard/Accounts/UpdateAccount")
);
const ExportUpdateAccount = lazy(() =>
  import("pages/Dashboard/ExportFinance/UpdateAccount")
);
const ChartOfAccount = lazy(() =>
  import("pages/Dashboard/Accounts/ChartOfAccount")
);
const ExportChartOfAccount = lazy(() =>
  import("pages/Dashboard/ExportFinance/ChartOfAccount")
);
const AccountLedger = lazy(() =>
  import("pages/Dashboard/Accounts/AccountLedger")
);
const AccountLedgerUsd = lazy(() =>
  import("pages/Dashboard/Accounts/AccountLedgerUsd")
);
const ExportAccountLedger = lazy(() =>
  import("pages/Dashboard/ExportFinance/AccountLedger")
);
const CustomerVehicleLedger = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/CustomerVehicleLedger")
);
const CustomerShippingLedger = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/CustomerShippingLedger")
);
const CustomerVehicleVault = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/CustomerVehicleVault")
);
const CustomerShippingVault = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/CustomerShippingVault")
);
const AccountList = lazy(() => import("pages/Dashboard/Accounts/AccountList"));
const ExportAccountList = lazy(() =>
  import("pages/Dashboard/ExportFinance/AccountList")
);
const SubAccount = lazy(() => import("pages/Dashboard/Accounts/SubAccount"));
const ExportSubAccount = lazy(() =>
  import("pages/Dashboard/ExportFinance/SubAccount")
);
const AccountApprovalList = lazy(() =>
  import("pages/Dashboard/Accounts/AccountApprovalList")
);
const ExportAccountApprovalList = lazy(() =>
  import("pages/Dashboard/ExportFinance/AccountApprovalList")
);
const GeneralLedger = lazy(() =>
  import("pages/Dashboard/Accounts/GeneralLedger")
);
const ExportGeneralLedger = lazy(() =>
  import("pages/Dashboard/ExportFinance/GeneralLedger")
);
const GeneralJournalLedger = lazy(() =>
  import("pages/Dashboard/Accounts/GeneralJournalLedger")
);
const ExportGeneralJournalLedger = lazy(() =>
  import("pages/Dashboard/ExportFinance/ExportGeneralJournal")
);
const MasterApproval = lazy(() =>
  import("pages/Dashboard/Approvals/MasterApproval")
);
const OptionApproval = lazy(() =>
  import("pages/Dashboard/Approvals/OptionApproval")
);
const CreateUser = lazy(() =>
  import("pages/Dashboard/UserManagement/CreateUser")
);
const UpdateUser = lazy(() =>
  import("pages/Dashboard/UserManagement/UpdateUser")
);
const UserList = lazy(() => import("pages/Dashboard/UserManagement/UserList"));
const CreateRole = lazy(() =>
  import("pages/Dashboard/RoleManagement/CreateRole")
);
const UpdateRole = lazy(() =>
  import("pages/Dashboard/RoleManagement/UpdateRole")
);
const RoleList = lazy(() => import("pages/Dashboard/RoleManagement/RoleList"));
const Permission = lazy(() =>
  import("pages/Dashboard/RoleManagement/Permission")
);
const CreateVendor = lazy(() => import("pages/Dashboard/Vendors/CreateVendor"));
const VendorRateShipping = lazy(() =>
  import("pages/Dashboard/Vendors/VendorRateShipping")
);
const VendorRateTowing = lazy(() =>
  import("pages/Dashboard/Vendors/VendorRateTowing")
);
const VendorRateClearance = lazy(() =>
  import("pages/Dashboard/Vendors/VendorRateClearance")
);
const VendorCosting = lazy(() =>
  import("pages/Dashboard/Vendors/VendorCosting")
);
const BookDamage = lazy(() => import("pages/Dashboard/Vendors/BookDamage"));
const ClientRates = lazy(() => import("pages/Dashboard/Client/ClientRates"));
const ClientFundsApplication = lazy(() =>
  import("pages/Dashboard/Client/ClientFundsApplication")
);
const UnpaidPaidInvoices = lazy(() =>
  import("pages/Dashboard/Client/UnpaidPaidInvoices")
);
const AddExport = lazy(() => import("pages/Dashboard/Export/AddExport"));
const ExportVehicleList = lazy(() =>
  import("pages/Dashboard/Export/ExportVehicleList")
);
const ExportTT = lazy(() => import("pages/Dashboard/Export/ExportTT"));
const ExportTTList = lazy(() => import("pages/Dashboard/Export/ExortTTList"));
const ExportTTDetail = lazy(() =>
  import("pages/Dashboard/Export/ExportTTDetail")
);
const UpdateExportVehicles = lazy(() =>
  import("pages/Dashboard/Export/UpdateExportVehicles")
);
const ViewExportVehicle = lazy(() =>
  import("pages/Dashboard/Export/ViewExportVehicle")
);
const ExportInvoice = lazy(() =>
  import("pages/Dashboard/Export/ExportInvoice")
);
const ExportCosting = lazy(() =>
  import("pages/Dashboard/ExportFinance/ExportCosting")
);
const AgentCosting = lazy(() =>
  import("pages/Dashboard/ExportFinance/AgentCosting")
);
const BrokerCosting = lazy(() =>
  import("pages/Dashboard/ExportFinance/BrokerCosting")
);
const UpdateManifest = lazy(() =>
  import("pages/Dashboard/Export/UpdateManifest")
);
const UpdateContainer = lazy(() =>
  import("pages/Dashboard/Export/UpdateContainer")
);
const CreateDamage = lazy(() => import("pages/Dashboard/Export/CreateDamage"));
const ViewDamage = lazy(() => import("pages/Dashboard/Export/DamageView"));
const DamageDetailsView = lazy(() =>
  import("pages/Dashboard/Export/DamageDetailsView")
);
const ExportVehicleDetails = lazy(() =>
  import("pages/Dashboard/Export/ExportVehicleDetails")
);
const CustomerHandover = lazy(() =>
  import("pages/Dashboard/Export/CustomerHandover")
);
const AddShipment = lazy(() => import("pages/Dashboard/Export/AddShipment"));
const AddNewContainer = lazy(() =>
  import("pages/Dashboard/Export/AddNewContainer")
);
const CreateBank = lazy(() => import("pages/Dashboard/Finance/CreateBank"));
const UpdateBank = lazy(() => import("pages/Dashboard/Finance/UpdateBank"));
const BankList = lazy(() => import("pages/Dashboard/Finance/BankList"));
const CreateVaultTopUp = lazy(() =>
  import("pages/Dashboard/Finance/CreateVaultTopUp")
);
const VaultTopUpList = lazy(() =>
  import("pages/Dashboard/Finance/VaultTopUpList")
);
const ViewVaultTopUp = lazy(() =>
  import("pages/Dashboard/Finance/ViewVaultTopUp")
);
const PaymentVoucher = lazy(() =>
  import("pages/Dashboard/Finance/PaymentVoucher")
);
const PaymentVoucherList = lazy(() =>
  import("pages/Dashboard/Finance/PaymentVoucherList")
);
const ReceiptVoucherList = lazy(() =>
  import("pages/Dashboard/Finance/ReceiptVoucherList")
);
const VoucherDetail = lazy(() =>
  import("pages/Dashboard/Finance/VoucherDetail")
);
const ReceiptDetail = lazy(() =>
  import("pages/Dashboard/Finance/ReceiptDetail")
);
const ReceiptVoucher = lazy(() =>
  import("pages/Dashboard/Finance/ReceiptVoucher")
);
const ExportCreateBank = lazy(() =>
  import("pages/Dashboard/ExportFinance/CreateBank")
);
const ExportUpdateBank = lazy(() =>
  import("pages/Dashboard/ExportFinance/UpdateBank")
);
const ExportBankList = lazy(() =>
  import("pages/Dashboard/ExportFinance/BankList")
);
const ExportPaymentVoucher = lazy(() =>
  import("pages/Dashboard/ExportFinance/PaymentVoucher")
);
const ExportPaymentVoucherList = lazy(() =>
  import("pages/Dashboard/ExportFinance/PaymentVoucherList")
);
const ExportReceiptVoucherList = lazy(() =>
  import("pages/Dashboard/ExportFinance/ReceiptVoucherList")
);
const ExportVoucherDetail = lazy(() =>
  import("pages/Dashboard/ExportFinance/VoucherDetail")
);
const ExportReceiptDetail = lazy(() =>
  import("pages/Dashboard/ExportFinance/ReceiptDetail")
);
const ExportReceiptVoucher = lazy(() =>
  import("pages/Dashboard/ExportFinance/ReceiptVoucher")
);
const ExportCreateFundTransferVoucher = lazy(() =>
  import("pages/Dashboard/ExportFinance/CreateFundTransferVoucher")
);
const ExportFundTransferVoucherList = lazy(() =>
  import("pages/Dashboard/ExportFinance/FundTransferVoucherList")
);
const ExportFundTransferApprovalList = lazy(() =>
  import("pages/Dashboard/ExportFinance/FundTransferApproval")
);
const ExportFundTransferVoucherDetail = lazy(() =>
  import("pages/Dashboard/ExportFinance/FundTransferVoucherDetail")
);
const VendorCenter = lazy(() => import("pages/Dashboard/Vendors/VendorCenter"));
const ExportVendorCenter = lazy(() =>
  import("pages/Dashboard/Export/ExportVendorCenter")
);
const DamageDetail = lazy(() => import("pages/Dashboard/Vendors/DamageDetail"));
const CreateTT = lazy(() => import("pages/Dashboard/Vendors/CreateTT"));
const TTList = lazy(() => import("pages/Dashboard/Vendors/TTList"));
const ViewTT = lazy(() => import("pages/Dashboard/Vendors/ViewTT"));
const VendorUnAppliedFunds = lazy(() =>
  import("pages/Dashboard/Vendors/VendorUnAppliedFunds")
);
const ExportVendorUnAppliedFunds = lazy(() =>
  import("pages/Dashboard/Export/ExportVendorUnAppliedFunds")
);

const VendorFundsApply = lazy(() =>
  import("pages/Dashboard/Vendors/VendorFundsApply")
);
const DamageList = lazy(() => import("pages/Dashboard/Vendors/DamageList"));

const VendorFundApproval = lazy(() =>
  import("pages/Dashboard/Vendors/VendorFundApproval")
);
const VendorFundDetail = lazy(() =>
  import("pages/Dashboard/Vendors/VendorFundDetail")
);
const VendorAppliedFunds = lazy(() =>
  import("pages/Dashboard/Vendors/VendorAppliedFunds")
);
const VccRefundedList = lazy(() =>
  import("pages/Dashboard/Vcc/VccRefundedList")
);
const VccDepositedList = lazy(() =>
  import("pages/Dashboard/Vcc/VccDepositedList")
);
const ViewVccRefunded = lazy(() =>
  import("pages/Dashboard/Vcc/ViewVccRefunded")
);
const ViewVccDeposited = lazy(() =>
  import("pages/Dashboard/Vcc/ViewVccDeposited")
);
const GatePassApproval = lazy(() =>
  import("pages/Dashboard/GatePass/GatePassApproval")
);
const CustomerAccountList = lazy(() =>
  import("pages/Dashboard/Customers/CustomerAccountList")
);
const CustomerAccountLedger = lazy(() =>
  import("pages/Dashboard/Customers/CustomerAccountLedger")
);
const ClientShippingRate = lazy(() =>
  import("pages/Dashboard/Client/ClientShippingRate")
);
const ShippingCosting = lazy(() =>
  import("pages/Dashboard/Client/ShippingCosting")
);
const ShippingPaymentReceivedApplied = lazy(() =>
  import("pages/Dashboard/Client/ShippingPaymentReceivedApplied")
);
const VehicleTT = lazy(() => import("pages/Dashboard/Client/VehicleTT"));
const VehicleTTList = lazy(() =>
  import("pages/Dashboard/Client/VehicleTTList")
);
const VehicleTTDetail = lazy(() =>
  import("pages/Dashboard/Client/VehicleTTDetail")
);
const ShippingPaymentDetail = lazy(() =>
  import("pages/Dashboard/Client/ShippingPaymentInvoice")
);
const Storages = lazy(() => import("pages/Dashboard/Client/Storages"));
const ClientInvoiceView = lazy(() =>
  import("pages/Dashboard/Client/ClientInvoiceView")
);
const VehicleTTApprovalList = lazy(() =>
  import("pages/Dashboard/Client/VehicleTTApprovalList")
);
const AddVat = lazy(() => import("pages/Dashboard/Vcc/AddVat"));
const TrialBalance = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/TrialBalance")
);
const ExportTrialBalance = lazy(() =>
  import("pages/Dashboard/ExportFinance/TrialBalance")
);
const BalanceSheet = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/BalanceSheet")
);
const ExportBalanceSheet = lazy(() =>
  import("pages/Dashboard/ExportFinance/BalanceSheet")
);
const ProfitLossStatement = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ProfitLossStatement")
);
const ExportProfitLossStatement = lazy(() =>
  import("pages/Dashboard/ExportFinance/ProfitLossStatement")
);
const CashierActivity = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/CashierActivity")
);
const ExportCashierActivity = lazy(() =>
  import("pages/Dashboard/ExportFinance/CashierActivity")
);
const CreateJournalVoucher = lazy(() =>
  import("pages/Dashboard/Finance/CreateJournalVoucher")
);
const ExportCreateJournalVoucher = lazy(() =>
  import("pages/Dashboard/ExportFinance/CreateJournalVoucher")
);
const ExportUpdateJournalVoucher = lazy(() =>
  import("pages/Dashboard/ExportFinance/UpdateJournalVoucher")
);
const JournalVoucherList = lazy(() =>
  import("pages/Dashboard/Finance/JournalVoucherList")
);
const JournalVoucherDetail = lazy(() =>
  import("pages/Dashboard/Finance/JournalVoucherDetail")
);
const ExportJournalVoucherList = lazy(() =>
  import("pages/Dashboard/ExportFinance/JournalVoucherList")
);
const ExportJournalVoucherDetail = lazy(() =>
  import("pages/Dashboard/ExportFinance/JournalVoucherDetail")
);
const CreateFundTransferVoucher = lazy(() =>
  import("pages/Dashboard/Finance/CreateFundTransferVoucher")
);
const FundTransferVoucherList = lazy(() =>
  import("pages/Dashboard/Finance/FundTransferVoucherList")
);
const FundTransferApprovalList = lazy(() =>
  import("pages/Dashboard/Finance/FundTransferApproval")
);
const FundTransferVoucherDetail = lazy(() =>
  import("pages/Dashboard/Finance/FundTransferVoucherDetail")
);
const VehicleSOAList = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/VehicleSOAList")
);
const ShippingSOA = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ShippingSOA")
);
const ShippingRA = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ShippingRA")
);
const ShippingRAAging = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ShippingRAAging")
);
const ShippingProfit = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ShippingProfit")
);
const AddBusinessCountries = lazy(() =>
  import("pages/Dashboard/Settings/AddBussinessCountires")
);
const BusinessCountriesList = lazy(() =>
  import("pages/Dashboard/Settings/BusinessCountriesList")
);

const VehicleSumLedger = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/VehicleSumLedger")
);
const VaultDashboard = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/VaultDashboard")
);
const ViewCustomerVehicleInvoice = lazy(() =>
  import("pages/Dashboard/ReportingAnalytics/ViewCustomerVehicleInvoice")
);
const SlpList = lazy(() => import("pages/Dashboard/Client/Slp.List"));
const PackingListForm = lazy(() => import("pages/Dashboard/Export/PackingList"));
const GatePassPaymentVoucher = lazy(() => import("pages/Dashboard/GatePass/GatePassPaymentVoucher"));

const AdminRoutes = [
  {
    path: "/create-customer",
    component: <CreateCustomer />,
  },
  {
    path: "/customer-queue",
    component: <CustomerQueue />,
  },
  {
    path: "/export-customers",
    component: <ExportCustomers />,
  },
  {
    path: "/galaxy-customers",
    component: <GalaxyCustomers />,
  },
  {
    path: "/my-customers",
    component: <MyCustomers />,
  },
  {
    path: "/my-vehicles",
    component: <MyVehicles />,
  },
  {
    path: "/export-costing",
    component: <ExportCosting />,
  },
  {
    path: "/agent-costing",
    component: <AgentCosting />,
  },
  {
    path: "/broker-costing",
    component: <BrokerCosting />,
  },
  {
    path: "/customer-detail/:id",
    component: <CustomerDetail />,
  },
  {
    path: "/buyer-id-detail/:id",
    component: <BuyerIdDetail />,
  },
  {
    path: "/message/:id",
    component: <Message />,
  },
  {
    path: "/create-buyer-id",
    component: <CreateBuyerId />,
  },
  {
    path: "/update-buyer-id",
    component: <UpdateBuyerId />,
  },
  {
    path: "/buyer-id-list",
    component: <BuyerIdList />,
  },
  {
    path: "/buyer-id-history-log",
    component: <BuyerIdHistoryLog />,
  },
  {
    path: "/request-buyer-id/:id",
    component: <RequestBuyerId />,
  },
  {
    path: "/create-auction-house",
    component: <CreateAuctionHouse />,
  },
  {
    path: "/update-auction-house",
    component: <UpdateAuctionHouse />,
  },
  {
    path: "/auction-house-list",
    component: <AuctionHouseList />,
  },
  {
    path: "/add-business-location",
    component: <AddBusinessCountries />,
  },
  {
    path: "/update-business-location",
    component: <UpdateBusinessCountries />,
  },
  {
    path: "/business-locations",
    component: <BusinessCountriesList />,
  },
  {
    path: "/create-vehicle-booking",
    component: <CreateVehicleBooking />,
  },
  {
    path: "/update-vehicle-booking/:id",
    component: <UpdateVehicleBooking />,
  },
  {
    path: "/vehicle-booking-list",
    component: <VehicleBookingList />,
  },
  {
    path: "/vehicle-booking-detail/:id",
    component: <VehicleBookingDetail />,
  },
  {
    path: "/container-booking-detail/:id",
    component: <ContainerBookingDetail />,
  },
  {
    path: "/approval-list",
    component: <ApprovalList />,
  },
  {
    path: "/exchange-rate",
    component: <ExchangeRate />,
  },
  {
    path: "/exchange-rate-logs",
    component: <ExchangeRateLog />,
  },
  {
    path: "/create-vehicle-Payment",
    component: <CreateVehiclePayment />,
  },
  {
    path: "/vehicle-Payment-history",
    component: <VehiclePaymentHistory />,
  },
  {
    path: "/invoice-list",
    component: <InvoiceList />,
  },
  {
    path: "/manual-invoice-list",
    component: <ManualInvoiceList />,
  },
  {
    path: "/invoice/manual/:id",
    component: <ViewManualInvoice />,
  },
  {
    path: "/invoice/:id",
    component: <ViewInvoice />,
  },
  {
    path: "/galaxy-operations",
    component: <Dashboard1 />,
  },
  {
    path: "/galaxy-motors",
    component: <GalaxyMotors />,
  },
  {
    path: "/customerwise-vcc-reports",
    component: <CustomerWiseVCCReports />,
  },
  {
    path: "/customer-service",
    component: <CustomerService />,
  },
  {
    path: "/operations",
    component: <Operations />,
  },
  {
    path: "/finance",
    component: <Finance />,
  },
  {
    path: "/buyer-id-dashboard",
    component: <BuyerIdDashboard />,
  },
  {
    path: "/wallet-dashboard",
    component: <WalletDashBoard />,
  },
  {
    path: "/global-search",
    component: <GlobalSearch />,
  },
  {
    path: "/payment-list",
    component: <PaymentList />,
  },
  {
    path: "/payment-receipt/:id",
    component: <PaymentReceipt />,
  },
  {
    path: "/export-payment-receipt/:id",
    component: <ExportPaymentReceipt />,
  },
  {
    path: "/export-container-payment-receipt/:id",
    component: <ExportContainerPaymentReceipt />,
  },
  {
    path: "/create-shipping",
    component: <CreateShipping />,
  },
  {
    path: "/edit-shipping/:id",
    component: <EditShipping />,
  },
  {
    path: "/export-country",
    component: <ExportCountry />,
  },
  {
    path: "/add-vendor-agent",
    component: <AddVendorAgent />,
  },
  {
    path: "/vendor-agent-list",
    component: <VendorAgentList />,
  },
  {
    path: "/update-recovery",
    component: <UpdateRecovery />,
  },
  {
    path: "/update-shipping-vehicle",
    component: <UpdateShippingVehicle />,
  },
  {
    path: "/update-shipping",
    component: <UpdateShipping />,
  },
  {
    path: "/shipping-list",
    component: <ShippingList />,
  },
  {
    path: "/create-vcc",
    component: <CreateVcc />,
  },
  {
    path: "/issue-vcc-list",
    component: <IssueVccList />,
  },
  {
    path: "/vat-payment",
    component: <VatPayment />,
  },
  {
    path: "/add-vat",
    component: <AddVat />,
  },
  {
    path: "/issue-vcc",
    component: <IssueVcc />,
  },
  {
    path: "/refund-vcc",
    component: <RefundVcc />,
  },
  {
    path: "/vcc-approval-list",
    component: <VccApprovalList />,
  },
  {
    path: "/vcc-issuer-list",
    component: <VccIssuerList />,
  },
  {
    path: "/vcc-deposit",
    component: <VccDeposit />,
  },
  {
    path: "/add-export",
    component: <AddExport />,
  },
  {
    path: "/update-export-vehicle/:id",
    component: <UpdateExportVehicles />,
  },
  {
    path: "/list-export-vehicle",
    component: <ExportVehicleList />,
  },
  {
    path: "/update-container",
    component: <UpdateContainer />,
  },
  {
    path: "/container-costing",
    component: <ContainerCosting />,
  },
  {
    path: "/unpaid-invoices",
    component: <UnpaidInvoices />,
  },
  {
    path: "/container-payment",
    component: <ContainerPayment />,
  },
  {
    path: "/container-list",
    component: <ContainerList />,
  },

  {
    path: "/update-manifest",
    component: <UpdateManifest />,
  },
  {
    path: "/update-cmr",
    component: <UpdateCMR />,
  },
  {
    path: "/create-damage",
    component: <CreateDamage />,
  },
  {
    path: "/damage-view",
    component: <ViewDamage />,
  },
  {
    path: "/final-destination",
    component: <FinalDestination />,
  },
  {
    path: "/damage-detail-view/:id",
    component: <DamageDetailsView />,
  },
  {
    path: "/customer-handover",
    component: <CustomerHandover />,
  },
  {
    path: "/add-shipment",
    component: <AddShipment />,
  },
  {
    path: "/add-container",
    component: <AddNewContainer />,
  },
  {
    path: "/edit-container",
    component: <EditContainer />,
  },
  {
    path: "/export-vehicle-details/:id",
    component: <ViewExportVehicle />,
  },
  {
    path: "/export-invoice/:id",
    component: <ExportInvoice />,
  },
  {
    path: "/export-container-invoice/:id",
    component: <ExportContainerInvoice />,
  },

  {
    path: "/export-vehicle-details-form/:id",
    component: <ExportVehicleDetails />,
  },

  {
    path: "/export-tt",
    component: <ExportTT />,
  },
  {
    path: "/export-payment-list",
    component: <ExportVehiclePayments />,
  },
  {
    path: "/export-container-payment-list",
    component: <ExportContainerPayments />,
  },
  {
    path: "/export-tt-list",
    component: <ExportTTList />,
  },
  {
    path: "/export-tt-detail/:id",
    component: <ExportTTDetail />,
  },
  {
    path: "/issue-gate-pass",
    component: <IssueGatePass />,
  },
  {
    path: "/export-rates",
    component: <ExportClientRates />,
  },
  {
    path: "/create-gate-pass/:id",
    component: <CreateGatePass />,
  },
  {
    path: "/recreate-gate-pass/:id",
    component: <ReCreateGatePass />,
  },
  {
    path: "/gate-pass/:id",
    component: <PayViewGatePass />,
  },
  {
    path: "/gate-pass-yard",
    component: <GatePassYard />,
  },
  {
    path: "/receive-exit-paper",
    component: <ReceiveExitPaper />,
  },
  {
    path: "/makasa",
    component: <Makasa />,
  },
  {
    path: "/issue-mobaya",
    component: <IssueMobaya />,
  },
  {
    path: "/mobaya-list",
    component: <MobayaList />,
  },
  {
    path: "/mobaya-reversals",
    component: <MobayaReversals />,
  },
  {
    path: "/create-account",
    component: <CreateAccount />,
  },
  {
    path: "/create-account-export",
    component: <ExportCreateAccount />,
  },
  {
    path: "/update-account",
    component: <UpdateAccount />,
  },
  {
    path: "/update-account-export",
    component: <ExportUpdateAccount />,
  },
  {
    path: "/chart-of-accounts",
    component: <ChartOfAccount />,
  },
  {
    path: "/chart-of-accounts-export",
    component: <ExportChartOfAccount />,
  },
  {
    path: "/chart-of-accounts/sub-accounts/:id",
    component: <SubAccount />,
  },
  {
    path: "/chart-of-accounts/sub-accounts-export/:id",
    component: <ExportSubAccount />,
  },
  {
    path: "/account-list",
    component: <AccountList />,
  },
  {
    path: "/account-list-export",
    component: <ExportAccountList />,
  },
  {
    path: "/account-approval",
    component: <AccountApprovalList />,
  },
  {
    path: "/account-approval-export",
    component: <ExportAccountApprovalList />,
  },
  {
    path: "/general-ledger",
    component: <GeneralLedger />,
  },
  {
    path: "/general-ledger-export",
    component: <ExportGeneralLedger />,
  },
  {
    path: "/account-ledger/:id",
    component: <AccountLedger />,
  },
  {
    path: "/account-ledger-usd/:id",
    component: <AccountLedgerUsd />,
  },
  {
    path: "/vendor-ledger/:id",
    component: <VendorLedger />,
  },
  {
    path: "/account-ledger-export/:id",
    component: <ExportAccountLedger />,
  },
  {
    path: "/reporting-analytics/customer-vehicle-ledger",
    component: <CustomerVehicleLedger />,
  },
  {
    path: "/reporting-analytics/customer-shipping-ledger",
    component: <CustomerShippingLedger />,
  },
  {
    path: "/reporting-analytics/customer-vehicle-vault",
    component: <CustomerVehicleVault />,
  },
  {
    path: "/reporting-analytics/customer-shipping-vault",
    component: <CustomerShippingVault />,
  },
  {
    path: "/general-journal-ledger",
    component: <GeneralJournalLedger />,
  },
  {
    path: "/general-journal-ledger-export",
    component: <ExportGeneralJournalLedger />,
  },
  {
    path: "/master-approval",
    component: <MasterApproval />,
  },
  {
    path: "/option-approval",
    component: <OptionApproval />,
  },
  {
    path: "/create-user",
    component: <CreateUser />,
  },
  {
    path: "/update-user",
    component: <UpdateUser />,
  },
  {
    path: "/user-list",
    component: <UserList />,
  },
  {
    path: "/create-role",
    component: <CreateRole />,
  },
  {
    path: "/update-role",
    component: <UpdateRole />,
  },
  {
    path: "/role-list",
    component: <RoleList />,
  },
  {
    path: "/permission/:id",
    component: <Permission />,
  },
  {
    path: "/create-bank",
    component: <CreateBank />,
  },
  {
    path: "/create-bank-export",
    component: <ExportCreateBank />,
  },
  {
    path: "/update-bank",
    component: <UpdateBank />,
  },
  {
    path: "/update-bank-export",
    component: <ExportUpdateBank />,
  },
  {
    path: "/bank-list",
    component: <BankList />,
  },
  {
    path: "/bank-list-export",
    component: <ExportBankList />,
  },
  {
    path: "/create-vault-top-up",
    component: <CreateVaultTopUp />,
  },
  {
    path: "/update-vault-top-up/:id",
    component: <UpdateVaultTopUp />,
  },
  {
    path: "/vault-top-up",
    component: <VaultTopUpList />,
  },
  {
    path: "/view-vault-top-up/:id",
    component: <ViewVaultTopUp />,
  },
  {
    path: "/payment-voucher",
    component: <PaymentVoucher />,
  },
  {
    path: "/update-payment-voucher/:id",
    component: <UpdatePaymentVoucher />,
  },
  {
    path: "/payment-voucher-export",
    component: <ExportPaymentVoucher />,
  },
  {
    path: "/payment-voucher-list",
    component: <PaymentVoucherList />,
  },
  {
    path: "/payment-voucher-list-export",
    component: <ExportPaymentVoucherList />,
  },
  {
    path: "/voucher-detail/:id",
    component: <VoucherDetail />,
  },
  {
    path: "/voucher-detail-export/:id",
    component: <ExportVoucherDetail />,
  },
  {
    path: "/receipt-voucher",
    component: <ReceiptVoucher />,
  },
  {
    path: "/update-receipt-voucher/:id",
    component: <UpdateReceiptVoucher />,
  },
  {
    path: "/receipt-voucher-export",
    component: <ExportReceiptVoucher />,
  },
  {
    path: "/receipt-voucher-list",
    component: <ReceiptVoucherList />,
  },
  {
    path: "/receipt-voucher-list-export",
    component: <ExportReceiptVoucherList />,
  },
  {
    path: "/receipt-detail/:id",
    component: <ReceiptDetail />,
  },
  {
    path: "/receipt-detail-export/:id",
    component: <ExportReceiptDetail />,
  },
  {
    path: "/create-vendor",
    component: <CreateVendor />,
  },
  {
    path: "/vendor-center",
    component: <VendorCenter />,
  },
  {
    path: "/export-vendor-center",
    component: <ExportVendorCenter />,
  },
  {
    path: "/export-vendor-ledger",
    component: <ExportVendorLedger />,
  },
  {
    path: "/vendor-rate-shipping",
    component: <VendorRateShipping />,
  },
  {
    path: "/vendor-rate-towing",
    component: <VendorRateTowing />,
  },
  {
    path: "/vendor-rate-clearance",
    component: <VendorRateClearance />,
  },
  {
    path: "/vendor-costing",
    component: <VendorCosting />,
  },
  {
    path: "/vendor-funds-apply",
    component: <VendorFundsApply />,
  },
  {
    path: "/export-vendor-funds-apply",
    component: <ExportVendorFundsApply />,
  },
  {
    path: "/book-damage",
    component: <BookDamage />,
  },
  {
    path: "/damage-list",
    component: <DamageList />,
  },
  {
    path: "/client-rates",
    component: <ClientRates />,
  },
  {
    path: "/client-funds-application",
    component: <ClientFundsApplication />,
  },
  {
    path: "/shipping-payment-received-applied",
    component: <ShippingPaymentReceivedApplied />,
  },
  {
    path: "/border-costing-list",
    component: <BorderCostingList />,
  },
  {
    path: "/shipping-payment-details/:id",
    component: <ShippingPaymentDetail />,
  },
  {
    path: "/vehicle-tt",
    component: <VehicleTT />,
  },
  {
    path: "/vehicle-tt-list",
    component: <VehicleTTList />,
  },
  {
    path: "/vehicle-tt-detail/:id",
    component: <VehicleTTDetail />,
  },
  {
    path: "/border-costing-detail/:id",
    component: <BorderCostingDetail />,
  },
  {
    path: "/client-invoice-detail/:id",
    component: <ClientInvoiceView />,
  },
  {
    path: "/storages",
    component: <Storages />,
  },
  {
    path: "/slp-list",
    component: <SlpList />,
  },
  {
    path: "/unpaid-paid-invoices",
    component: <UnpaidPaidInvoices />,
  },
  {
    path: "/vehicle-tt-approval-list",
    component: <VehicleTTApprovalList />,
  },
  {
    path: "/vehicle-available-tt",
    component: <VehicleAvailableForTT />,
  },
  {
    path: "/client-shipping-rate",
    component: <ClientShippingRate />,
  },
  {
    path: "/shipping-costing",
    component: <ShippingCosting />,
  },
  {
    path: "/create-tt",
    component: <CreateTT />,
  },
  {
    path: "/update-tt/:id",
    component: <UpdateTT />,
  },
  {
    path: "/tt-list",
    component: <TTList />,
  },
  {
    path: "/tt-detail/:id",
    component: <ViewTT />,
  },
  {
    path: "/vendor-unapplied-funds",
    component: <VendorUnAppliedFunds />,
  },
  {
    path: "/export-vendor-unapplied-funds",
    component: <ExportVendorUnAppliedFunds />,
  },
  {
    path: "/border-costing",
    component: <BroderCosting />,
  },
  {
    path: "/border-costing-approval",
    component: <BorderCostingApproval />,
  },
  {
    path: "/update-border-costing/:id",
    component: <UpdateBorderCosting />,
  },
  {
    path: "/vendor-funds-apply",
    component: <VendorFundsApply />,
  },
  {
    path: "/vendor-payable-list",
    component: <VendorPayableList />,
  },
  {
    path: "/vendor-funds-approval",
    component: <VendorFundApproval />,
  },
  {
    path: "/vendor-fund-detail/:id",
    component: <VendorFundDetail />,
  },
  {
    path: "/vendor-applied-funds",
    component: <VendorAppliedFunds />,
  },
  {
    path: "/export-vendor-applied-funds",
    component: <ExportVendorAppliedFunds />,
  },
  {
    path: "/vcc-refunded",
    component: <VccRefundedList />,
  },
  {
    path: "/vcc-downloaded-list",
    component: <VccDownloadedList />,
  },
  {
    path: "/vcc-deposited",
    component: <VccDepositedList />,
  },
  {
    path: "/vcc-refunded/:id",
    component: <ViewVccRefunded />,
  },
  {
    path: "/vcc-deposited/:id",
    component: <ViewVccDeposited />,
  },
  {
    path: "/damage-detail/:id",
    component: <DamageDetail />,
  },
  {
    path: "/gate-pass-approval",
    component: <GatePassApproval />,
  },
  {
    path: "/gate-pass-list",
    component: <GatePassList />,
  },
  {
    path: "/customer-accounts",
    component: <CustomerAccountList />,
  },
  {
    path: "/customer-account-ledger/:id",
    component: <CustomerAccountLedger />,
  },
  {
    path: "/reporting-analytics/trial-balance",
    component: <TrialBalance />,
  },
  {
    path: "/reporting-analytics/trial-balance-export",
    component: <ExportTrialBalance />,
  },
  {
    path: "/reporting-analytics/balance-sheet",
    component: <BalanceSheet />,
  },
  {
    path: "/reporting-analytics/balance-sheet-export",
    component: <ExportBalanceSheet />,
  },
  {
    path: "/reporting-analytics/profit-loss-statement",
    component: <ProfitLossStatement />,
  },
  {
    path: "/reporting-analytics/profit-loss-statement-export",
    component: <ExportProfitLossStatement />,
  },
  {
    path: "/reporting-analytics/cashier-activity",
    component: <CashierActivity />,
  },
  {
    path: "/reporting-analytics/cashier-activity-export",
    component: <ExportCashierActivity />,
  },
  {
    path: "/reporting-analytics/vat-due",
    component: <VatDueList />,
  },
  {
    path: "/reporting-analytics/auction-house-due",
    component: <AuctionHouseDueList />,
  },
  {
    path: "create-journal-voucher",
    component: <CreateJournalVoucher />,
  },
  {
    path: "create-journal-voucher-export",
    component: <ExportCreateJournalVoucher />,
  },
  {
    path: "update-journal-voucher-export/:id",
    component: <ExportUpdateJournalVoucher />,
  },
  {
    path: "journal-vouchers",
    component: <JournalVoucherList />,
  },
  {
    path: "journal-vouchers-export",
    component: <ExportJournalVoucherList />,
  },
  {
    path: "/journal-voucher-detail/:id",
    component: <JournalVoucherDetail />,
  },
  {
    path: "/journal-voucher-detail-export/:id",
    component: <ExportJournalVoucherDetail />,
  },
  {
    path: "/create-fund-transfer-voucher",
    component: <CreateFundTransferVoucher />,
  },
  {
    path: "/create-fund-transfer-voucher-export",
    component: <ExportCreateFundTransferVoucher />,
  },
  {
    path: "/fund-transfer-vouchers",
    component: <FundTransferVoucherList />,
  },
  {
    path: "/fund-transfer-vouchers-export",
    component: <ExportFundTransferVoucherList />,
  },
  {
    path: "/fund-transfer-approval-list",
    component: <FundTransferApprovalList />,
  },
  {
    path: "/fund-transfer-approval-list-export",
    component: <ExportFundTransferApprovalList />,
  },
  {
    path: "/fund-transfer-voucher-detail/:id",
    component: <FundTransferVoucherDetail />,
  },
  {
    path: "/fund-transfer-voucher-detail-export/:id",
    component: <ExportFundTransferVoucherDetail />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/reporting-analytics/vehicle-soa",
    component: <VehicleSOAList />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/reporting-analytics/shipping-soa",
    component: <ShippingSOA />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/export-shipping-soa",
    component: <ExportShippingSOA />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/reporting-analytics/shipping-ra",
    component: <ShippingRA />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/reporting-analytics/shipping-ra-aging",
    component: <ShippingRAAging />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/export-shipping-ra-aging",
    component: <ExportShippingRAAging />,
  },

  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/reporting-analytics/shipping-profit",
    component: <ShippingProfit />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/export-cost-profit",
    component: <ExportCostProfit />,
  },
  {
    // path: "/reporting-analytics/vehicle-soa",
    path: "/recovery-soa-export",
    component: <ExportRecoverySOA />,
  },
  {
    path: "/reporting-analytics/vehicle-sum-ledger",
    component: <VehicleSumLedger />,
  },
  {
    path: "/reporting-analytics/vault-dashboard",
    component: <VaultDashboard />,
  },
  {
    path: "/customer-vehicle-vault/invoice/:id",
    component: <ViewCustomerVehicleInvoice />,
  },
  {
    path: "/create-packing",
    component: <PackingListForm />,
  },
  {
    path: "/packing-list-invoice/:id",
    component: <PackingListInvoice />,
  },
  {
    path: "/update-packing-list/:id",
    component: <UpdatePackingList />,
  },
  {
    path: "/gate-pass-payment-voucher",
    component: <GatePassPaymentVoucher />,
  },
];

export default AdminRoutes;
