import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  Tooltip,
} from "@mui/material";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import { DeleteIcon, EditIcon, FontFamily } from "assets";
import Colors from "assets/Style/Colors";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import Pagination from "components/Pagination";
import InputField from "components/Input";
import { useForm } from "react-hook-form";
import moment from "moment";
import DatePicker from "components/DatePicker";
import SelectField from "components/Select";
import ExportServices from "services/Export";
import { PDFExport } from "@progress/kendo-react-pdf";
import CurrencyServices from "services/Currency";
import { PrimaryButton } from "components/Buttons";
import { useNavigate } from 'react-router-dom';
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    backgroundColor: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));
const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
  anchorLink: {
    textDecoration: "underline",
    color: Colors.twitter,
    cursor: "pointer",
  },
});

const UpdateRecovery = () => {
  const classes = useStyles();
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const tableHead = [
    "Select",
    "VIN",
    "Year",
    "Make",
    "Model",
    "Shipment to",
    "Date",
    "REC-Charges (AED)",
    "REC-Charges (USD)",
    "Shifting Charges (AED)",
    "Shifting-Charges (USD)",
    // "Action",
  ];
  const [visibleColumns, setVisibleColumns] = useState([
    ...Array(tableHead?.length).keys(),
  ]);
  const [loader, setLoader] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [wholeCustomers, setWholeCustomers] = useState([]);
  const [selectAgent, setSelectAgent] = useState(null);
  const [selectFrom, setSelectFrom] = useState();
  const [selectedTo, setSelectedTo] = useState();
  const [ExportData, setExportData] = useState();
  const [filters, setFilters] = useState({});
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState();
  const [vinData, setVinData] = useState([])
  const [selectedVin, setSelectedVin] = useState('')
  const [vehicleDetail, setVehicleDetail] = useState([]);
  const [recoveryChareges, setRecoveryCharges] = useState({})
  const [shiftingCharges, setShiftingCharges] = useState({})
  const [agentsData, setAgentsData] = useState([])
  const [selectedVINs, setSelectedVINs] = useState([]);
  const [recoveryChargesAed, setRecoveryChargesAed] = useState()
  const [shiftingChargesAed, setShiftingChargesAed] = useState()
  const [recoveryData, setRecoveryData] = useState(null)
  const [shiftingAgent, setShiftingAgent] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [recoveryCharegesUsd, setRecoveryChargesUsd] = useState(0)
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    agentList();
    getExportVehicles();
    getCurrencies();
    getVins();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleCheck = (data) => {
    try {
      const shallowCopy = [...selectedVehicles];
      const vinCopy = [...selectedVINs];
      const currentIndex = selectedVehicles.findIndex((e) => e === data?.id);

      if (currentIndex) {
        shallowCopy.push(data?.id);
        vinCopy.push(data?.vin);
      } else {
        shallowCopy.splice(currentIndex, 1);
        vinCopy.splice(currentIndex, 1);
      }

      setWholeCustomers([...shallowCopy]);
      setSelectedVehicles(shallowCopy);
      setSelectedVINs(vinCopy);
    } catch (error) {
      ErrorToaster(error);
    }

  };

  const handleAllCheck = (value) => {
    setSelectAll(!selectAll);
    const shallowCopy = [...selectedVehicles];
    if (value) {
      const customerList2 = ExportData.map((data) => data?.id);
      setWholeCustomers([...selectedVehicles, ...customerList2]);
      setSelectedVehicles(shallowCopy);

      setSelectedVehicles(customerList2);
    } else {
      const filteredArray = wholeCustomers.filter(
        (item) => !selectedVehicles.includes(item)
      );
      setSelectedVehicles(filteredArray);
    }
  };
  const agentList = async () => {
    // let params ={
    //   type: ExportData
    // }
    const result = await ExportServices.getAgentsList()
    if (result.status) {
      setAgentsData(result?.data?.agents);
    }
  }
  const getVins = async () => {
    const result = await ExportServices.getVehicleVin()
    if (result?.status) {
      setVinData(
        result?.data?.rows?.map((item) => {
          return { id: item.vin, name: item.vin, vid: item.id };
        })
      );
    }
  }

  const getVinDetail = async (vin) => {
    let params = {
      vin: vin.id,
    };
    if (vin) {
      const result = await ExportServices.getVinDetail(params);
      if (result?.status) {
        const recoveryRes = result?.data?.detail?.recovery
        const recAgent = agentsData.filter((recId) => recId.id === recoveryRes?.recovery_agent_id);
        if (recAgent.length > 0) {
          const agentId = recAgent[0];
          setSelectAgent(agentId)
        }
        const shiftingAgent = agentsData.filter((shfId) => shfId.id === recoveryRes?.shifting_agent_id);
        if (shiftingAgent.length > 0) {
          const agentId = shiftingAgent[0];
          setShiftingAgent(agentId)
        }
        setRecoveryData(result?.data?.detail);
        setValue(`shiftingChargesAed`, recoveryRes ? parseFloat(recoveryRes?.shifting_charges).toFixed(2) : parseFloat(0).toFixed(2));
        setValue(
          `recoveryChargesAed`,
          recoveryRes
            ? parseFloat(recoveryRes?.recovery_charges).toFixed(2)
            : parseFloat(0).toFixed(2)
        );

        const detail = Array.isArray(result?.data?.detail)
          ? result?.data?.detail
          : [result?.data?.detail];

        setVehicleDetail(detail);
      }
      ErrorToaster(result?.error)
    }

  }
  const handleVindetail = (value) => {
    getVinDetail(value)

  }
  const getExportVehicles = async (page, limit, filter) => {
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
      };
      params = { ...params, ...Filter };
      const { data } = await ExportServices.getExportVehicles(params);
      setExportData(data?.vehicles?.rows);
      setTotalCount(data?.vehicles?.count);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  //*Handle Date
  const handleDate = (newDate) => {
    try {
      if (newDate === "Invalid Date") {
        setSelectedDate("invalid");
        return;
      }
      setSelectedDate(new Date(newDate));
      setValue(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleFilter = (data) => {
    getExportVehicles(1, "", data);
  };
  const getCurrencies = async (currency) => {
    try {
      let params = {
        detailed: true,
      };
      const { data } = await CurrencyServices.getCurrencies(params);
      setCurrencyExchangeRate(data.currencies[2].conversion_rate);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  let veh_id = vinData.find(({ name }) => name === selectedVINs[0])?.vid
  const handleUpdateRecovery = async (formData) => {
    if (selectAgent === null && shiftingAgent === null) {
      ErrorToaster('Please select at least one Agent');
      return;
    }
    if (recoveryChargesAed > 0 || shiftingChargesAed > 0) {
      setLoading(true);  // Start loading
      const obj = {
        recoveryCharges: parseFloat(recoveryChargesAed),
        shiftingCharges: parseFloat(shiftingChargesAed),
        vin: selectedVINs[0],
        from: formData?.from,
        to: formData?.to,
        recovery_agent_id: selectAgent?.id,
        recovery_user_id: selectAgent?.user_id,
        shifting_user_id: shiftingAgent?.user_id,
        agent_name: selectAgent?.name,
        shifting_agent_id: shiftingAgent?.id,
        vehicle_id: veh_id,
        broker_costing_date: selectedDate
      };

      try {
        const res = await ExportServices.updateRecovery(obj);
        if (res?.status) {
          reset();
          setAgentsData([]);
          setSelectFrom();
          setSelectedTo();
          SuccessToaster(res?.message);
          navigate("/update-recovery")
        } else {
          ErrorToaster(res?.message);
        }
      } catch (error) {
        ErrorToaster("An error occurred. Please try again.");
      } finally {
        setLoading(false);  // End loading
      }

    } else {
      ErrorToaster("Please add Recovery charges or Shifting Charges");
    }
  };

  const renderCellContent = (colIndex, item, isActive) => {
    const date = moment(item?.vcc_expiry_date).format("DD-MM-YYYY");
    const targetDate = moment(date, "DD-MM-YYYY");
    let daysRemaining = targetDate.diff(moment(), "days");
    if (daysRemaining < 0) {
      daysRemaining = 0;
    }
    const reco_charges = recoveryData?.recovery
      ? parseFloat(
        recoveryData?.recovery?.recovery_charges / currencyExchangeRate
      ).toFixed(2)
      : recoveryChareges[item.id]
        ? recoveryChareges[item.id]
        : "-";
    const shif_charges = recoveryData?.recovery
      ? parseFloat(
        recoveryData?.recovery?.shifting_charges / currencyExchangeRate
      ).toFixed(2)
      : shiftingCharges[item.id]
        ? shiftingCharges[item.id]
        : "-";
    switch (colIndex) {
      case 0:
        return (
          <Checkbox
            className="pdf-hide"
            disabled={
              item?.customerProfile?.customer_type_id == 4 ? true : false
            }
            checked={selectedVehicles.findIndex((e) => e === item?.id) !== -1}
            onChange={() => handleCheck(item)}
          />
        );
      case 1:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.vin}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.vin}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.vin}
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.year ?? "-"}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.year}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.year ?? "-"}
            </Box>
          </Box>
        );
      // item?.name ?? "-";
      case 3:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.make?.name ?? "-"}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.make?.name}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.make?.name ?? "-"}
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.model?.name ?? "-"}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.model?.name}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.model?.name ?? "-"}
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.destination?.name ?? "-"}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.destination?.name ?? "-"}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.destination?.name ?? "-"}
            </Box>
          </Box>
        );
      // item?.email ?? "-";
      case 6:
        return (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={item?.date ? moment(item?.date).format("DD-MM-YYYY") : "-"}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {item?.date ? moment(item?.date).format("DD-MM-YYYY") : "-"}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {item?.date ? moment(item?.date).format("DD-MM-YYYY") : "-"}
            </Box>
          </Box>
        );
      case 7:
        return isActive || recoveryData?.recovery ? (
          <InputField
            size={"small"}
            type={"number"}
            defaultValue={
              item?.recovery?.recovery_charges == null ? 0.0 : item?.recovery?.recovery_charges || 0.0
            }
            // // defaultValue={0.0}
            // InputProps={{
            //   inputProps: {
            //     min: 0,
            //   },
            // }}
            register={register(`recoveryChargesAed`, {
              onChange: (e) => {
                const value = parseFloat(e.target.value);

                const recoveryChargeValue = value ? value : item?.recovery?.recovery_charges;
                setValue(`recoveryChargesAed`, recoveryChargeValue);
                setRecoveryChargesAed(recoveryChargeValue);
                const usdRecoveryCharges =
                  recoveryChargeValue / currencyExchangeRate;
                setRecoveryCharges((prev) => ({
                  ...prev,
                  [item.id]: usdRecoveryCharges.toFixed(2),
                }));
              },
            })}
            inputStyle={{
              width: "100px",
            }}
          />
        ) : (
          0.0 ?? "-"
        );

      case 8:
        return isActive || recoveryData?.recovery ? (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={reco_charges}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {reco_charges}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {recoveryCharegesUsd ? recoveryCharegesUsd : reco_charges}
            </Box>
          </Box>
        ) : (
          0.0 ?? "-"
        );
      case 9:
        return isActive || recoveryData?.recovery?.shifting_charges ? (
          <InputField
            size={"small"}
            type={"number"}
            defaultValue={
              item?.recovery?.shifting_charges == null ? 0.0 : item?.recovery?.shifting_charges || 0.0
            }
            // defaultValue={0.0}
            // InputProps={{
            //   inputProps: {
            //     min: 0,
            //   },
            // }}
            register={register(`shiftingChargesAed`, {
              onChange: (e) => {
                const value = parseFloat(e.target.value);
                const effectiveValue = value ? value : item?.recovery?.shifting_charges;
                setValue(`shiftingChargesAed`, effectiveValue);
                // if (`shiftingChargesAed_${item.id}`) {
                setShiftingChargesAed(effectiveValue);
                // }
                const usdShiftingCharges =
                  effectiveValue / currencyExchangeRate;
                setShiftingCharges((prev) => ({
                  ...prev,
                  [item.id]: usdShiftingCharges.toFixed(2),
                }));
              },
            })}
            inputStyle={{
              width: "100px",
            }}
          />
        ) : (
          0.0 ?? "-"
        );

      case 10:
        return isActive || recoveryData?.recovery?.shifting_charges ? (
          <Box>
            <Tooltip
              className="pdf-hide"
              title={shif_charges}
              arrow
              placement="top"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, -2],
                      },
                    },
                  ],
                },
              }}
            >
              {shif_charges}
            </Tooltip>
            <Box
              component={"div"}
              sx={{ display: "none !important" }}
              className="pdf-show"
            >
              {shif_charges}
            </Box>
          </Box>
        ) : (
          0.0 ?? "-"
        );
      // case 11:
      //   return (
      //     <Box
      //       sx={{
      //         flexDirection: "row",
      //         display: "flex",
      //         gap: 1,
      //         textDecorationLine: "underline",
      //         textDecorationColor: Colors.green,
      //       }}
      //     >
      //       <Box
      //         component={"div"}
      //         sx={{
      //           cursor: "pointer",
      //         }}
      //         onClick={() => { }}
      //       >
      //         <span className="">
      //           <DeleteIcon />
      //         </span>
      //         <Typography
      //           sx={{
      //             fontSize: 14,
      //             fontWeight: "700",
      //             color: Colors.green,
      //           }}
      //         >
      //           {"Delete"}
      //         </Typography>
      //       </Box>
      //       <Box
      //         component={"div"}
      //         sx={{
      //           cursor: "pointer",
      //         }}
      //         onClick={() => { }}
      //       >
      //         <span className="">
      //           <EditIcon />
      //         </span>
      //         <Typography
      //           sx={{
      //             fontSize: 14,
      //             fontWeight: "700",
      //             color: Colors.green,
      //           }}
      //         >
      //           {"Edit"}
      //         </Typography>
      //       </Box>
      //     </Box>
      //   );
      default:
        return "-";
    }
  };
  const fromAddress = [
    {
      name: "Dubai",
    },
    {
      name: "Canada",
    },
    {
      name: "USA",
    },
  ];
  const toAddress = [
    {
      name: "Dubai",
    },
    {
      name: "Canada",
    },
    {
      name: "USA",
    },
  ];
  return (
    <Box
      sx={{ m: 4, mb: 2 }}
      component={"form"}
      onSubmit={handleSubmit(handleUpdateRecovery)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mr: 4,
          my: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          Update Recovery
        </Typography>
      </Box>

      {/* Filters */}
      <Box
        sx={{
          boxShadow: " 0px 3px 10px 0px #00000040",
          p: 3,
          borderRadius: "15px",
        }}
      >
        <Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6} mt={1}>
              <SelectField
                SelectField
                size="small"
                label={"Search"}
                options={vinData}
                selected={selectedVin}
                onSelect={(value) => {
                  handleVindetail(value);
                }}
              // error={errors?.country?.message}
              // register={register("country", {
              //   required: "Please select Country.",
              // })}
              // bgColor={"rgba(0,0,0,0.04)"}
              />
              {/* <InputField
                size={"small"}
                label={"Search"}
                placeholder={"Search by VIN"}
                error={errors?.vin?.message}
                register={register("vin")}
                inputStyle={{ backgroundColor: "rgba(0,0,0,0.04)" }}
              /> */}
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <DatePicker
                label={"Recovery Booking Date"}
                size="small"
                value={selectedDate}
                error={errors?.etaDateVal?.message}
                register={register("recDateVal")}
                onChange={(date) => handleDate(date)}
              />
            </Grid>

            {/* <Grid item xs={12} sm={2} sx={{ mt: 3 }}>
              <PrimaryButton title="Search" type="submit" />
            </Grid> */}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 3,
          backgroundColor: "whtie",
          boxShadow: " 0px 3px 10px 0px #00000040",
          p: 3,
          borderRadius: 3,
        }}
      >
        <Fragment>
          <PDFExport
            ref={contentRef}
            landscape={true}
            paperSize="A4"
            margin={5}
            fileName="Approval Record"
          >
            <TableContainer
              component={Paper}
              sx={{
                boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                borderRadius: 2,
                maxHeight: "calc(100vh - 330px)",
              }}
              className="table-box"
            >
              <Table stickyHeader sx={{ minWidth: 500 }}>
                {/* Table Header */}
                <TableHead>
                  <TableRow className="pdf-table">
                    {visibleColumns.map((index) => (
                      <Cell className="pdf-table" key={index}>
                        {tableHead[index] == "Select" ? (
                          <Checkbox
                            className="pdf-hide"
                            sx={{ color: "white !important" }}
                            checked={selectAll}
                            onChange={() => handleAllCheck(!selectAll)}
                          />
                        ) : (
                          tableHead[index]
                        )}
                      </Cell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {!loader ? (
                    vehicleDetail?.length > 0 ? (
                      <Fragment>
                        {vehicleDetail?.map((item, rowIndex) => {
                          // const isActive = true;
                          const isActive =
                            selectedVehicles.indexOf(item?.id) !== -1;

                          return (
                            <Row
                              key={rowIndex}
                              sx={{
                                bgcolor: rowIndex % 2 !== 0 && "#EEFBEE",
                              }}
                            >
                              {visibleColumns.map((colIndex) => (
                                <Cell className="pdf-table" key={colIndex}>
                                  {renderCellContent(colIndex, item, isActive)}
                                </Cell>
                              ))}
                            </Row>
                          );
                        })}
                      </Fragment>
                    ) : (
                      <Row>
                        <Cell
                          colSpan={tableHead.length + 1}
                          align="center"
                          sx={{ fontWeight: 600 }}
                        >
                          No Data Found
                        </Cell>
                      </Row>
                    )
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={visibleColumns?.length + 2}
                        align="center"
                        sx={{ fontWeight: 600 }}
                      >
                        <Box className={classes.loaderWrap}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </PDFExport>
          {/* ========== Pagination ========== */}
          <Pagination
            currentPage={currentPage}
            pageSize={pageLimit}
            onPageSizeChange={(size) => getExportVehicles(1, size.target.value)}
            tableCount={ExportData?.length}
            totalCount={totalCount}
            onPageChange={(page) => getExportVehicles(page, "")}
          />
        </Fragment>
      </Box>
      <Box
        sx={{
          boxShadow: " 0px 3px 10px 0px #00000040",
          p: 3,
          borderRadius: "15px",
          mt: 4,
        }}
      >
        <Box sx={{ width: "100%" }} component={"form"}>
          <Grid container spacing={1} alignItems="center">
            <Grid marginLeft={-1} item xs={12} sm={6} mt={1}>
              <SelectField
                SelectField
                size="small"
                label={"Recovery Agent"}
                options={agentsData}
                selected={selectAgent}
                onSelect={(value) => {
                  setSelectAgent(value);
                }}
                error={errors?.agent?.message}
                register={register("agent", {
                  // required: "Please select Agent.",
                })}
                bgColor={"rgba(0,0,0,0.04)"}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <SelectField
                SelectField
                size="small"
                label={"Shifting Agent"}
                options={agentsData}
                selected={shiftingAgent}
                onSelect={(value) => {
                  setShiftingAgent(value);
                }}
                error={errors?.agent?.message}
                register={register("shiftingAgent", {
                  // required: "Please select Agent.",
                })}
                bgColor={"rgba(0,0,0,0.04)"}
              />
            </Grid>

            {/* <Grid item xs={12} sm={2} sx={{ mt: 3 }}>
              <PrimaryButton title="Search" type="submit" />
            </Grid> */}

            <Grid
              container
              spacing={1}
              alignItems="center"
              display={"flex"}
              flexDirection={"row"}
              xs={12}
              sm={12}
            >
              <Grid item xs={6} sm={6}>
                {" "}
                {/* Set width to 50% for xs and sm */}
                <SelectField
                  SelectField
                  size="small"
                  label={"From"}
                  options={fromAddress}
                  addNew={(newValue) => setSelectFrom(newValue)}
                  selected={selectFrom}
                  onSelect={(value) => setSelectFrom(value)}
                  error={errors?.from?.message}
                  register={register("from", {
                    required: "Please select Country.",
                  })}
                  recovery={true}
                  bgColor={"rgba(0,0,0,0.04)"}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                {" "}
                {/* Set width to 50% for xs and sm */}
                <SelectField
                  SelectField
                  size="small"
                  label={"To"}
                  options={toAddress}
                  addNew={(newValue) => setSelectedTo(newValue)}
                  selected={selectedTo}
                  onSelect={(value) => setSelectedTo(value.name)}
                  error={errors?.to?.message}
                  register={register("to", {
                    required: "Please select Country.",
                  })}
                  recovery={true}
                  bgColor={"rgba(0,0,0,0.04)"}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid
          sx={{ ml: 0.5, mt: 4.5, justifyContent: "flex-end", display: "flex" }}
        >
          <PrimaryButton
            title="Submit"
            type="submit"
            disabled={loading}
            // style={{ backgroundColor: Colors.bluishCyan }}
          // onClick={() => handleExportWithComponent(contentRef)}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateRecovery;
