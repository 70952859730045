import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";
import { FontFamily } from "assets";
const ClientLedger = ({ resultData }) => {
  const classes = useStyles();
  let TotalVehicleBalance = 0;
  // *For Total Amounts
  let TotalShippingBalance = 0;
  let TotalVehicleLedgerAmount = 0;
  let TotalVehicleVaultAmount = 0;
  let TotalShippingVaultAmount = 0;
  let TotalShippingLedgerAmount = 0;
  let TotalVccDepositAmount = 0;
  let TotalGatePassAmount = 0;
  return (
    <>
      <TableBody>
        {resultData?.map((item, index) => {
          let vehicleLedgerAmount = 0;
          let vehicleVaultAmount = 0;
          let shippingLedgerAmount = 0;
          let shippingVaultAmount = 0;
          let vccDepositAmount = 0;
          let gatePassAmount = 0;

          // *For Accounts Id
          let vehicleAccountId = "";
          let vehicleVaultAccountId = "";
          let shippingVaultAccountId = "";
          let vehicleAccountNature = "";
          let vehicleVaultAccountNature = "";
          let shippingVaultAccountNature = "";
          let shippingAccountId = "";
          let shippingAccountNature = "";
          let vccAccountId = "";
          let vccAccountNature = "";
          let gatePassAccountId = "";
          let gatePassAccountNature = "";

          let GVV;
          let GSV;
          let GVVNature = item?.accounts.find(
            (account) =>
              account.type_code === "L2" && account.primary_series === 50004
          );
          let GSVNature = item?.accounts.find(
            (account) =>
              account.type_code === "L2" && account.primary_series === 50005
          );

          GVV = GVVNature.total_credit - GVVNature.total_debit;
          GSV = GSVNature.total_credit - GSVNature.total_debit;

          for (let i = 0; i < item?.accounts.length; i++) {
            const element = item?.accounts[i];
            const credit = isNaN(element?.total_credit)
              ? 0
              : element?.total_credit ?? 0;
            const debit = isNaN(element?.total_debit)
              ? 0
              : element?.total_debit ?? 0;
            let subTotal =
              element?.nature === "credit"
                ? (parseFloat(credit) - parseFloat(debit)).toFixed(2)
                : (parseFloat(debit) - parseFloat(credit)).toFixed(2);
            if (element?.primary_account_id == 11100005) {
              vehicleAccountNature = element?.nature;
              vehicleLedgerAmount += parseFloat(subTotal);
              TotalVehicleLedgerAmount += parseFloat(subTotal);
              if (element?.currency === "usd") {
                vehicleAccountId = element?.id;
              }
            }
            if (element?.primary_account_id == 11100024) {
              vehicleVaultAccountNature = element?.nature;
              vehicleVaultAmount += parseFloat(subTotal);
              TotalVehicleVaultAmount += parseFloat(subTotal);
              if (element?.currency === "usd") {
                vehicleVaultAccountId = element?.id;
              }
            }
            if (element?.primary_account_id == 11100025) {
              shippingVaultAccountNature = element?.nature;
              shippingVaultAmount += parseFloat(subTotal);
              TotalShippingVaultAmount += parseFloat(subTotal);
              if (element?.currency === "usd") {
                shippingVaultAccountId = element?.id;
              }
            }
            if (element?.primary_account_id == 11100006) {
              shippingAccountId = element?.id;
              shippingAccountNature = element?.nature;
              console.log(shippingAccountNature, "shippingAccountNature");
              shippingLedgerAmount += parseFloat(subTotal);
              TotalShippingLedgerAmount += parseFloat(subTotal);
            }
            if (element?.primary?.name.includes("VCC")) {
              vccAccountId = element?.id;
              vccAccountNature = element?.nature;
              vccDepositAmount += parseFloat(subTotal);
              TotalVccDepositAmount += parseFloat(subTotal);
            }
            if (element?.primary?.name.includes("Gate Pass")) {
              gatePassAccountId = element?.id;
              gatePassAccountNature = element?.nature;
              gatePassAmount += parseFloat(subTotal);
              TotalGatePassAmount += parseFloat(subTotal);
            }
            if (
              element?.type_code === "L2" &&
              element?.primary_series === 50004
            ) {
              if (element?.currency === "usd") {
                subTotal =
                  element?.nature === "credit"
                    ? (parseFloat(credit) - parseFloat(debit)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalVehicleBalance += parseFloat(subTotal);
              }
            }
            if (
              element?.type_code === "L2" &&
              element?.primary_series === 50005
            ) {
              if (element?.currency === "usd") {
                subTotal =
                  element?.nature === "credit"
                    ? (parseFloat(credit) - parseFloat(debit)).toFixed(2)
                    : parseFloat(0).toFixed(2);

                TotalShippingBalance += parseFloat(subTotal);
              }
            }
          }

          return (
            <>
              <TableRow
                sx={{
                  fontSize: "8px !important",
                  background: index % 2 !== 0 ? "#EFF8E7" : "",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.ref_id ?? "-"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.name ?? "-"}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(GVV).toFixed(2))}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(GSV).toFixed(2))}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(vehicleLedgerAmount).toFixed(2))}{" "}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(shippingLedgerAmount).toFixed(2))}{" "}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(vccDepositAmount).toFixed(2))}{" "}
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(parseFloat(gatePassAmount).toFixed(2))}{" "}
                </TableCell>
              </TableRow>
            </>
          );
        })}
        <TableRow>
          <TableCell
            colSpan={3}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            Total:
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVehicleBalance).toFixed(2))}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalShippingBalance).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVehicleLedgerAmount).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVehicleLedgerAmount).toFixed(2))}{" "}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalVccDepositAmount).toFixed(2))}
          </TableCell>
          <TableCell
            colSpan={2}
            sx={{
              whiteSpace: "normal",
              fontSize: "8px !important",
              fontWeight: 700,
              fontFamily: FontFamily.openSans,
              margin: "0px 4px !important",
              padding: "4px 8px !important",
              textAlign: "center",
            }}
          >
            ${CommaSeparator(parseFloat(TotalGatePassAmount).toFixed(2))}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
};

export default ClientLedger;
