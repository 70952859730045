import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";
import { FontFamily } from "assets";

const AuctionHouse = ({ resultData }) => {
  const classes = useStyles();

  return (
    <>
      <TableBody>
        {resultData?.map((item, index) => (
          <>
            <TableRow
              sx={{
                fontSize: "8px !important",
                background: index % 2 !== 0 ? "#EFF8E7" : "",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.customer?.id ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.customer?.name ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.auctioneer ?? "-"}
              </TableCell>{" "}
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.buyer?.name ?? "-"}
              </TableCell>{" "}
              <TableCell
                colSpan={2}
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.vin ?? "-"}
              </TableCell>{" "}
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.lot_number ?? "-"}
              </TableCell>{" "}
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.veh_make?.name ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.veh_model?.name}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.booking?.color ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                $
                {CommaSeparator(parseFloat(item?.remaining_due).toFixed(2)) ??
                  "-"}
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </>
  );
};

export default AuctionHouse;
