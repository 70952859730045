import React, { Fragment } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Button,
} from "@mui/material";
import { FontFamily, Images } from "assets";
import Colors from "assets/Style/Colors";
import { PrimaryButton } from "components/Buttons";
import { useNavigate } from "react-router-dom";
import { CalendarToday } from "@mui/icons-material";

const shadowBox = {
  bgcolor: "transparent",
  width: "100%",
  height: "100%",
  position: "absolute",
  left: "0px",
  top: "0px",
  opacity: "1",
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
  transform: "scale(0.94)",
  filter: "blur(12px)",
  borderRadius: 4,
};

const blogTextStyle = {
  "-webkit-line-clamp": "6",
  "-webkit-box-orient": "vertical",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  wordBreak: "break-all",
  color: Colors.smokeyGrey,
  fontFamily: FontFamily.NunitoRegular,
  mb: 2,
};

function BuyACar() {
  const navigate = useNavigate();

  const blogs = [
    {
      image: Images.navigating,
      title: " Navigating International Auto Shipping: A Comprehensive Guide",
      description: `This blog 
could cover everything from choosing the right shipping method to understanding 
customs regulations and documentation requirements when shipping vehicles 
overseas.`,
      description2: `Shipping a vehicle internationally can be a daunting task, but with the right guidance, it 
becomes manageable and e icient. This comprehensive guide covers everything you need 
to know about navigating international auto shipping.`,
      subDetail: [
        {
          subTitle: `Choosing the Right Shipping Method `,
          subDescription: ` The first step in international auto shipping is 
choosing the right shipping method. Options include container shipping, where your 
vehicle is loaded into a container for transport, or roll-on/roll-o (RoRo) shipping, where 
vehicles are driven onto the ship's deck. Factors to consider when choosing a method 
include cost, convenience, and the type of vehicle being shipped. `,
        },
        {
          subTitle: `Understanding Customs Regulations and Documentation Requirements`,
          subDescription: `Another 
crucial aspect of international auto shipping is understanding customs regulations and 
documentation requirements. This includes providing proof of ownership, vehicle 
registration, and insurance documents. Additionally, some countries may have specific 
import restrictions or requirements, such as emission standards or vehicle modifications. 
It's important to research and comply with these regulations to avoid delays or 
complications during shipping. `,
        },
        {
          subTitle: `Tips for Preparing Your Vehicle for Shipping`,
          subDescription: `Before shipping your vehicle internationally, 
there are several steps you can take to ensure a smooth process. This includes thoroughly 
cleaning the vehicle inside and out, removing personal belongings, securing loose items, 
and documenting any existing damage. It's also recommended to have the vehicle 
inspected by a mechanic and to keep a record of its condition before shipping.`,
        },
        {
          subTitle: `What to Expect During the Shipping Process`,
          subDescription: ` Once your vehicle is ready for shipping, 
you'll work with a shipping company to arrange transportation. They will handle logistics 
such as booking cargo space, coordinating with shipping ports, and managing customs 
clearance. During transit, you can track your vehicle's progress and receive updates until it 
reaches its destination. \n Navigating international auto shipping requires careful planning, attention to detail, and 
working with experienced professionals. By following this comprehensive guide, you can 
ensure a successful and hassle-free shipping experience for your vehicle. 
`,
        },
      ],
    },
    {
      image: Images.autoShow,
      title: "Top Global Auto Events: Must-Visit Shows for Car Enthusiasts",
      description: `Explore the most exciting international car shows, races, and events that attract 
auto enthusiasts from around the world. Highlight key details, dates, and locations 
for these events.`,
      description2: `Auto enthusiasts around the world eagerly anticipate the most exciting international car 
shows, races, and events. These gatherings showcase the latest innovations, classic 
designs, and adrenaline-pumping performances that captivate automotive enthusiasts of 
all kinds.`,
      subDetail: [
        {
          subTitle: `Geneva International Motor Show `,
          subDescription: `One of the most prestigious events in the automotive 
world, the Geneva International Motor Show, held annually in Switzerland, features the 
latest advancements in luxury cars, concept vehicles, and cutting-edge technology. 
Visitors can explore exhibits from top manufacturers and witness unveilings of highly 
anticipated models.`,
        },
        {
          subTitle: `Tokyo Auto Salon`,
          subDescription: `For enthusiasts of Japanese automotive culture, the Tokyo Auto Salon is 
a must-visit event. Held in Tokyo, Japan, this show highlights custom cars, tuning trends, 
and aftermarket accessories. Attendees can experience the vibrant car culture of Japan 
and see unique modifications and designs up close.`,
        },
        {
          subTitle: `Goodwood Festival of Speed`,
          subDescription: ` The Goodwood Festival of Speed, located in West Sussex, 
England, is a celebration of automotive history and performance. This iconic event features 
historic racing cars, modern supercars, and legendary drivers tackling the famous hill climb 
course. It's a thrilling experience for motorsport enthusiasts and spectators alike. 
Monaco Grand Prix As one of the most prestigious Formula 1 races in the world, the 
Monaco Grand Prix attracts auto enthusiasts and celebrities to the glamorous streets of 
Monte Carlo. The race's challenging circuit, scenic backdrop, and luxurious atmosphere 
make it a highlight of the motorsport calendar.`,
        },
        {
          subTitle: `Barrett-Jackson Collector Car Auction`,
          subDescription: `For collectors and enthusiasts of classic and rare 
automobiles, the Barrett-Jackson Collector Car Auction in Scottsdale, Arizona, o ers an 
unparalleled experience. Attendees can bid on a wide range of vehicles, from vintage 
classics to iconic muscle cars, and witness the excitement of live auctions. 
These top destinations for auto enthusiasts o er unforgettable experiences, showcasing 
the passion, innovation, and craftsmanship of the automotive industry on a global scale. 
`,
        },
      ],
    },
    {
      image: Images.sustainable,
      title: "Sustainable Solutions in International Auto Shipping",
      description: ` Discuss eco-friendly practices in the auto shipping industry, including electric vehicle transportation, carbon offsetting, and strategies for reducing environmental impact during international shipping.`,
      description2: `As concerns about environmental impact continue to grow, the auto shipping industry is 
adopting sustainable practices and technologies to reduce its carbon footprint and protect 
the planet. From electric vehicle transportation to carbon o setting options, these 
sustainable solutions are reshaping the future of international auto shipping. `,
      subDetail: [
        {
          subTitle: `Electric Vehicle Transportation`,
          subDescription: `One of the most significant advancements in sustainable 
auto shipping is the adoption of electric vehicle (EV) transportation methods. Electric 
trucks and carriers powered by renewable energy sources help reduce emissions and air 
pollution associated with traditional fuel-powered vehicles. Many shipping companies are 
investing in EV fleets to promote eco-friendly transportation solutions.`,
        },
        {
          subTitle: `Carbon Offsetting Programs`,
          subDescription: `To mitigate the carbon emissions generated during shipping, 
carbon o setting programs o er a solution. These programs involve investing in 
environmental projects such as reforestation, renewable energy initiatives, or carbon 
capture technologies to o set the carbon dioxide released from vehicle transportation. By 
participating in carbon o setting programs, shipping companies can achieve carbon 
neutrality and contribute to global sustainability efforts.`,
        },
        {
          subTitle: `Strategies for Reducing Environmental Impact`,
          subDescription: ` In addition to EV transportation and 
carbon o setting, other strategies for reducing environmental impact in international auto 
shipping include optimizing logistics routes to minimize fuel consumption, implementing 
fuel-e icient vehicle designs, and promoting eco-friendly packaging materials. These 
strategies aim to reduce greenhouse gas emissions, conserve natural resources, and 
protect ecosystems a ected by transportation activities. 
By embracing sustainable solutions, the international auto shipping industry is taking 
proactive steps towards environmental stewardship and creating a more sustainable future 
for generations to come. 
`,
        },
      ],
    },
    {
      image: Images.serviceBanner,
      title: "Expanding Horizons",
      description: ` Galaxy Worldwide Shipping's New Branch in Sharjah Industrial 
Area 13": Dive into the details of Galaxy Worldwide Shipping's latest expansion, 
o ering insights into the services provided, benefits for customers in Sharjah, and 
the company's commitment to enhancing global logistics solutions.`,
      description2: `Galaxy Worldwide Shipping, a trusted name in vehicle shipping, is thrilled to announce the 
grand opening of its new branch dedicated exclusively to vehicle shipping services in 
Sharjah Industrial Area 13. This expansion signifies our commitment to providing 
unparalleled vehicle shipping solutions to our valued clients.`,
      subDetail: [
        {
          subTitle: `Strategic Location for Seamless Vehicle Shipping`,
          subDescription: `Situated strategically in the bustling 
Sharjah Industrial Area 13, our new branch o ers a central hub for all your vehicle shipping 
needs. Whether you're relocating a single vehicle or managing a fleet of automobiles, our 
location ensures easy access and e icient logistics, saving you time and e ort.`,
        },
        {
          subTitle: `Comprehensive Vehicle Shipping Solutions`,
          subDescription: `At Galaxy Worldwide Shipping's new branch, 
we specialize in a range of vehicle shipping services tailored to meet the diverse needs of 
our clients. From cars and motorcycles to boats and RVs, we handle each shipment with 
utmost care and attention to detail. Our team of skilled professionals ensures that your 
vehicles are transported safely and securely to their destination, no matter the distance.`,
        },
        {
          subTitle: `Unmatched Expertise and Customer Satisfactiont`,
          subDescription: `With years of experience in the vehicle 
shipping industry, Galaxy Worldwide Shipping brings unmatched expertise and a proven 
track record of customer satisfaction. Our dedicated team is committed to providing 
personalized service, transparent communication, and timely delivery, making us your 
trusted partner for all your vehicle shipping requirements. 
`,
        },
        {
          subTitle: `Experience Excellence in Vehicle Shipping`,
          subDescription: ` Join us as we embark on this exciting journey 
of expansion and innovation in the vehicle shipping landscape. Galaxy Worldwide 
Shipping's new branch in Sharjah Industrial Area 13 is poised to deliver excellence in 
vehicle shipping services, ensuring a seamless and stress-free experience for our clients. 
Contact us today to experience the Galaxy di erence in vehicle shipping and let us handle 
your shipping needs with precision, reliability, and utmost professionalism. Galaxy 
Worldwide Shipping - Your trusted partner in vehicle shipping excellence. 
`,
        },
      ],
    },
  ];

  return (
    <Fragment>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: 1,
          height: 500,
          bgcolor: Colors.mirage,
          backgroundColor: "rgba(0,0,0,0.9)",
        }}
      >
        <Box
          component="img"
          src={Images.buyCarBanner}
          alt="banner shade"
          style={{
            objectFit: "cover",
            position: "absolute",
            width: "100%",
            height: "500px",
            left: 0,
            top: 0,
            opacity: "0.4",
          }}
        />
        <Container
          style={{
            backgroundColor: "transparent",
          }}
        >
          <Box
            sx={{
              marginTop: "10%",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12} sm={12} md={12} sx={{ zIndex: 5 }}>
                <Typography
                  //   variant="h1"
                  letterSpacing={5}
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "center",
                    fontSize: 20,
                    color: "#5BBC0F",
                  }}
                >
                  BUY QUICK, SELL SMART{" "}
                </Typography>
                <Typography
                  variant="h1"
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "center",
                    // fontSize: 20,
                  }}
                >
                  Galaxy Used Cars TR LLC{" "}
                </Typography>
                <Box
                  md={12}
                  sx={{
                    borderWidth: 5,
                    border: "solid",
                    width: "80%",
                    borderColor: Colors.lightGreen,
                    justifyContent: "center",
                    display: "flex",
                    mt: 2,
                    ml: 15,
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "center",
                    color: Colors.white,
                    px: 26.5,
                    fontFamily: FontFamily.NunitoRegular,
                    mt: 2,
                  }}
                >
                  Galaxy Used Cars TR LLC is one of the premier car dealers in
                  the UAE. Whether you are looking for a new or used vehicle,
                  our website is the premier independent resource to help you
                  select the car that best fits your needs and get it at the
                  best possible price and quality.{" "}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container spacing={0}>
              <Grid item xs={12} sm={8} md={8} sx={{ zIndex: 5 }}>
                <Typography variant="h1" sx={{ textTransform: "capitalize" }}>
                  Buy a Car
                </Typography>
              </Grid>
            </Grid> */}
          </Box>
        </Container>
      </Box>

      <Box sx={{ py: 5, mt: 0, mb: 4 }}>
        <Container>
          <Typography
            variant="h1"
            sx={{
              textAlign: "center",
              fontSize: 44,
              fontWeight: "700px",
              fontFamily: FontFamily.NunitoRegular,
              color: Colors.black,
            }}
          >
            Ready to Transform Your Buying & Selling Experience?
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              color: "rgba(0,0,0,0.6)",
              px: 21,
              fontFamily: FontFamily.NunitoRegular,
              mt: 3,
            }}
          >
            For Immediate Assistance, Please Contact Us Directly At (+971) 6 510
            2000. Our Dedicated Team Will Provide Full Support And Assistance To
            Help You Find The Perfect Vehicle That Meets Your Needs. With Over
            10 Years Of Experience, We Ensure A Hassle-free And Enjoyable Car
            Buying Or Selling Experience. Trust Us To Deliver Quality And
            Affordability Every Time
          </Typography>
          <Box
            component="img"
            src={Images.buyCarlogo}
            alt="banner shade"
            style={{
              objectFit: "cover",
              width: "20%",
              height: "200px",
              display: "block",
              margin: "auto",
            }}
          />
        </Container>
      </Box>
    </Fragment>
  );
}

export default BuyACar;
