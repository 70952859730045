import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Box } from "@mui/material";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import InputField from "components/Input";
import SelectField from "components/Select";
import InputPhone from "components/InputPhone";
import { useParams } from "react-router-dom";
import { PrimaryButton } from "components/Buttons";
import ExportServices from "services/Export"; // API service for CRUD operations
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import SystemServices from "services/System";

const UpdatePackingList = () => {
    const { id } = useParams(); // Get packing list ID from URL params
    const { register, control, handleSubmit, setValue,formState: { errors }, reset } = useForm();
    const { fields, append, remove, replace } = useFieldArray({ control, name: "vehicles" });

    const [loading, setLoading] = useState(true);
    const [containerSizes, setContainerSizes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedContainerSize, setSelectedContainerSize] = useState(null);
    const [selectedPortLoading, setSelectedPortLoading] = useState(null);
    const [selectedPortDischarge, setSelectedPortDischarge] = useState(null);
    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [colorOptions, setColorOptions] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedMake, setSelectedMake] = useState(null);
    const [destinationOptions, setDestinationOptions] = useState([]);

    // Fetch packing list data and populate form
    useEffect(() => {
        const fetchPackingList = async () => {
            try {
                let params = {
                    container_no: id,
                };
                const { data } = await ExportServices.getPackingList(params);
                const packingList = data?.packingList;

                // Set form values
                setValue("address", packingList.address);
                setValue("country", packingList.country);
                setSelectedCountry(packingList.country)
                setValue("phone", packingList.phone);
                setValue("email", packingList.email);
                setValue("seal_no", packingList.seal_no);
                setValue("amount", packingList.amount);
                setValue("container_no", packingList.container_no);
                setSelectedContainerSize(packingList.container_size);
                getContainerSizes('', packingList.container_size)

                setSelectedPortLoading(packingList.port_of_loading);
                setSelectedPortDischarge(packingList.port_of_discharge);

                const vehicles = packingList.packingList.map((vehicle) => ({
                    id: vehicle.id,
                    vehicleType: vehicle.vehicle_type,
                    make: vehicle.veh_make?.name,
                    model: vehicle.veh_model?.name,
                    color: vehicle.color,
                    vin: vehicle.vin,
                    hssCode: vehicle.hss_code,
                    coo: vehicle.coo,
                    weight: vehicle.weight,
                }));

                replace(vehicles); // Pre-fill vehicle data in the form
                setLoading(false);
            } catch (error) {
                ErrorToaster("Failed to fetch packing list data");
                setLoading(false);
            }
        };

        fetchPackingList();
    }, [id, setValue, replace]);

    // Handle form submission
    const onSubmit = async (data) => {
        try {
            const updatedVehicles = data.vehicles.map((vehicle) => ({
                id: vehicle.id, // Include vehicle ID to update
                vehicle_type: vehicle.vehicleType?.name,
                make: vehicle.make?.id,
                model: vehicle.model?.id,
                color: vehicle.color?.id,
                vin: vehicle.vin,
                hss_code: vehicle.hssCode,
                coo: vehicle.coo?.name,
                weight: vehicle.weight,
            }));

            const obj = {
                address: data.address,
                country: data.country,
                phone: data.phone,
                email: data.email,
                seal_no: data.seal_no,
                container_no: data.container_no,
                container_size: selectedContainerSize?.id,
                port_of_loading: selectedPortLoading?.name,
                port_of_discharge: selectedPortDischarge?.name,
                vehicles: updatedVehicles,
                amount : data?.amount,
                container_no:id
            };
            const res = await ExportServices.updatePackingList(obj); // API call to update
            SuccessToaster("Packing list updated successfully");
        } catch (error) {
            ErrorToaster("Failed to update packing list");
        }
    };

    const getCountries = async () => {
        try {
            const { data } = await SystemServices.getCountries();
            setCountries(data?.nations.rows);
        } catch (error) {
            ErrorToaster("Failed to load countries");
        }
    };
    const getMake = async (search) => {
        try {
            let params = {
                limit: 1000,
            };
            const { data } = await ExportServices.getMake(params);
            setMakeOptions(data?.makes?.rows)
        } catch (error) {
            ErrorToaster(error);
        }
    };

    //*For Models
    const getModels = async (val) => {
        try {
            let params = {
                page: 1,
                limit: 1000,
                make_id: val,
            };
            const { data } = await ExportServices.getModel(params);
            setModelOptions(data?.models?.rows);
        } catch (error) {
            ErrorToaster(error);
        }
    };

    //*For Colors
    const getColors = async (search) => {
        try {
            const { data } = await ExportServices.getColors();
            const colorsArray = [];
            data?.colors?.forEach((element) => {
                let obj = {
                    id: element,
                    name: element,
                };
                colorsArray.push(obj);
            });
            setColorOptions(colorsArray);
        } catch (error) {
            ErrorToaster(error);
        }
    };
    const getFinalDestination = async (search) => {
        try {
            let params = {
                page: 1,
                limit: 50,
            };
            const { data } = await ExportServices.getFinalDestination(params);

            const updateData = data?.destinations?.map((item) => {
                return {
                    id: item.id,
                    name: item?.name + "-" + item?.country?.name,
                    country_id: item.country_id,
                };
            });
            setDestinationOptions(updateData);
        } catch (error) {
            ErrorToaster(error);
        }
    };
    const getContainerSizes = async (search, defaultId) => {
        try {
          let params = {
            page: 1,
            limit: 50,
            search: search
          }
          const { data } = await SystemServices.getContainerSizes(params)
          setContainerSizes(data?.cont?.rows)
          if (defaultId) {
            setSelectedContainerSize(data?.cont?.rows.find(e => e?.id === defaultId))
          }
        } catch (error) {
          ErrorToaster(error)
        }
      }
   
    const createModel = async (name) => {
        try {
            let obj = {
                name: name,
                make_id: selectedMake?.id,
            };
            const { data } = await SystemServices.createModel(obj);
            getModels(obj?.make_id);
            setSelectedModel(data?.model);
            setValue("model", data?.model?.name);
        } catch (error) {
            ErrorToaster(error);
        }
    };
    // *For Create Color
    const createColor = async (name) => {
        try {
            let obj = {
                color: name,
            };
            const { data } = await SystemServices.createColor(obj);
            getColors();
            setSelectedColor({ id: data?.color, name: data?.color });
            setValue("color", data?.color);
        } catch (error) {
            ErrorToaster(error);
        }
    };
    useEffect(() => {
    
        getFinalDestination()
        getContainerSizes()
        getCountries()
        // getModels()
        getMake()
        getColors()
    }, []);
    const pickupOptions = [
        { id: "Garage", name: "Garage" },
        { id: "Location in UAE", name: "Location in UAE" },
        { id: "China", name: "China" },
        { id: "Russia", name: "Russia" },
        { id: "Canada", name: "Canada" },
        { id: "Tajikistan", name: "Tajikistan" },
    ];
    return loading ? (
        <div>Loading...</div>
    ) : (
        <Box sx={{ m: 4, mb: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    {/* Address */}
                    <Grid item xs={12} md={8}>
                        <InputField
                            size="small"
                            label="Address"
                            placeholder="Enter Address"
                            register={register("address", { required: "Address is required" })}
                            error={errors?.address?.message}
                        />
                    </Grid>

                    {/* Country */}
                    <Grid item xs={12} md={4}>
                        <SelectField
                            size="small"
                            label="Country"
                            options={countries}
                            selected={selectedCountry}
                            onSelect={(value) => setSelectedCountry(value)}
                            error={errors?.country?.message}
                            register={register("country", {
                                required: "Please select Country.",
                            })}
                        />
                    </Grid>

                    {/* Phone */}
                    <Grid item xs={12} md={4}>
                        <InputPhone
                            label="Phone"
                            name="phone"
                            control={control}
                            size={"small"}
                            error={errors?.phone?.message}
                            onBlur={(e, v) => console.log(v.dialCode)}  // Handle dial code
                            rules={{
                                required: "Please enter your phone number."
                            }}
                        />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} md={4}>
                        <InputField
                            size="small"
                            label="Email"
                            placeholder="Enter Email"
                            register={register("email", {
                                required: "Email is required",
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: "Invalid email address",
                                },
                            })}
                            error={errors?.email?.message}
                        />
                    </Grid>

                    {/* Seal No */}
                    <Grid item xs={12} md={4}>
                        <InputField
                            size="small"
                            label="Seal No."
                            placeholder="Enter Seal No."
                            register={register("seal_no")}
                            error={errors?.seal_no?.message}
                        />
                    </Grid>

                    {/* Container No */}
                    <Grid item xs={12} md={6}>
                        <InputField
                            size="small"
                            label="Container No."
                            placeholder="Enter Container No."
                            register={register("container_no")}
                            error={errors?.container_no?.message}
                        />
                    </Grid>

                    {/* Container Size */}
                    <Grid item xs={12} md={6}>
                        <SelectField
                            size="small"
                            label="Container Size"
                            options={containerSizes}
                            selected={selectedContainerSize}
                            onSelect={(value) => setSelectedContainerSize(value)}
                            error={errors?.container_size?.message}
                        />
                    </Grid>

                    {/* Port of Loading */}
                    <Grid item xs={12} md={4}>
                    <SelectField
                            size="small"
                            label="Port of Loading"
                            options={pickupOptions}
                            selected={selectedPortLoading}
                            onSelect={(value) => setSelectedPortLoading(value)}
                        />
                    </Grid>

                    {/* Port of Discharge */}
                    <Grid item xs={12} md={4}>
                    <SelectField
                            size="small"
                            label="Port of Discharge"
                            options={destinationOptions}
                            selected={selectedPortDischarge}
                            onSelect={(value) => setSelectedPortDischarge(value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputField
                            size="small"
                            label="Amount"
                            placeholder="Enter Amount."
                            register={register("amount",)}
                            error={errors?.amount?.message}
                        />
                    </Grid>
                    {/* Dynamic Vehicle Sections */}
                    {fields.map((vehicle, index) => (
                        <Grid container spacing={2} key={vehicle.id} sx={{ mt: 4 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Edit Vehicle {index + 1}</Typography>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name={`vehicles.${index}.vehicleType`}
                                    control={control}
                                    defaultValue={vehicle.vehicleType || ""}
                                    render={({ field }) => (
                                        <SelectField
                                            label="Vehicle Type"
                                            size="small"
                                            options={[
                                                { name: "Car", value: "Car" },
                                                { name: "Truck", value: "Truck" },
                                                { name: "Motorcycle", value: "Motorcycle" },
                                            ]}
                                            selected={field.value}
                                            onSelect={field.onChange} // Handle change here
                                            error={errors?.vehicles?.[index]?.vehicleType?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name={`vehicles.${index}.make`}
                                    control={control}
                                    defaultValue={vehicle.make || ""}
                                    render={({ field }) => (
                                        <SelectField
                                            size="small"
                                            label="Make"
                                            options={makeOptions}
                                            selected={field.value}
                                            onSelect={(value) => {
                                                field.onChange(value);
                                                getModels(value.id); // Fetch models based on selected make
                                            }}
                                            error={errors?.vehicles?.[index]?.make?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name={`vehicles.${index}.model`}
                                    control={control}
                                    defaultValue={vehicle.model || ""}
                                    render={({ field }) => (
                                        <SelectField
                                            size="small"
                                            label="Model"
                                            options={modelOptions}
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            error={errors?.vehicles?.[index]?.model?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <InputField
                                    size="small"
                                    label="VIN / Chassis No."
                                    placeholder="VIN / Chassis No."
                                    register={register(`vehicles.${index}.vin`)}
                                    error={errors?.vehicles?.[index]?.vin?.message}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <InputField
                                    size="small"
                                    label="HSS Code"
                                    placeholder="HSS Code"
                                    register={register(`vehicles.${index}.hssCode`)}
                                    error={errors?.vehicles?.[index]?.hssCode?.message}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name={`vehicles.${index}.color`}
                                    control={control}
                                    defaultValue={vehicle.color || ""}
                                    render={({ field }) => (
                                        <SelectField
                                            size="small"
                                            label="Color"
                                            options={colorOptions}
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            error={errors?.vehicles?.[index]?.color?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Controller
                                    name={`vehicles.${index}.coo`}
                                    control={control}
                                    defaultValue={vehicle.coo || ""}
                                    render={({ field }) => (
                                        <SelectField
                                            size="small"
                                            label="Country of Origin"
                                            options={[
                                                { name: "Canada", value: "Canada" },
                                                { name: "USA", value: "USA" },
                                            ]}
                                            selected={field.value}
                                            onSelect={field.onChange}
                                            error={errors?.vehicles?.[index]?.coo?.message}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <InputField
                                    size="small"
                                    label="Weight (Kgs)"
                                    placeholder="Enter Weight"
                                    register={register(`vehicles.${index}.weight`)}
                                    error={errors?.vehicles?.[index]?.weight?.message}
                                />
                            </Grid>

                            <Grid item xs={4} mt={4}>
                                <Button variant="contained" color="error" onClick={() => remove(index)}>
                                    Remove Vehicle
                                </Button>
                            </Grid>
                        </Grid>
                    ))}

                    <Grid item xs={1.5}>
                        <PrimaryButton title="Add Vehicle" onClick={() => append({})} />
                    </Grid>

                    <Grid item xs={2}>
                        <PrimaryButton type="submit" title="Update Packing List" />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default UpdatePackingList;
