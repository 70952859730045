import React, { Fragment, useState, useEffect } from "react";
import {
    Grid,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    Checkbox,
    tableCellClasses,
    InputLabel,
    FormControl,
    TableCell,
    Paper,
    Select,
    MenuItem,
    ListItemText,
    CircularProgress,
} from "@mui/material";
import SelectField from "components/Select";
import { PrimaryButton } from "components/Buttons";
import Colors from "assets/Style/Colors";
import { FontFamily } from "assets";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { ErrorToaster } from "components/Toaster";
import moment from "moment";
import FinanceServices from "services/Finance";
import { CircleLoading } from "components/Loaders";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ExportServices from "services/Export";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { CommaSeparator } from "utils";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
    border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        border: 0,
        padding: "15px",
        textAlign: "center",
        whiteSpace: "nowrap",
        background: Colors.primary,
        color: Colors.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        textAlign: "center",
        textWrap: "nowrap",
        padding: "5px !important",

        ".MuiBox-root": {
            display: "flex",
            gap: "6px",
            alignItems: "center",
            justifyContent: "center",
            ".MuiBox-root": {
                cursor: "pointer",
            },
        },
        svg: {
            width: "auto",
            height: "24px",
        },
        ".MuiTypography-root": {
            textTransform: "capitalize",
            fontFamily: FontFamily.NunitoRegular,
            textWrap: "nowrap",
        },
        ".MuiButtonBase-root": {
            padding: "8px",
            width: "28px",
            height: "28px",
        },
    },
}));

const useStyles = makeStyles({
    loaderWrap: {
        display: "flex",
        height: 100,
        "& svg": {
            width: "40px !important",
            height: "40px !important",
        },
    },
});

function ExportRecoverySOA() {
    let TotalBalance = 0;
    // *For Total Amount
    const [allSums, setAllSums] = useState();

    // *For Client Dropdown
    const [angentsList, setAgentsList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [loader, setLoader] = useState(false);
    // *for Payment Type

    const [paymentType, setPaymentType] = useState("aed");
    // *For Cashier Account
    const [cashierAccounts, setCashierAccounts] = useState([]);
    const [selectedCashierAccount, setSelectedCashierAccount] = useState(null);

    // *For Pagination
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);

    const [shippingSOA, setShippingSOA] = useState();

    const [shippingVault, setShippingVault] = useState();

    // *For Filters
    const [filters, setFilters] = useState({});

    // *wallet Id
    const [totalBalance, setTotalBalance] = useState(0);

    const classes = useStyles();

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        setValue,
        getValues: getValues2,
        formState: { errors: errors2 },
    } = useForm();

    const tableHead = [
        "SL. No",
        "Vin",
        "Year",
        "Make",
        "Model",
        "Shipped To",
        "Rec-Ch(AED)",
        "Rec-Ch(USD)",
        "Shi-Ch(AED)",
        "Shi-Ch(USD)"
    ];

    const [visibleColumns, setVisibleColumns] = useState([
        ...Array(tableHead?.length).keys(),
    ]);
    let NoOfVCC = 0;

    const getRecoverAgentList = async (page, limit, filter) => {
        const Page = page ? page : currentPage;
        const Limit = limit ? limit : pageLimit;
        const Filter = { ...filters, ...filter };
        setCurrentPage(Page);
        setPageLimit(Limit);
        setFilters(Filter);
        let params = {
            page: Page,
            limit: Limit,
        };
        params = { ...params, ...Filter };
        const res = await ExportServices.getAgentsList(params);
        if (res?.status) {
            setAgentsList(res?.data?.agents);
        }
    };

    // *For Client Costing
    const getExportRecoveries = async (page, limit, filter) => {
        setLoader(true);
        try {
            const Page = page ? page : currentPage;
            const Limit = limit ? limit : pageLimit;
            const Filter = { ...filters, ...filter };
            setCurrentPage(Page);
            setPageLimit(Limit);
            setFilters(Filter);
            let params = {
                page: Page,
                limit: Limit,
            };
            params = { ...params, ...Filter };
            const { data } = await ExportServices.getAllExportRecoveries(params);
            setShippingSOA(data);
            setTotalCount(data?.pagination?.totalRecords);
        } catch (error) {
            ErrorToaster(error);
        } finally {
            setLoader(false);
        }
    };

    // *For Apply Filters
    const applyFilter = async () => {
        try {
            let data = {
                search: selectedClient?.id,
            };

            getExportRecoveries(1, "", data);
        } catch (error) {
            ErrorToaster(error);
        }
    };

    const sortData = (e, type, item) => {
        e.preventDefault();

        if (type === "ascending" && item == "Buyer ID") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                console.log(a, b);
                return a.booking?.buyer?.name.localeCompare(b.booking?.buyer?.name);
            });

            setShippingSOA(sortedData);
        }

        if (type === "descending" && item == "Buyer ID") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                return b.booking?.buyer?.name.localeCompare(a.booking?.buyer?.name);
            });

            setShippingSOA(sortedData);
        }

        if (type === "ascending" && item == "Model") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                console.log(a, b);
                return a.booking?.veh_model?.name.localeCompare(
                    b.booking?.veh_model?.name
                );
            });

            setShippingSOA(sortedData);
        }

        if (type === "descending" && item == "Model") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                return b.booking?.veh_model?.name.localeCompare(
                    a.booking?.veh_model?.name
                );
            });

            setShippingSOA(sortedData);
        }
        if (type === "ascending" && item == "Make") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                console.log(a, b);
                return a.booking?.veh_make?.name.localeCompare(
                    b.booking?.veh_make?.name
                );
            });

            setShippingSOA(sortedData);
        }
        if (type === "descending" && item == "Make") {
            console.log("deefgghe");
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                return b.booking?.veh_make?.name.localeCompare(
                    a.booking?.veh_make?.name
                );
            });

            setShippingSOA(sortedData);
        }

        if (type === "ascending" && item === "LOT") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                const costA = parseFloat(a.booking?.lot_number) || 0;
                const costB = parseFloat(b.booking?.lot_number) || 0;
                console.log(costA, costB); // Add this line for debugging
                return costA - costB;
            });

            setShippingSOA(sortedData);
        }
        if (type === "descending" && item === "LOT") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                const costA = parseFloat(a.booking?.lot_number) || 0;
                const costB = parseFloat(b.booking?.lot_number) || 0;
                console.log(costA, costB); // Add this line for debugging
                return costB - costA;
            });

            setShippingSOA(sortedData);
        }

        if (type === "ascending" && item == "VIN") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                console.log(a, b);
                return a.booking?.vin.localeCompare(b.booking?.vin);
            });

            setShippingSOA(sortedData);
        }

        if (type === "descending" && item == "VIN") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                return b.booking?.vin.localeCompare(a.booking?.vin);
            });

            setShippingSOA(sortedData);
        }

        if (type === "ascending" && item == "Color") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                console.log(a, b);
                return a.booking?.color.localeCompare(b.booking?.color);
            });

            setShippingSOA(sortedData);
        }

        if (type === "descending" && item == "Color") {
            const sortedData = [...shippingSOA].sort((a, b) => {
                // Use the localeCompare method for string comparison
                return b.booking?.color.localeCompare(a.booking?.color);
            });

            setShippingSOA(sortedData);
        }
    };

    const handleColumnChange = (event) => {
        const selectedColumns = event.target.value;
        // Sort the selected columns to maintain the correct order
        const sortedColumns = selectedColumns.sort((a, b) => a - b);
        setVisibleColumns(sortedColumns);
    };

    const renderCellContent = (colIndex, item, isActive, rowIndex) => {
        TotalBalance +=
            parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
        // eslint-disable-next-line default-case
        switch (colIndex) {
            case 0:
                return rowIndex + 1;
            case 1:
                return item?.vin
                    ? item?.vin
                    : "-";
            case 2:
                return item?.vehicle?.year ?? "-";
            case 3:
                return item?.vehicle?.make.name ?? "-";
            case 4:
                return item?.vehicle?.model.name ?? "-";
            case 5:
                return item?.to ?? "-";
            case 6:
                return parseFloat(item?.recovery_charges).toFixed(2)
                    ? CommaSeparator(parseFloat(item?.recovery_charges).toFixed(2))
                    : "-";
            case 7:
                return parseFloat(item?.recovery_charges).toFixed(2)
                    ? CommaSeparator(parseFloat(item?.recovery_charges).toFixed(2))
                    : "-";
            case 8:
                return parseFloat(item?.shifting_charges).toFixed(2)
                    ? CommaSeparator(parseFloat(item?.shifting_charges).toFixed(2))
                    : "-";
            case 9:
                return parseFloat(item?.shifting_charges).toFixed(2)
                    ? CommaSeparator(parseFloat(item?.shifting_charges).toFixed(2))
                    : "-";
        }
    };

    const downloadExcel = () => {
        const headers = tableHead;
        const rows = shippingSOA?.data?.map((item, colIndex) => {
            TotalBalance +=
                parseFloat(item?.final_price) - parseFloat(item?.vendor_paid);
            return [
                colIndex + 1,
                item?.date ? moment(item?.date).format("DD-MMM-YYYY") : "-",
                item?.model?.name ?? "-",
                item?.make.name ?? "-",
                item?.vin ?? "-",
                item?.color ?? "-",
                parseFloat(item?.price).toFixed(2) ?? "-",
                parseFloat(item?.discount).toFixed(2) ?? "-",
                `USD ${parseFloat(item?.vendor_paid).toFixed(2)}`,
                `USD ${parseFloat(
                    parseFloat(item?.final_price) - parseFloat(item?.vendor_paid)
                ).toFixed(2)}`,
            ];
        });

        const totalRows = [
            "",
            "",
            "",
            "",
            "",
            "Total Due",
            "",
            "",
            "",
            `USD ${parseFloat(allSums).toFixed(2)}`,
        ];

        const ws = XLSX.utils.aoa_to_sheet([headers, ...rows, totalRows]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const buf = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
            mimeType:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(new Blob([buf]), "data.xlsx");
    };

    useEffect(() => {
        if (paymentType) {
            const cashierDetail = cashierAccounts.find(
                (e) => e.currency === paymentType
            );
            setValue("cash", cashierDetail?.name);
            setSelectedCashierAccount(cashierDetail);
        }
    }, [paymentType]);

    useEffect(() => {
        getRecoverAgentList();
    }, []);

    return (
        <Fragment>
            <Grid
                container
                spacing={1}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Grid item xs={12} sm={12} md={11}>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            mr: 4,
                            my: 4,
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: Colors.charcoalGrey,
                                fontFamily: FontFamily.NunitoRegular,
                                ml: "5px",
                            }}
                        >
                            Export Recovery SOA
                        </Typography>
                        {shippingSOA?.data?.length > 0 && (
                            <PrimaryButton
                                title={"Download Excel"}
                                onClick={() => downloadExcel()}
                            />
                        )}
                    </Box>
                </Grid>

                <Grid item md={11}>
                    <Box
                        sx={{
                            m: "20px 0 20px 0",
                            p: "20px",
                            bgcolor: Colors.feta,
                            border: `1px solid ${Colors.iron}`,
                            borderRadius: "9px",
                        }}
                    >
                        <Grid
                            container
                            spacing={1}
                            alignItems={"center"}
                            component={"form"}
                            onSubmit={handleSubmit(applyFilter)}
                        >
                            <Grid item xs={12} md={3}>
                                <SelectField
                                    size="small"
                                    label={"Select Agent"}
                                    options={angentsList}
                                    selected={selectedClient}
                                    onSelect={(value) => {
                                        setSelectedClient(value);
                                        // setShowTable(true);
                                    }}
                                    error={errors?.client?.message}
                                    register={register("client", {
                                        required: "Please select client.",
                                    })}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box
                                    sx={{
                                        mt: "12px",
                                    }}
                                >
                                    <PrimaryButton type="submit" title="Search" />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item md={11}>
                    {shippingSOA?.data && (
                        <Box>
                            <Grid container mb={2}>
                                <Grid item xs={5}>
                                    <FormControl>
                                        <InputLabel>Columns</InputLabel>
                                        <Select
                                            size={"small"}
                                            multiple
                                            value={visibleColumns}
                                            label={"Columns"}
                                            onChange={handleColumnChange}
                                            renderValue={() => "Show/Hide"}
                                        >
                                            {tableHead.map((column, index) => {
                                                if (
                                                    column !== "Balance" &&
                                                    column !== "Past Due Days" &&
                                                    column !== "Paid"
                                                ) {
                                                    return (
                                                        <MenuItem key={index} value={index}>
                                                            <Checkbox
                                                                checked={visibleColumns.includes(index)}
                                                            />
                                                            <ListItemText primary={column} />
                                                        </MenuItem>
                                                    );
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            {shippingSOA?.data && (
                                <Box>
                                    <TableContainer
                                        component={Paper}
                                        sx={{
                                            boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                                            borderRadius: 2,
                                            maxHeight: "calc(100vh - 330px)",
                                        }}
                                    >
                                        <Table stickyHeader sx={{ minWidth: 500 }}>
                                            {/* Table Header */}
                                            <TableHead>
                                                <TableRow>
                                                    {visibleColumns.map((index) => (
                                                        <>
                                                            <Cell key={index}>
                                                                {tableHead[index]}{" "}
                                                                {tableHead[index] === "S.No" ||
                                                                    tableHead[index] === "Vin" ||
                                                                    tableHead[index] === "Year" ||
                                                                    tableHead[index] === "Make" ||
                                                                    tableHead[index] === "Model" ||
                                                                    tableHead[index] === "Shipped To" ||
                                                                    tableHead[index] === "AED Rate" ||
                                                                    tableHead[index] === "USD" ||
                                                                    tableHead[index] === "AED Rate" ||
                                                                    tableHead[index] === "USD" ? (
                                                                    ""
                                                                ) : (
                                                                    <>
                                                                        {" "}
                                                                        <ArrowUpwardIcon
                                                                            sx={{
                                                                                color: "white",
                                                                                fontSize: "15px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={(e) =>
                                                                                sortData(e, "ascending", tableHead[index])
                                                                            }
                                                                        />{" "}
                                                                        <ArrowDownwardIcon
                                                                            sx={{
                                                                                color: "white",
                                                                                fontSize: "15px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={(e) =>
                                                                                sortData(
                                                                                    e,
                                                                                    "descending",
                                                                                    tableHead[index]
                                                                                )
                                                                            }
                                                                        />{" "}
                                                                    </>
                                                                )}
                                                            </Cell>
                                                        </>

                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            {/* Table Body */}
                                            <TableBody>
                                                {!loader ? (
                                                    shippingSOA?.data?.length > 0 ? (
                                                        <Fragment>
                                                            {shippingSOA?.data?.map((item, rowIndex) => {
                                                                const isActive = true;
                                                                return (
                                                                    <Row
                                                                        key={rowIndex}
                                                                        sx={{
                                                                            bgcolor: rowIndex % 2 !== 0 && "#EFF8E7",
                                                                        }}
                                                                    >
                                                                        {visibleColumns.map((colIndex) => (
                                                                            <Cell key={colIndex}>
                                                                                {renderCellContent(
                                                                                    colIndex,
                                                                                    item,
                                                                                    isActive,
                                                                                    rowIndex
                                                                                )}
                                                                            </Cell>
                                                                        ))}
                                                                    </Row>
                                                                );
                                                            })}
                                                            <Row>
                                                                <Cell colSpan={visibleColumns?.length - 1}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{ fontWeight: 700 }}
                                                                    >
                                                                        Total Due
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{ fontWeight: 700 }}
                                                                    >
                                                                        USD {CommaSeparator(parseFloat(allSums).toFixed(2))}
                                                                    </Typography>
                                                                </Cell>
                                                            </Row>
                                                            <Row>
                                                                <Cell colSpan={visibleColumns?.length - 5}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{ fontWeight: 700 }}
                                                                    >
                                                                        Net Due Total
                                                                    </Typography>
                                                                </Cell>

                                                                <Cell colSpan={3}>
                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{ fontWeight: 700 }}
                                                                    >
                                                                        USD{" "}
                                                                        {CommaSeparator(parseFloat(
                                                                            parseFloat(totalBalance) -
                                                                            parseFloat(
                                                                                shippingVault?.nature === "credit"
                                                                                    ? isNaN(
                                                                                        parseFloat(
                                                                                            shippingVault?.total_credit
                                                                                        ) -
                                                                                        parseFloat(
                                                                                            shippingVault?.total_debit
                                                                                        )
                                                                                    )
                                                                                        ? parseFloat(0).toFixed(2)
                                                                                        : parseFloat(
                                                                                            parseFloat(
                                                                                                shippingVault?.total_credit
                                                                                            ) -
                                                                                            parseFloat(
                                                                                                shippingVault?.total_debit
                                                                                            )
                                                                                        ).toFixed(2)
                                                                                    : isNaN(
                                                                                        parseFloat(
                                                                                            shippingVault?.total_debit
                                                                                        ) -
                                                                                        parseFloat(
                                                                                            shippingVault?.total_credit
                                                                                        )
                                                                                    )
                                                                                        ? parseFloat(0).toFixed(2)
                                                                                        : parseFloat(
                                                                                            parseFloat(
                                                                                                shippingVault?.total_debit
                                                                                            ) -
                                                                                            parseFloat(
                                                                                                shippingVault?.total_credit
                                                                                            )
                                                                                        ).toFixed(2)
                                                                            )
                                                                        ).toFixed(2))}
                                                                    </Typography>
                                                                </Cell>
                                                                <Cell></Cell>
                                                            </Row>
                                                        </Fragment>
                                                    ) : (
                                                        <Row>
                                                            <Cell
                                                                colSpan={tableHead.length + 1}
                                                                align="center"
                                                                sx={{ fontWeight: 600 }}
                                                            >
                                                                No Data Found
                                                            </Cell>
                                                        </Row>
                                                    )
                                                ) : (
                                                    <TableRow>
                                                        <TableCell
                                                            colSpan={visibleColumns?.length + 2}
                                                            align="center"
                                                            sx={{ fontWeight: 600 }}
                                                        >
                                                            <Box className={classes.loaderWrap}>
                                                                <CircularProgress />
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}

                            {loader && <CircleLoading />}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default ExportRecoverySOA;
