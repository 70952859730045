import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import { FontFamily } from "assets";
import React from "react";
import { CommaSeparator } from "utils";

const BalanceSheet = ({ resultData, libalTotal, capitalTotal, type }) => {
  return (
    <TableBody>
      {resultData?.map((values, index) => {
        let Total = 0;
        let Total2 = 0;
        let GrandTotal = 0;
        let TotalEquity = 0;
        let GrandTotal2 = 0;
        return (
          <>
            <TableRow className="table-row">
              <TableCell
                sx={{
                  fontSize: "12px !important",
                  fontWeight: 700,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                colSpan={1}
              >
                {values?.name}
              </TableCell>
              <TableCell colSpan={5}></TableCell>
            </TableRow>
            {values?.sub
              ? values?.sub.map((val, subIndex) => (
                  <React.Fragment key={val.name}>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontSize: "10px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          margin: "0px 4px !important",
                          padding: "4px 8px !important",
                          textAlign: "center",
                        }}
                        colSpan={1}
                      >
                        {val?.name}
                      </TableCell>
                      <TableCell colSpan={5}></TableCell>
                    </TableRow>
                    {val?.accounts &&
                      val?.accounts.map((data, index2) => {
                        let childFinalTotal = 0;
                        const credit = isNaN(data?.total_credit)
                          ? 0
                          : data?.total_credit;
                        const debit = isNaN(data?.total_debit)
                          ? 0
                          : data?.total_debit;
                        let childTotal =
                          data?.nature === "debit"
                            ? parseFloat(debit) - parseFloat(credit)
                            : parseFloat(credit) - parseFloat(debit);

                        if (data?.childAccounts?.length > 0) {
                          const initialValue = {
                            credit: 0,
                            debit: 0,
                          };

                          const result = data?.childAccounts?.reduce(
                            (accumulator, transaction) => {
                              const credit = isNaN(transaction?.total_credit)
                                ? 0
                                : transaction?.total_credit;
                              const debit = isNaN(transaction?.total_debit)
                                ? 0
                                : transaction?.total_debit;
                              return {
                                credit:
                                  parseFloat(accumulator.credit) +
                                  parseFloat(credit),
                                debit:
                                  parseFloat(accumulator.debit) +
                                  parseFloat(debit),
                              };
                            },
                            initialValue
                          );
                          childTotal =
                            data?.nature === "debit"
                              ? parseFloat(result?.debit) -
                                parseFloat(result?.credit)
                              : parseFloat(result?.credit) -
                                parseFloat(result?.debit);
                        }
                        Total += parseFloat(childTotal);
                        GrandTotal += parseFloat(childTotal);
                        if (index !== 0) {
                          TotalEquity += parseFloat(childTotal);
                        }
                        return (
                          <React.Fragment key={index2}>
                            <TableRow className="table-row table-row-sub">
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_code}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_category}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_subcategory}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              ></TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                $
                                {CommaSeparator(
                                  parseFloat(childTotal.toFixed(2))
                                )}
                              </TableCell>
                            </TableRow>
                            {type === "balance_summary" &&
                              data?.childAccounts &&
                              data.childAccounts.map(
                                (childData, childIndex) => {
                                  const credit = isNaN(childData?.total_credit)
                                    ? 0
                                    : childData?.total_credit;
                                  const debit = isNaN(childData?.total_debit)
                                    ? 0
                                    : childData?.total_debit;
                                  let subTotal =
                                    childData?.nature === "debit"
                                      ? (
                                          parseFloat(debit) - parseFloat(credit)
                                        ).toFixed(2)
                                      : (
                                          parseFloat(credit) - parseFloat(debit)
                                        ).toFixed(2);

                                  childFinalTotal += parseFloat(subTotal);
                                  return (
                                    <TableRow
                                      key={childIndex}
                                      sx={{
                                        background: "#EFF8E7",
                                      }}
                                      className="table-green-row table-row"
                                    >
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_code}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_name}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_category}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_subcategory}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        $
                                        {CommaSeparator(
                                          parseFloat(subTotal).toFixed(2)
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      ></TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </React.Fragment>
                        );
                      })}
                    {val?.accounts?.[0]?.type_code && (
                      <TableRow className="table-row">
                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          Total Of {val?.accounts[0]?.type_code}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          Total {val?.name}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={1}
                        ></TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={1}
                        >
                          ${CommaSeparator(parseFloat(Total).toFixed(2))}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              : resultData?.map((val, subIndex) => (
                  <React.Fragment key={val.name}>
                    {/* <TableRow>
                    <TableCell
                      sx={{
                        fontSize: "10px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                      colSpan={1}
                    >
                      {val?.name}
                    </TableCell>
                    <TableCell colSpan={5}></TableCell>
                  </TableRow> */}
                    {val?.accounts &&
                      val?.accounts.map((data, index2) => {
                        let childFinalTotal = 0;
                        const credit = isNaN(data?.total_credit)
                          ? 0
                          : data?.total_credit;
                        const debit = isNaN(data?.total_debit)
                          ? 0
                          : data?.total_debit;
                        let childTotal =
                          data?.nature === "debit"
                            ? parseFloat(debit) - parseFloat(credit)
                            : parseFloat(credit) - parseFloat(debit);

                        if (data?.childAccounts?.length > 0) {
                          const initialValue = {
                            credit: 0,
                            debit: 0,
                          };

                          const result = data?.childAccounts?.reduce(
                            (accumulator, transaction) => {
                              const credit = isNaN(transaction?.total_credit)
                                ? 0
                                : transaction?.total_credit;
                              const debit = isNaN(transaction?.total_debit)
                                ? 0
                                : transaction?.total_debit;
                              return {
                                credit:
                                  parseFloat(accumulator.credit) +
                                  parseFloat(credit),
                                debit:
                                  parseFloat(accumulator.debit) +
                                  parseFloat(debit),
                              };
                            },
                            initialValue
                          );
                          childTotal =
                            data?.nature === "debit"
                              ? parseFloat(result?.debit) -
                                parseFloat(result?.credit)
                              : parseFloat(result?.credit) -
                                parseFloat(result?.debit);
                        }
                        Total += parseFloat(childTotal);
                        GrandTotal += parseFloat(childTotal);
                        if (index !== 0) {
                          TotalEquity += parseFloat(childTotal);
                        }
                        return (
                          <React.Fragment key={index2}>
                            <TableRow className="table-row table-row-sub">
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_code}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_category}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                {data?.account_subcategory}
                              </TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              ></TableCell>
                              <TableCell
                                sx={{
                                  fontSize: "10px !important",
                                  fontWeight: 600,
                                  fontFamily: FontFamily.openSans,
                                  margin: "0px 4px !important",
                                  padding: "4px 8px !important",
                                  textAlign: "center",
                                }}
                                colSpan={1}
                              >
                                $
                                {CommaSeparator(
                                  parseFloat(childTotal.toFixed(2))
                                )}
                              </TableCell>
                            </TableRow>
                            {type === "balance_summary" &&
                              data?.childAccounts &&
                              data.childAccounts.map(
                                (childData, childIndex) => {
                                  const credit = isNaN(childData?.total_credit)
                                    ? 0
                                    : childData?.total_credit;
                                  const debit = isNaN(childData?.total_debit)
                                    ? 0
                                    : childData?.total_debit;
                                  let subTotal =
                                    childData?.nature === "debit"
                                      ? (
                                          parseFloat(debit) - parseFloat(credit)
                                        ).toFixed(2)
                                      : (
                                          parseFloat(credit) - parseFloat(debit)
                                        ).toFixed(2);

                                  childFinalTotal += parseFloat(subTotal);
                                  return (
                                    <TableRow
                                      key={childIndex}
                                      sx={{
                                        background: "#EFF8E7",
                                      }}
                                      className="table-green-row table-row"
                                    >
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_code}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_name}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_category}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        {childData?.account_subcategory}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      >
                                        $
                                        {CommaSeparator(
                                          parseFloat(subTotal).toFixed(2)
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          fontSize: "10px !important",
                                          fontWeight: 600,
                                          fontFamily: FontFamily.openSans,
                                          margin: "0px 4px !important",
                                          padding: "4px 8px !important",
                                          textAlign: "center",
                                        }}
                                        colSpan={1}
                                      ></TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </React.Fragment>
                        );
                      })}
                    {val?.accounts?.[0]?.type_code && (
                      <TableRow className="table-row">
                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          Total Of {val?.accounts[0]?.type_code}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={2}
                        >
                          Total {val?.name}
                        </TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={1}
                        ></TableCell>

                        <TableCell
                          sx={{
                            fontSize: "10px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          colSpan={1}
                        >
                          ${CommaSeparator(parseFloat(Total).toFixed(2))}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
            <TableRow
              sx={{
                background: index !== 0 ? "#27ADDF" : "#5abc0e",
              }}
              className="table-row-blue"
            >
              <TableCell
                sx={{
                  fontSize: "10px !important",
                  fontWeight: 700,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                  color: "white",
                }}
                colSpan={2}
              >
                Total
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "10px !important",
                  fontWeight: 700,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                  color: "white",
                }}
                colSpan={2}
                className="total-name"
              >
                Total {values?.name}
              </TableCell>
              <TableCell colSpan={1}></TableCell>
              <TableCell
                sx={{
                  fontSize: "10px !important",
                  fontWeight: 700,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                  color: "white",
                }}
                colSpan={1}
              >
                ${CommaSeparator(parseFloat(GrandTotal).toFixed(2))}
              </TableCell>
            </TableRow>
          </>
        );
      })}
      <TableRow
        sx={{
          background: "#5abc0e",
        }}
        className="table-row-blue"
      >
        <TableCell
          sx={{
            fontSize: "10px !important",
            fontWeight: 700,
            fontFamily: FontFamily.openSans,
            margin: "0px 4px !important",
            padding: "4px 8px !important",
            textAlign: "center",
            color: "white",
          }}
          colSpan={2}
        >
          Total
        </TableCell>
        <TableCell
          sx={{
            fontSize: "10px !important",
            fontWeight: 700,
            fontFamily: FontFamily.openSans,
            margin: "0px 4px !important",
            padding: "4px 8px !important",
            textAlign: "center",
            color: "white",
          }}
          colSpan={2}
          className="total-name"
        >
          Owner Capital + Liabilities
        </TableCell>
        <TableCell colSpan={1}></TableCell>
        <TableCell
          sx={{
            fontSize: "10px !important",
            fontWeight: 700,
            fontFamily: FontFamily.openSans,
            margin: "0px 4px !important",
            padding: "4px 8px !important",
            textAlign: "center",
            color: "white",
          }}
          colSpan={1}
        >
          $
          {CommaSeparator(
            parseFloat(
              parseFloat(libalTotal) + parseFloat(capitalTotal)
            ).toFixed(2)
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default BalanceSheet;
