import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { FacebookOutlined, Instagram, LinkedIn, X } from "@mui/icons-material";
import Colors from "assets/Style/Colors";

import "./style.css";
import { Icons } from "assets";
function SocialLinks() {
  const iconBtn = {
    borderRadius: 0,
    width: "50px",
    height: "70px",
    transition: "0.3s ease-in-out",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        right: 0,
        top: "10%",
        zIndex: 99,
      }}
    >
      <nav className="social">
        <ul>
          <li>
            <a
              style={{ alignItems: "center", display: "flex", gap: 5 }}
              href="https://twitter.com/GWWS_LLC"
              target={"_blank"}
            >
              <img
                src={Icons.twitter}
                width={40}
                height={40}
                style={{ borderRadius: 50 }}
              />{" "}
              Twitter
            </a>
          </li>
          <li>
            <a
              style={{ alignItems: "center", display: "flex", gap: 5 }}
              href="https://www.linkedin.com/company/galaxyworldwideshipping"
              target={"_blank"}
            >
              <img
                src={Icons.linkedIn}
                width={40}
                height={40}
                style={{ borderRadius: 50 }}
              />{" "}
              Linkedin
            </a>
          </li>
          <li>
            <a
              style={{ alignItems: "center", display: "flex", gap: 5 }}
              href="https://www.facebook.com/GalaxyWorldWideShipping"
              target={"_blank"}
            >
              <img
                src={Icons.fbIcon}
                width={40}
                height={40}
                style={{ borderRadius: 50 }}
              />{" "}
              Facebook
            </a>
          </li>
          <li>
            <a
              style={{ alignItems: "center", display: "flex", gap: 5 }}
              href="https://www.instagram.com/GalaxyWorldWideshippingllc"
              target={"_blank"}
            >
              <img
                src={Icons.insta}
                width={40}
                height={40}
                style={{ borderRadius: 50 }}
              />{" "}
              Instagram
            </a>
          </li>
        </ul>
      </nav>
      {/* <Typography
        component={"a"}
        href={"https://www.facebook.com/GalaxyWorldWideShipping"}
        target={"_blank"}
      >
        <IconButton
          sx={{
            ...iconBtn,
            bgcolor: Colors.facebook2,
            "&:hover": { bgcolor: Colors.facebook2 },
          }}
        >
          <FacebookOutlined sx={{ fontSize: "26px", color: Colors.white }} />
        </IconButton>
      </Typography>
      <Typography
        component={"a"}
        href={"https://www.instagram.com/GalaxyWorldWideshippingllc"}
        target={"_blank"}
      >
        <IconButton
          sx={{
            ...iconBtn,
            bgcolor: Colors.instagram2,
            "&:hover": { bgcolor: Colors.instagram2 },
          }}
        >
          <Instagram sx={{ fontSize: "26px", color: Colors.white }} />
        </IconButton>
      </Typography>
      <Typography
        component={"a"}
        href={"https://twitter.com/GWWS_LLC"}
        target={"_blank"}
      >
        <IconButton
          sx={{
            ...iconBtn,
            bgcolor: Colors.black,
            "&:hover": { bgcolor: Colors.black },
          }}
        >
          <X sx={{ fontSize: "26px", color: Colors.white }} />
        </IconButton>
      </Typography>
      <Typography
        component={"a"}
        href={"https://www.linkedin.com/company/galaxyworldwideshipping"}
        target={"_blank"}
      >
        <IconButton
          sx={{
            ...iconBtn,
            bgcolor: Colors.linkedin2,
            "&:hover": { bgcolor: Colors.linkedin2 },
          }}
        >
          <LinkedIn sx={{ fontSize: "26px", color: Colors.white }} />
        </IconButton>
      </Typography> */}
    </Box>
  );
}

export default SocialLinks;
