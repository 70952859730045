import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Box,
    Grid,
    Typography,
    TableContainer,
    TableHead,
    Paper,
} from "@mui/material";
import React from "react";
import ShippingLanscape from "./shipping_short_landscape.svg";
import ShippingPotarit from "./shipping_sjort_potrait.svg";
import CarLanscape from "./used_car_landscape.svg";
import CarPotarit from "./used_car_potrait.svg";
import { FontFamily } from "assets";
import moment from "moment";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";

const ExportPDF = ({
    invoiceName,
    selectedCustomer,
    mode,
    fromdate,
    todate,
    type,
    selectedStatus,
    tableHead,
    resultData,
    total,
    currencyExchangeRate,
    shippingVault,
    totalVCC,
    vehicleVault,
    allSums,
    mainTotal,
    totalBalance,
    totalPaid,
    dueBreakup,
    totalVehicleAmount,
    otherCharges,
    netBalance,
    total30days,
    total15Days,
    total16Days,
    receivableTotal,
    libalTotal,
    capitalTotal,
    filter,
}) => {
    const classes = useStyles();
    const headerImage = ShippingLanscape


    const today = new Date();
    const currentDate = moment(today).format("DD-MMM-YY HH:mm");
    const startDate = fromdate && moment(fromdate).format("DD-MMM-YY");
    const endDate = todate && moment(todate).format("DD-MMM-YY");
    const status = selectedStatus && selectedStatus?.name;
    let Balance = 0;
    let TotalShippingBalance = 0;
    let TotalVehicleLedgerAmount = 0;
    let TotalVehicleVaultAmount = 0;
    let TotalShippingVaultAmount = 0;
    let TotalShippingLedgerAmount = 0;
    let TotalVccDepositAmount = 0;
    let TotalGatePassAmount = 0;
    let TotalVehicleBalance = 0;
    const getCellData = (header, row, index) => {
        switch (header) {
            case "SL. No":
                return index+1;
            case "Invoice #":
                return row.date ? moment(row.date).format("DD-MMM-YY") : "-";
            case "CMR Date":
                return row.cmr_date ? moment(row.cmr_date).format("DD-MMM-YY") : "-";
            case "CMR No.":
                return row.cmr_number;
            case "Model":
                return row.model?.name || "-";
            case "Make":
                return row.make?.name || "-";
            case "Vin/Container.#":
                return row.vin;
            case "COLOR":
                return row.color;
            case "Status":
                return row.status?.name || "-";
            case "Final Destination":
                return row.destination?.name || "-";
            case "SHIPPING CHARGE":
                return `$${CommaSeparator(parseFloat(row.costing?.total_gws_usd || 0).toFixed(2))}`;
            case "Other Charges":
                return `$${CommaSeparator(parseFloat(row.other_charges || 0).toFixed(2))}`;
            case "Discount":
                return `$${CommaSeparator(parseFloat(row.discount || 0).toFixed(2))}`;
            case "Net Due":
                return CommaSeparator(parseFloat(row?.price).toFixed(2)) ?? "-";
            case "Paid Amount":
                return `$${CommaSeparator(parseFloat(row.vendor_paid || 0).toFixed(2))}`;
            case "Balance Due":
                return `$${(parseFloat(row.price || 0) - parseFloat(row.vendor_paid || 0)).toFixed(2)}`;
            case "Final Paid Date":
                return row.final_paid_date ? moment(row.final_paid_date).format("DD-MMM-YY") : "-";
            case "Past Due Days":
                const dueDate = moment(row.eta);
                const today = moment();
                return today.diff(dueDate, "days") > 0 ? today.diff(dueDate, "days") : "-";
            default:
                return "-";
        }
    };


    return (
        <>
            <Box
                sx={{
                    minHeight: mode === "landscape" ? "210mm" : "297mm !important",
                }}
            >
                {invoiceName === "Balance Sheet" ||
                    invoiceName === "Trial Balance" ||
                    invoiceName === "Profit or Loss Statement" ? (
                    ""
                ) : (
                    <Box
                        component="img"
                        src={headerImage}
                        sx={{
                            marginBottom: "0px",
                        }}
                        width="100%"
                        height="80px"
                    />
                )}
                <Box className="invoice">
                    <Box className="invoice-container">
                        <Grid
                            container
                            className="main-cont"
                            spacing={
                                invoiceName === "Balance Sheet" ||
                                    invoiceName === "Trial Balance" ||
                                    invoiceName === "Profit or Loss Statement"
                                    ? 0
                                    : 1
                            }
                            sx={{
                                background: "#EFF8E7",
                                display: "flex",
                                justifyContent: "center",
                                // alignItems: "center",
                            }}
                        >

                            <>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "start",
                                        textAlign: "start",
                                        paddingLeft: "4px",
                                    }}
                                >
                                    <Box sx={{ paddingLeft: "4px" }}>
                                        <Box>
                                            <Typography
                                                sx={{
                                                    borderBottom: "1px solid black",
                                                    fontWeight: 700,
                                                    marginBottom: "12px",
                                                    fontFamily: FontFamily.openSans,
                                                    fontSize: "14px !important",
                                                }}
                                            >
                                                Statement To :-
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                className="client-font"
                                                sx={{
                                                    fontSize: "8px !important",
                                                    fontWeight: 700,
                                                    width: "80px",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                Client Name:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "11px !important",
                                                    textTransform: "capitalize",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                {(selectedCustomer && selectedCustomer?.name)
                                                    ? selectedCustomer?.name
                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "8px !important",
                                                    fontWeight: 700,
                                                    width: "80px",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                Customer ID:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "11px !important",
                                                    textTransform: "capitalize",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                {(selectedCustomer && selectedCustomer?.ref_id)
                                                    ? selectedCustomer?.ref_id

                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                        <Box
                                            c
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "8px !important",
                                                    fontWeight: 700,
                                                    width: "80px",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                Phone:
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: "11px !important",
                                                    textTransform: "capitalize",
                                                    fontFamily: FontFamily.openSans,
                                                }}
                                            >
                                                {(selectedCustomer && selectedCustomer?.uae_phone)
                                                    ? selectedCustomer?.uae_phone
                                                    : "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: "flex",
                                        alignItems: "start",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: FontFamily.openSans,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontSize: "14px !important",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {invoiceName}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end",
                                        paddingRight: "4px",
                                    }}
                                >
                                    <Box>
                                        {startDate && endDate && (
                                            <Table
                                                sx={{
                                                    width: "100%",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                borderTop: "1px solid",
                                                                borderLeft: "1px solid",
                                                                borderRight: "1px solid",
                                                                borderTopLeftRadius: "4px",
                                                                borderTopRightRadius: "4px",
                                                                padding: "2px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontFamily: FontFamily.openSans,
                                                                    fontSize: "8px !important",
                                                                    color: "#5abc0e",
                                                                }}
                                                            >
                                                                {status ? status : "All"}{" "}
                                                                {invoiceName
                                                                }
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                borderTop: "1px solid",
                                                                borderLeft: "1px solid",
                                                                borderRight: "1px solid",
                                                                borderTopLeftRadius: "4px",
                                                                borderTopRightRadius: "4px",
                                                                padding: "2px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 700,
                                                                    fontSize: "8px !important",
                                                                    fontFamily: FontFamily.openSans,
                                                                }}
                                                            >
                                                                Statement Period
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            sx={{
                                                                border: "1px solid",
                                                                borderBottomLeftRadius: "4px",
                                                                borderBottomRightRadius: "4px",
                                                                padding: "2px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Box>
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 700,
                                                                                fontSize: "8px !important",
                                                                                fontFamily: FontFamily.openSans,
                                                                                margin: "6px",
                                                                            }}
                                                                            component="span"
                                                                        >
                                                                            From:
                                                                        </Typography>
                                                                        <Typography
                                                                            sx={{
                                                                                fontWeight: 700,
                                                                                fontSize: "8px !important",
                                                                                fontFamily: FontFamily.openSans,
                                                                                color: "#5abc0e",
                                                                            }}
                                                                            component="span"
                                                                        >
                                                                            {startDate}
                                                                        </Typography>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Box
                                                                        sx={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                        }}
                                                                    >
                                                                        <Box>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 700,
                                                                                    fontSize: "8px !important",
                                                                                    fontFamily: FontFamily.openSans,
                                                                                    margin: "6px",
                                                                                }}
                                                                                component="span"
                                                                            >
                                                                                To:
                                                                            </Typography>
                                                                            <Typography
                                                                                sx={{
                                                                                    fontWeight: 700,
                                                                                    fontSize: "8px !important",
                                                                                    fontFamily: FontFamily.openSans,
                                                                                    color: "#5abc0e",
                                                                                }}
                                                                                component="span"
                                                                            >
                                                                                {endDate}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        )}
                                        <Box
                                            sx={{
                                                padding: "8px",
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "end",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    width: "80px",
                                                    fontSize: "8px !important",
                                                    fontFamily: FontFamily.openSans,
                                                    fontWeight: 700,
                                                }}
                                                variant="span"
                                            >
                                                Generated on:{" "}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: "8px !important",
                                                    fontFamily: FontFamily.openSans,
                                                    fontWeight: 700,
                                                }}
                                                variant="span"
                                            >
                                                {currentDate}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </>

                        </Grid>
                    </Box>
                </Box>
                <Box>
                    <TableContainer
                        component={Paper}
                        sx={{
                            boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                            borderRadius: "4px",
                            minWidth: "600px",
                            minHeight: "297mm",
                        }}
                        className="table-box"
                    >
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        background: "#5abc0e",
                                    }}
                                >
                                    {tableHead.map((item, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                whiteSpace: "normal",
                                                fontSize: "6px !important",
                                                fontWeight: 700,
                                                fontFamily: FontFamily.openSans,
                                                color: "white !important",
                                                textAlign: "center",
                                                padding: "4px 8px !important",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "6px !important",
                                                    fontWeight: 700,
                                                    fontFamily: FontFamily.openSans,
                                                    color: "white !important",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {resultData?.map((row, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        sx={{
                                            fontSize: "8px !important",
                                            background: rowIndex % 2 !== 0 ? "#EFF8E7" : "",
                                        }}
                                    >
                                        {tableHead?.map((header, cellIndex) => (
                                            <TableCell
                                                key={cellIndex}
                                                sx={{
                                                    whiteSpace: "normal",
                                                    fontSize: "6px !important",
                                                    fontWeight: 600,
                                                    fontFamily: FontFamily.openSans,
                                                    padding: "4px 8px !important",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {getCellData(header, row, rowIndex)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                                 {/* Totals Row */}
    <TableRow>
        <TableCell
            sx={{
                whiteSpace: "normal",
                fontSize: "8px !important",
                fontWeight: 700,
                fontFamily: FontFamily.openSans,
                padding: "4px 8px !important",
                textAlign: "right",
            }}
            colSpan={tableHead?.length - 5} // Ensure it spans the correct number of columns before Net Due, Paid Amount, Balance Due
        >
            Total:
        </TableCell>

        {/* Display Other Charges under Net Due */}
        <TableCell
            sx={{
                whiteSpace: "normal",
                fontSize: "8px !important",
                fontWeight: 700,
                fontFamily: FontFamily.openSans,
                padding: "4px 8px !important",
                textAlign: "center",
            }}
        >
            ${CommaSeparator(parseFloat(otherCharges).toFixed(2))}
        </TableCell>

        {/* Display Total Paid under Paid Amount */}
        <TableCell
            sx={{
                whiteSpace: "normal",
                fontSize: "8px !important",
                fontWeight: 700,
                fontFamily: FontFamily.openSans,
                padding: "4px 8px !important",
                textAlign: "center",
            }}
        >
            ${CommaSeparator(parseFloat(totalPaid).toFixed(2))}
        </TableCell>

        {/* Display Total Balance under Balance Due */}
        <TableCell
            sx={{
                whiteSpace: "normal",
                fontSize: "8px !important",
                fontWeight: 700,
                fontFamily: FontFamily.openSans,
                padding: "4px 8px !important",
                textAlign: "center",
            }}
        >
            ${CommaSeparator(parseFloat(totalBalance).toFixed(2))}
        </TableCell>
    </TableRow>
                            </TableBody>
                        </Table>
                        <TableRow style={{ display: "flex", justifyContent: "center" }}>
                            <TableCell
                                colSpan={5}
                                sx={{
                                    whiteSpace: "normal",
                                    fontSize: "8px !important",
                                    fontWeight: 700,
                                    fontFamily: FontFamily.openSans,
                                    margin: "0px 4px !important",
                                    padding: "4px 8px !important",
                                    textAlign: "center",
                                }}
                            >
                                Net Due Total:
                            </TableCell>
                            <TableCell colSpan={7}></TableCell>
                            <TableCell
                                colSpan={2}
                                sx={{
                                    whiteSpace: "normal",
                                    fontSize: "8px !important",
                                    fontWeight: 700,
                                    fontFamily: FontFamily.openSans,
                                    margin: "0px 4px !important",
                                    padding: "4px 8px !important",
                                    textAlign: "center",
                                }}
                            >
                                ${CommaSeparator(parseFloat(allSums).toFixed(2))}
                            </TableCell>
                            <TableCell colSpan={4}></TableCell>
                        </TableRow>
                        <Box sx={{ background: "#EFF8E7", padding: "8px", marginTop: "16px" }}>
                            <Grid container spacing={1} display="flex" alignItems="center" justifyContent="space-around">
                                <Grid item xs={2} display="flex" alignItems="center">
                                    <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans, marginRight: "2px" }}>Shipping Wallet</Typography>
                                    <Typography sx={{ border: "1px solid #B2B5BA", background: "white", fontFamily: FontFamily.openSans, padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "65px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        ${CommaSeparator(parseFloat(shippingVault?.total_credit || 0) - parseFloat(shippingVault?.total_debit || 0))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} display="flex" alignItems="center">
                                    <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans, marginRight: "2px" }}>Vehicle Wallet</Typography>
                                    <Typography sx={{ border: "1px solid #B2B5BA", background: "white", fontFamily: FontFamily.openSans, padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "65px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        ${CommaSeparator(parseFloat(vehicleVault?.total_credit || 0) - parseFloat(vehicleVault?.total_debit || 0))}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.5} display="flex" alignItems="center" justifyContent="end">
                                    <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans, marginRight: "2px" }}>Due Break Up</Typography>
                                    <Box sx={{ border: "1px solid #B2B5BA", background: "white", padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "75px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        <Box>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>Below 7 Days</Typography>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>${CommaSeparator(dueBreakup?.below7DaysTotal)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box sx={{ border: "1px solid #B2B5BA", background: "white", padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "75px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        <Box>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>8 - 14 Days</Typography>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>${CommaSeparator(dueBreakup?.below8To14DaysTotal)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box sx={{ border: "1px solid #B2B5BA", background: "white", padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "75px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        <Box>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>Over 15 Days</Typography>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>${CommaSeparator(dueBreakup?.over15DaysTotal)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} justifyContent="end">
                                    <Box sx={{ border: "1px solid #B2B5BA", background: "white", padding: "4px", textAlign: "center", color: "black", borderRadius: "6px", width: "75px", height: "40px", fontSize: "8px !important", fontWeight: 700 }}>
                                        <Box>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>Total</Typography>
                                            <Typography sx={{ fontSize: "8px !important", fontWeight: 700, fontFamily: FontFamily.openSans }}>${CommaSeparator(dueBreakup?.total)}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </TableContainer>

                </Box>

                <Box
                    className="bottom-box"
                    sx={{
                        marginTop: "10px",
                        position: "absolute",
                        bottom: 1,
                        left: 1,
                        right: 0,
                    }}
                >
                    <Box
                        sx={{
                            background: "#EFF8E7",
                            color: "black",
                            display: "flex",
                            justifyContent: "start",
                            minWidth: "100%",
                        }}
                    >
                        <Box
                            sx={{
                                width: " 100%",
                                padding: "2px 6px",
                                textAlign: "start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "8px !important",
                                    fontWeight: 700,
                                    display: "flex",
                                    justifyContent: "start",
                                    fontFamily: FontFamily.openSans,
                                }}
                            >
                                1- This is a system-generated statement. No signature is required. If you have any queries, please discuss within 7 days; otherwise, it will be assumed to be true and fair.
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "8px !important",
                                    fontWeight: 700,
                                    display: "flex",
                                    justifyContent: "start",
                                    fontFamily: FontFamily.openSans,
                                }}
                            >
                                2- Kindly settle the payment due at the earliest , We would like to remind you to be in touch with our customer service support team. 
                            </Typography>
                            {/* <Typography
                                sx={{
                                    fontSize: "8px !important",
                                    fontWeight: 700,
                                    display: "flex",
                                    justifyContent: "start",
                                    fontFamily: FontFamily.openSans,
                                }}
                            >
                                3- Kindly settle the payment due at the earliest, we would
                                like to remind you to be in touch with our customer service
                                support team.
                            </Typography> */}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: " 100%",
                            color: "#FFFFFF",
                            padding: "1.5%",
                            backgroundColor: " #5abc0e",
                            textAlign: "center",
                            fontSize: "8px !important",
                            fontWeight: 700,
                        }}
                    >
                        <Typography
                            sx={{
                                wordBreak: "break-word",
                                fontSize: "8px !important",
                                fontWeight: 700,
                                fontFamily: FontFamily.openSans,
                            textAlign: "center",

                            }}
                            className="footer-div-font"
                        >
                           Customer care Contact: Mohammed Rafi 00971 54 583 6030 or Mr Hamdan 00974 54 583 6022
                        </Typography>
                    </Box>
                </Box>

            </Box>
        </>
    );
};

export default ExportPDF;
