import { Fragment } from "react";
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import { Debounce } from "utils";
import { LocationIcon } from "assets";

function SelectField(props) {
  const {
    label,
    size,
    disabled,
    onSearch,
    addNew,
    multiple,
    selected,
    onSelect,
    register,
    error,
    options,
    recovery,
    bgColor,
  } = props;

  const filter = createFilterOptions();

  // *For Handle Filter Option
  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // *Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option.name);
    if (inputValue !== "" && !isExisting && addNew) {
      filtered.push({
        inputValue,
        name: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  // *For Handle Option Label
  const handleOptionLabel = (option) => {
    // *Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // *Add new option created dynamically
    if (option.inputValue && addNew) {
      return option.inputValue;
    }
    // *Regular option
    return option.name;
  };

  // *For Handle Change
  const handleChange = (newValue) => {
    if (typeof newValue === "string") {
      onSelect(newValue);
      return;
    }
    if (newValue && newValue.inputValue && addNew) {
      addNew(newValue?.inputValue);
      return;
    }
    return onSelect(newValue);
  };

  // *For Handle Search
  const handleSearch = (value) => {
    if (onSearch) {
      Debounce(() => onSearch(value));
    }
  };

  return (
    <Fragment>
      <InputLabel
        sx={{ color: "#323B4B" }}
        error={error && selected === "" && true}
      >
        {label}
      </InputLabel>
      <Autocomplete
        disabled={disabled}
        size={size}
        multiple={multiple}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        value={selected}
        options={options}
        filterOptions={(options, params) =>
          handleFilterOptions(options, params)
        }
        getOptionLabel={(option) => handleOptionLabel(option)}
        onChange={(event, newValue) => handleChange(newValue)}
        onInputChange={(event, newInputValue) => handleSearch(newInputValue)}
        sx={{ mt: 1, mb: !error && 2 }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={label}
            error={error}
            {...register}
            InputProps={{
              ...params.InputProps,
              // startAdornment: recovery ? (
              //   <LocationIcon style={{ marginRight: "4px" }} />
              // ) : null,
              endAdornment: (
                <Fragment>{params.InputProps.endAdornment}</Fragment>
              ),
            }}
            sx={{ backgroundColor: bgColor }}
          />
          // <TextField
          //   {...params}
          //   placeholder={label}
          //   error={error}
          //   {...register}
          //   InputProps={{
          //     ...params.InputProps,
          //     endAdornment: (
          //       <Fragment>{params.InputProps.endAdornment}</Fragment>
          //     ),
          //   }}
          //   sx={{ backgroundColor: recovery ? "rgba(0,0,0,0.04)" : "#ffff" }}
          // />
        )}
      />
      {error && (
        <Typography variant="caption" color="error" sx={{ textAlign: "left" }}>
          {error}
        </Typography>
      )}
    </Fragment>
  );
}

export default SelectField;
