import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function ProtectedRoute({ children }) {
	const { pathname } = useLocation();
	const { permission } = useSelector((state) => state.navigationReducer);

	// if (
	// 	pathname !== "/dashboard" &&
	// 	pathname !== "/404" &&
	// 	!permission?.includes(pathname.replace(/\/(\d+)$/, ""))
	// ) {

	// 	return <Navigate to="/404" replace />;
	// }

	return children;
}

export default ProtectedRoute;
