import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, tableCellClasses } from '@mui/material';
import QRCode from 'qrcode.react';
import Colors from 'assets/Style/Colors';
import { PDFExport } from '@progress/kendo-react-pdf';
import { PrimaryButton } from 'components/Buttons';
import styled from '@emotion/styled';
import { FontFamily } from 'assets';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import ExportServices from 'services/Export';
import { ErrorToaster } from 'components/Toaster';
import moment from 'moment';

const Row = styled(TableRow)(({ theme }) => ({
    border: 0,
}));
const Cell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        border: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        background: Colors.primary,
        color: Colors.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        fontFamily: FontFamily.NunitoRegular,
        textAlign: 'center',
        textWrap: 'nowrap',
        padding: '5px !important',

        '.MuiBox-root': {
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
            justifyContent: 'center',
            '.MuiBox-root': {
                cursor: 'pointer'
            }
        },
        'svg': {
            width: 'auto',
            height: '24px'
        },
        '.MuiTypography-root': {
            textTransform: 'capitalize',
            fontFamily: FontFamily.Halvetica,
            textWrap: 'nowrap',
        },
        '.MuiButtonBase-root': {
            padding: '8px',
            width: '28px',
            height: '28px',
        }
    },

}));

const useStyles = makeStyles((theme) => ({
    loaderWrap: {
        display: 'flex',
        height: 100,
        '& svg': {
            width: '40px !important',
            height: '40px !important'
        }
    },
    text: {
        color: Colors.black,
        fontWeight: 400,
        fontFamily: FontFamily.NunitoRegular,
    },
    textBold: {
        fontWeight: 700,
        fontFamily: FontFamily.Halvetica,
        marginBottom: theme.spacing(1), // Adjust spacing as needed
    },
}))

const PackingListInvoice = () => {
    const contentRef = useRef(null);
    const { id } = useParams();
    const [packingDetail, setPackingDetail] = useState(null)
    const [loader, setLoader] = useState(null)
    const classes = useStyles();
    const packingListData = {
        invoiceNo: 'ICL-1771',
        date: '06/09/2024',
        containerNo: 'GCKU5775781',
        containerSize: '40 feet',
        sealNo: '1234566878',
        items: [
            { hssCode: '1651651', make: 'Used Car - Chevrolet Malibu LT - White', vin: '1C4RJFDJ7FC730960', coo: 'USA', weight: '2336 KGS', model: '2015', value: '$3,000.00' },
            { hssCode: '1651651', make: 'Used Car - Chevrolet Malibu LT - White', vin: '1C4RJFDJ7FC730960', coo: 'USA', weight: '1750 KGS', model: '2010', value: '$3,000.00' },
            { hssCode: '1651651', make: 'New Car - Chevrolet Malibu LT - White', vin: '1C4RJFDJ7FC730960', coo: 'Germany', weight: '1400 KGS', model: '2014', value: '$2,700.00' }
        ],
        totalWeight: '1400 KGS',
        totalValue: '$2,700.00',
        portOfLoading: 'Jebel Ali, Dubai, UAE',
        portOfDischarge: 'Rotterdam, Netherlands',
        totalAmountInWords: 'USD SIX THOUSAND SEVEN HUNDRED ONLY',
        totalPcs: 3
    };
    useEffect(()=>{
        getExportInvoice()
    },[])
    const getExportInvoice = async () => {
		setLoader(true);
		try {
			let params = {
				container_no: id,
			};
			const { data } = await ExportServices.getPackingList(params);
			setPackingDetail(data?.packingList);
		} catch (error) {
			ErrorToaster(error);
		} finally {
			setLoader(false);
		}
	};
    // Function to handle PDF export
    const handleExportWithComponent = () => {
        if (contentRef.current) {
            contentRef.current.save();
        }
    };
    const tableHead = ['SL#', 'HSS Code', 'Vehicle Make', 'VIN / Chassis no.', 'COO', 'Weight (Kgs)', 'Model', 'Value (USD)'];
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ width: '80%', p: 2 }}> {/* Reduced padding */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <PrimaryButton
                        title="Download PDF"
                        type="button"
                        style={{ backgroundColor: Colors.bluishCyan }}
                        onClick={handleExportWithComponent}
                    />
                </Box>

                {/* PDF Export with adjusted size and consistent header/footer */}
                <PDFExport
                    ref={contentRef}

                    fileName="PackingInvoice"
                    landscape={false}
                >
                    <Paper elevation={3} sx={{ p: 6, mt: 2 }}>
                        {/* Header */}
                        <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
                            <Typography variant="h5" sx={{ fontWeight: 'bold', fontFamily: FontFamily.Merriweather }}>
                                INVOICE & PACKING LIST
                            </Typography>
                        </Box>

                        <Grid item spacing={2} sx={{ fontFamily: FontFamily.Halvetica, justifyContent: "space-between", display: "flex" }}>
                            {/* Ship To Section */}
                            <Grid item xs={6}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#009933' }}>
                                    Ship To:
                                </Typography>
                                <Typography
                                    variant="body1"
                                    display={"flex"}
                                    gap={1}
                                    className={classes.text}
                                    sx={{ mb: 1 }} >
                                    Full Address:
                                    <Typography
                                        fontFamily={FontFamily?.NunitoRegular}
                                        className={classes.text}>
                                        {packingDetail?.address}
                                    </Typography>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    display={"flex"}
                                    gap={1}
                                    className={classes.text}
                                    sx={{ mb: 1 }} >
                                    Phone:
                                    <Typography
                                        fontFamily={FontFamily?.NunitoRegular}
                                        className={classes.text}>
                                                                                {packingDetail?.phone}

                                    </Typography>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    display={"flex"}
                                    gap={1}
                                    className={classes.text}
                                    sx={{ mb: 1 }} >
                                    Country:
                                    <Typography
                                        fontFamily={FontFamily?.NunitoRegular}
                                        className={classes.text}>
                                                                                {packingDetail?.country}

                                    </Typography>
                                </Typography>
                                <Typography
                                    variant="body1"
                                    display={"flex"}
                                    gap={1}
                                    className={classes.text}
                                    sx={{ mb: 1 }} >
                                    Email ID:
                                    <Typography
                                        fontFamily={FontFamily?.NunitoRegular}
                                        className={classes.text}>
                                                                                {packingDetail?.email}

                                    </Typography>
                                </Typography>

                            </Grid>

                            {/* Invoice Details Section */}
                            <Grid item xs={6} mt={4}>

                                {/* Invoice Number */}
                                <Typography gap={1} display={"flex"} variant="body1" className={classes.text}>
                                    Invoice No:
                                    <Typography fontFamily={FontFamily?.NunitoRegular} component="span" className={classes.text}>
                                    {packingDetail?.id}

                                    </Typography>
                                </Typography>

                                {/* Date */}
                                <Typography gap={1} display={"flex"} variant="body1" className={classes.text}>
                                    Date:
                                    <Typography fontFamily={FontFamily?.NunitoRegular} component="span" className={classes.text}>
                                    {moment(packingDetail?.created_at).format('DD-MM-YYYY')}

                                    </Typography>
                                </Typography>

                                {/* Container Number */}
                                <Typography gap={1} display={"flex"} variant="body1" className={classes.text}>
                                    Container No:
                                    <Typography fontFamily={FontFamily?.NunitoRegular} component="span" className={classes.text}>
                                    {packingDetail?.container_no}

                                    </Typography>
                                </Typography>

                                {/* Container Size */}
                                <Typography gap={1} display={"flex"} variant="body1" className={classes.text}>
                                    Container Size:
                                    <Typography fontFamily={FontFamily?.NunitoRegular} component="span" className={classes.text}>
                                    {packingDetail?.container_size}

                                    </Typography>
                                </Typography>

                                {/* Seal Number */}
                                <Typography gap={1} display={"flex"} variant="body1" className={classes.text}>
                                    Seal No:
                                    <Typography fontFamily={FontFamily?.NunitoRegular} component="span" className={classes.text}>
                                    {packingDetail?.seal_no}

                                    </Typography>
                                </Typography>
                            </Grid>

                        </Grid>

                        {/* Items Table */}
                        <TableContainer component={Paper} sx={{ boxShadow: '0px 8px 18px 0px #9B9B9B1A', borderRadius: 2, mt: 2 }} className='table-box'>
                            <Table stickyHeader sx={{ minWidth: 500 }}>
                                <TableHead>
                                    <TableRow>
                                        {tableHead.map((item, index) => (
                                            <Cell className='pdf-table' key={index}>{item}</Cell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {packingDetail?.packingList?.map((item, index) => (
                                        <Row key={index} sx={{ bgcolor: index % 2 !== 0 && '#EEFBEE' }}>
                                            <Cell >{index + 1}</Cell>
                                            <Cell >{item.hss_code}</Cell>
                                            <Cell >{item.veh_make?.name}</Cell>
                                            <Cell >{item.vin}</Cell>
                                            <Cell >{item.coo}</Cell>
                                            <Cell >{item.weight}</Cell>
                                            <Cell >{item.veh_model?.name}</Cell>
                                            <Cell >{item.value}</Cell>
                                        </Row>
                                    ))}
                                    {/* Total */}
                                    <TableRow sx={{ backgroundColor: Colors.primary, color: '#FFFFFF', fontFamily: FontFamily.Halvetica }}>
                                        <Cell style={{ color: "#FFFFFF" }} colSpan={5} s>Total</Cell>
                                        <Cell style={{ color: "#FFFFFF" }}>{packingListData.totalWeight}</Cell>
                                        <Cell></Cell>
                                        <Cell style={{ color: "#FFFFFF" }} >{packingListData.totalValue}</Cell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Ports and Total Details */}
                        <Grid container spacing={2} sx={{ mt: 2 }} className={classes.gridContainer}>
                            {/* Port Details */}
                            <Grid item xs={6}>
                                <Typography gap={1} display={"flex"} className={classes.textBold}>
                                    Port of Loading:
                                    <Typography fontFamily={FontFamily.NunitoRegular} component="span" className={classes.textNormal}>
                                        {packingDetail?.port_of_loading}
                                    </Typography>
                                </Typography>
                                <Typography gap={1} display={"flex"} className={classes.textBold}>
                                    Port of Discharge:
                                    <Typography fontFamily={FontFamily.NunitoRegular} component="span" className={classes.textNormal}>
                                        {packingDetail?.port_of_discharge}
                                    </Typography>
                                </Typography>
                            </Grid>

                            {/* Amount in Words and Total Pieces */}
                            <Grid item xs={6}>
                                <Typography gap={1} display={"flex"} className={classes.textBold}>
                                    Amount in Words:
                                    <Typography fontFamily={FontFamily.NunitoRegular} component="span" className={classes.textNormal}>
                                        {packingDetail?.amount}
                                    </Typography>
                                </Typography>
                                <Typography gap={1} display={"flex"} className={classes.textBold}>
                                    Total no. of Pcs:
                                    <Typography fontFamily={FontFamily.NunitoRegular} component="span" className={classes.textNormal}>
                                        {packingDetail?.packingList?.length}
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>

                        {/* Terms & Conditions */}
                        <Box sx={{ mt: 2, padding: '10px 0' }}>
                            <Typography variant="h6" className={classes.text} color={"#0C6135"} sx={{ textDecoration: 'underline' }} >
                                TERMS & CONDITIONS
                            </Typography>
                            <Typography className={classes.text} fontFamily={FontFamily.NunitoRegular}>
                                1. We will not bear any loss or liability for the shipment.
                            </Typography>
                            <Typography fontFamily={FontFamily.NunitoRegular} className={classes.text}>
                                2. Kindly review the invoice and packing list for accuracy.
                            </Typography>
                            <Typography fontFamily={FontFamily.NunitoRegular} className={classes.text}>
                                3. All payments must be made in advance.
                            </Typography>
                        </Box>

                        {/* Footer with QR Code */}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <QRCode value={packingListData.invoiceNo} size={100} />
                        </Box>
                    </Paper>
                </PDFExport>
            </Box>
        </Box>
    );
};

export default PackingListInvoice;
