import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TableRow,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import Colors from "assets/Style/Colors";
import { FontFamily, EyeIcon } from "assets";
import { CircleLoading } from "components/Loaders";
import styled from "@emotion/styled";
import { ErrorToaster } from "components/Toaster";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import SelectField from "components/Select";
import { useForm } from "react-hook-form";
import InputField from "components/Input";
import InputPhone from "components/InputPhone";
import SystemServices from "services/System";
import UploadFile from "components/UploadFile";
import { PrimaryButton } from "components/Buttons";
import ExportServices from "services/Export";
import { getFileSize, CleanTypes } from "utils";
import instance from "config/axios";
import routes from "services/System/routes";
import Uploading from "components/Uploading";
import { SuccessToaster } from "components/Toaster";
import DatePicker from "components/DatePicker";
// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function AddVendorAgent() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [intCode, setIntCode] = useState();
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectAgent, setSelectedAgent] = useState("");
  const [selectCurrency, setSelectCurrency] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [selectedLoadingPort, setSelectedLoadingPort] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploadedSize, setUploadedSize] = useState(0);
  const [slipDetail, setSlipDetail] = useState([]);
  const [slipLink, setSlipLink] = useState([]);
  const [documentDetail, setDocumentDetail] = useState([]);
  const [documentLink, setDocumentLink] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const allowFilesType = ["image/png", "image/jpg", "image/jpeg"];

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm();
  useEffect(() => {
    getCountries();
  }, []);
  //*Handle Date
  const handleDate = (newDate) => {
    try {
      if (newDate === 'Invalid Date') {
        setSelectedDate('invalid');
        return;
      }
      setSelectedDate(new Date(newDate));
      //   setValue(new Date(newDate));
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const contentRef = useRef(null);
  const agentType = [{ name: "Recovery Agent" }, { name: "Port Agent" }];
  const currencyType = [{ name: "AED" }, { name: "USD" }];
  const getCountries = async () => {
    try {
      const { data } = await SystemServices.getCountries();
      // const country = data?.nations.rows.find(
      //   (e) => e?.id === state?.country_id
      // );
      setCountries(data?.nations.rows);
      // setSelectedCountry(country);
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleAgentSelect = async (agent) => {
    setSelectedLoadingPort(agent);
    const res = await ExportServices.getRecoveryAgentCode(agent);
    if (res?.status) {
      setAgentCode(res?.data?.agent_code);
    }
  };
  const handleCurrency = async (currency) => {
    setSelectCurrency(currency);
  };
  const AddAgent = async (formData) => {
    const obj = {
      customer_type: formData?.agentType,
      name: formData.agentName,
      uae_phone: formData?.mobileNumber,
      nationality_id: selectedCountry?.id,
      currency: formData?.currencyTpye,
      code: agentCode,
      ap_documents: formData.ap_documents,
      trade_license_number: formData.trade_license_number,
      passport_id_number: formData.passport_id_number,
      reference_person_name: formData.reference_person_name,
      reference_person_number: formData.reference_person_number,
      broker_type_id: formData?.agentType === 'Recovery Agent' ? 5 : 6,
      agent_c_date: selectedDate
    };
    console.log(obj, "objobj")
    const res = await ExportServices.addRecoveryAgent(obj);
    if (res?.status) {
      SuccessToaster(res?.message);
      navigate("/vendor-agent-list")
      reset();
    } else {
      ErrorToaster(res?.message);
    }
  };
  const removeDoc = () => {
    try {
      setDocumentDetail([]);
      setDocumentLink("");
    } catch (error) {
      ErrorToaster(error);
    }
  };
  const handleUploadDocument = async (e) => {
    try {
      e.preventDefault();
      const file = e.target.files[0];
      let arr = [
        {
          name: file?.name,
          file: "",
          type: file?.type.split("/")[1],
          size: getFileSize(file.size),
          isUpload: false,
        },
      ];
      if (allowFilesType.includes(file.type)) {
        setDocumentDetail(arr);
        handleUpload(file, arr);
      } else {
        ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };

  const handleUpload = async (file, docs) => {
    setProgress(0);
    try {
      const formData = new FormData();
      formData.append("document", file);
      const { data } = await instance.post(routes.uploadDocuments, formData, {
        onUploadProgress: (progressEvent) => {
          const uploadedBytes = progressEvent.loaded;
          const percentCompleted = Math.round(
            (uploadedBytes * 100) / progressEvent.total
          );

          setProgress(percentCompleted);
          setUploadedSize(getFileSize(uploadedBytes));
        },
      });

      if (data) {
        const nations = data?.data?.nations;

        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setDocumentDetail(docs);
        setDocumentLink([nations]);
      }
      if (data) {
        docs[0].isUpload = true;
        docs[0].file = data?.data?.nations;
        setSlipDetail(docs);
        setSlipLink(data?.data?.nations);
      }
    } catch (error) {
      ErrorToaster(error);
    }
  };
  return (
    <Box sx={{ m: 4, mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mr: 4,
          my: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          Add Vendor Agent
        </Typography>
      </Box>
      <Box
        component={"form"}
        onSubmit={handleSubmit(AddAgent)}
        sx={{
          boxShadow: "0px 3px 10px 0px #00000040",
          p: 3,
          borderRadius: "15px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectField
              label={"Agent Type"}
              options={agentType}
              selected={selectedLoadingPort}
              error={errors?.agentType?.message}
              onSelect={(value) => handleAgentSelect(value)}
              register={register("agentType", {
                required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Agent Code"}
              placeholder={"Agent Code"}
              disabled={true}
              value={agentCode}
              register={register("code", {})}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Agent Name"}
              placeholder={"Agent Name"}
              error={errors?.agentName?.message}
              register={register("agentName", {
                required: "Please enter Agent name.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputPhone
              label={"Mobile Number"}
              name={"mobileNumber"}
              control={control}
              error={errors?.mobileNumber?.message}
              onBlur={(e, v) => setIntCode(v.dialCode)}
              rules={{
                required: "Please enter your international mobile number.",
              }}
              register={register("mobileNumber", {
                required: "Please enter number.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              size="small"
              label={"Country"}
              options={countries}
              selected={selectedCountry}
              onSelect={(value) => setSelectedCountry(value)}
              error={errors?.country?.message}
              register={register("country", {
                required: "Please select Country.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectField
              label={"Currency Type"}
              options={currencyType}
              selected={selectCurrency}
              error={errors?.currencyTpye?.message}
              onSelect={(value) => handleCurrency(value)}
              register={register("currencyTpye", {
                required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Passport/Emirates ID Number"}
              placeholder={"Passport/Emirates ID Number"}
              register={register("passport_id_number", {
                // required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Trade License Number"}
              placeholder={"Trade License Number"}
              register={register("trade_license_number", {
                // required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Reference Person Name"}
              placeholder={"Reference Person Name"}
              register={register("reference_person_name", {
                // required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              label={"Reference Person Number"}
              placeholder={"Reference Person Number"}
              register={register("reference_person_number", {
                // required: "Please select Agent type.",
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={'Creation Date'}
              size='small'
              value={selectedDate}
              error={errors?.etaDateVal?.message}
              register={register('agent_c_date')}
              onChange={(date) => handleDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box sx={{ display: "flex", gap: 1 }}>
              {/* <Typography
                variant="body1"
                sx={{ color: Colors.charcoalGrey, mb: 1 }}
              >
                Add Attachment
              </Typography> */}
              {/* <InfoIcon sx={{ color: Colors.primary, cursor: 'pointer' }} onClick={handleDownload} /> */}
            </Box>
            {/* <UploadFile
              accept={"image/png, image/jpg, image/jpeg"}
              error={errors?.depositSlip?.message}
              style={{
                backgroundColor: "#e7efeb",
                width: "100%",
                height: "150px",
                display: "flex",
                flexDirection: "column-reverse",
                padding: "20px",
                border: "1px dashed #aeb1b0",
                borderRadius: "10px",
              }}
              Memo={true}
              register={register("depositSlip", {
                required: "",
                onChange: (e) => handleUploadDocument(e),
              })}
            /> */}
          </Grid>
          <Grid item xs={12}>
            {documentDetail.length > 0 && (
              <Typography
                variant="body1"
                sx={{ color: Colors.charcoalGrey, mb: 1 }}
              >
                Uploaded Files
              </Typography>
            )}
            <Box sx={{ maxHeight: 300, overflow: "auto", pr: 1 }}>
              {documentDetail?.map((item, index) => (
                <Uploading
                  key={index}
                  data={item}
                  uploadedSize={uploadedSize}
                  progress={progress}
                  removeDoc={() => removeDoc()}
                />
              ))}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              ml: 0.5,
              mt: 4.5,
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <PrimaryButton
              title="Submit"
              type="submit"
              style={{ backgroundColor: Colors.bluishCyan }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default AddVendorAgent;
