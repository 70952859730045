import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from "@mui/material";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { FontFamily, SearchIcon, EditIcon, DownloadIcon } from "assets";
import Colors from "assets/Style/Colors";
import VehicleBookingServices from "services/VehicleBooking";
import { ErrorToaster } from "components/Toaster";
import { CircleLoading } from "components/Loaders";
import Pagination from "components/Pagination";
import { PrimaryButton } from "components/Buttons";
import InputField from "components/Input";
import { useForm } from "react-hook-form";
import moment from "moment";
import {
  Debounce,
  formatPermissionData,
  handleExportWithComponent,
} from "utils";

import { PDFExport } from "@progress/kendo-react-pdf";
import ExportServices from "services/Export";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",

    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
      ".MuiBox-root": {
        cursor: "pointer",
      },
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
      textWrap: "nowrap",
    },
    ".MuiButtonBase-root": {
      padding: "8px",
      width: "28px",
      height: "28px",
    },
  },
}));

const useStyles = makeStyles({
  loaderWrap: {
    display: "flex",
    height: 100,
    "& svg": {
      width: "40px !important",
      height: "40px !important",
    },
  },
});

function VendorAgentList() {
  const navigate = useNavigate();
  const classes = useStyles();
  const contentRef = useRef(null);
  const tableHead = [
    "C.Date",
    "Agent Type",
    "Agent Name",
    "Agent Code",
    "Mobile Number",
    "Country",
    "Passport/E.ID",
    "Trade License",
    "Ref-Person",
    "Ref-Person Num",
    // "Currency (AED)",
    "Currency",
    // "Actions",
  ];

  const { register, handleSubmit } = useForm();
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // *For Dialog Box
  const [approvalRequestDialog, setApprovalRequestDialog] = useState(false);

  // *For Approval List
  const [approvalList, setApprovalList] = useState();
  const [approvalDetail, setApprovalDetail] = useState();
  const [bookingId, setBookingId] = useState();
  const [approvalId, setApprovalId] = useState();
  const [agentsList, setAgentsList] = useState();
  // *For Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  // *For Filters
  const [filters, setFilters] = useState({});
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  // *For Permissions
  const [permissions, setPermissions] = useState();

  // *For Handle Date
  useEffect(() => {
    getRecoverAgentList();
  }, []);
  // *For Get Approval List
  const getApprovalList = async (page, limit, filter) => {
    setLoader(true);
    try {
      const Page = page ? page : currentPage;
      const Limit = limit ? limit : pageLimit;
      const Filter = { ...filters, ...filter };
      setCurrentPage(Page);
      setPageLimit(Limit);
      setFilters(Filter);
      let params = {
        page: Page,
        limit: Limit,
      };
      params = { ...params, ...Filter };
      const { data } = await VehicleBookingServices.getApprovalList(params);
      setApprovalList(data?.approvals?.rows);
      setTotalCount(data?.approvals?.count);
      setPermissions(formatPermissionData(data?.permissions));
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };
  const getRecoverAgentList = async (page, limit, filter) => {
    const Page = page ? page : currentPage;
    const Limit = limit ? limit : pageLimit;
    const Filter = { ...filters, ...filter };
    setCurrentPage(Page);
    setPageLimit(Limit);
    setFilters(Filter);
    let params = {
      page: Page,
      limit: Limit,
    };
    params = { ...params, ...Filter };
    const res = await ExportServices.getRecoveryAgentList(params);

    if (res?.status) {
      setAgentsList(res?.data?.recovery_agents?.rows);
    }
  };
  const getAgentDetail = async (data) => {
    const res = await ExportServices.getRecoveryAgentDetail(data);
  };
  // *For Get Approval Detail
  const getApprovalDetail = async (id) => {
    try {
      let params = {
        approval_id: id,
      };
      let { data } = await VehicleBookingServices.getApprovalDetail(params);
      let oldData = data?.details?.edited_data?.old_data;
      let newData = data?.details?.edited_data?.old_data;

      let obj = {
        ...data.details,
        oldData: oldData,
        newData: newData,
      };

      setApprovalDetail(obj);
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // *For Handle Filter
  const handleFilter = (data) => {
    Debounce(() => getAgentDetail(data));
  };

  useEffect(() => {
    getApprovalList();
  }, []);

  return (
    <Box sx={{ m: 4, mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mr: 4,
          my: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          Vendor Agent List
        </Typography>
      </Box>

      {/* Filters */}
      <Box
        sx={{
          boxShadow: " 0px 3px 10px 0px #00000040",
          p: 3,
          borderRadius: "15px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            width: "100%",
          }}
          container
          spacing={1}
          item
        >
          <Grid item xs={12} sm={5}>
            <InputField
              size={"small"}
              inputStyle={{ backgroundColor: "#f5f5f5" }}
              label={"Search"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={"Search"}
              register={register("Search", {
                onChange: (e) => handleFilter({ search: e.target.value }),
              })}
            />
          </Grid>
          <Grid sx={{ ml: 0.5, mt: 4.5 }}>
            <PrimaryButton
              title="Search"
              type="button"
              style={{ backgroundColor: Colors.bluishCyan }}
              onClick={() => handleExportWithComponent(contentRef)}
            />
          </Grid>
        </Grid>

        {approvalList ? (
          <Fragment>
            <PDFExport
              ref={contentRef}
              landscape={true}
              paperSize="A4"
              margin={5}
              fileName="Approval Record"
            >
              {/* ========== Table ========== */}
              <TableContainer
                component={Paper}
                sx={{
                  boxShadow: "0px 8px 18px 0px #9B9B9B1A",
                  borderRadius: 2,
                  maxHeight: "calc(100vh - 330px)",
                }}
                className="table-box"
              >
                <Table stickyHeader sx={{ minWidth: 500 }}>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((item, index) => (
                        <Cell className="pdf-table" key={index}>
                          {item}
                        </Cell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loader ? (
                      agentsList?.length > 0 ? (
                        <Fragment>
                          {agentsList.map((item, index) => (
                            <Row
                              key={index}
                              sx={{ bgcolor: index % 2 !== 0 && "#EFF8E7" }}
                            >
                              <Cell className="pdf-table">
                                {item?.customerProfile?.agent_c_date && moment(item?.customerProfile?.agent_c_date).format("DD-MMM-YY")}
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.customerProfile?.type?.name}
                              </Cell>
                              <Cell className="pdf-table">
                                <Tooltip
                                  className="pdf-hide"
                                  title={item?.name}
                                  arrow
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [10, -2],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  {item?.name}
                                </Tooltip>
                                <Box
                                  component={"div"}
                                  className="pdf-show"
                                  sx={{ display: "none !important" }}
                                >
                                  {item?.name ?? "-"}
                                </Box>
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.agent_code}
                              </Cell>
                              <Cell className="pdf-table ">
                                <Tooltip
                                  className="pdf-hide"
                                  title={item?.uae_phone ?? "-"}
                                  arrow
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [10, -2],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  {item?.uae_phone}
                                </Tooltip>
                                <Box
                                  sx={{ display: "none !important" }}
                                  component={"div"}
                                  className="pdf-show"
                                >
                                  {item?.uae_phone ?? "-"}
                                </Box>
                              </Cell>
                              <Cell className="pdf-table">
                                <Tooltip
                                  className="pdf-hide"
                                  title={
                                    item?.customerProfile?.nationality?.name ??
                                    "-"
                                  }
                                  arrow
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [10, -2],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  {item?.customerProfile?.nationality?.name}
                                </Tooltip>
                                <Box
                                  component={"div"}
                                  className="pdf-show"
                                  sx={{ display: "none !important" }}
                                >
                                  {item?.customerProfile?.nationality?.name ??
                                    "-"}
                                </Box>
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.customerProfile?.passport_id_number}
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.customerProfile?.trade_license_number}
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.customerProfile?.reference_person_name}
                              </Cell>
                              <Cell className="pdf-table">
                                {item?.customerProfile?.reference_person_number}
                              </Cell>
                              {/* <Cell className="pdf-table">
                                <Tooltip
                                  className="pdf-hide"
                                  title={
                                    item?.customerProfile?.currency === "Aed"
                                      ? item?.customerProfile?.currency
                                      : "-"
                                  }
                                  arrow
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [10, -2],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  {item?.customerProfile?.currency === "Aed"
                                    ? item?.customerProfile?.currency
                                    : "-"}
                                </Tooltip>
                                <Box
                                  component={"div"}
                                  className="pdf-show"
                                  sx={{ display: "none !important" }}
                                >
                                  {item?.customerProfile?.currency === "Aed"
                                    ? item?.customerProfile?.currency
                                    : "-"}
                                </Box>
                              </Cell> */}
                              <Cell className="pdf-table">
                                <Tooltip
                                  className="pdf-hide"
                                  title={
                                    item?.customerProfile?.currency === "Usd"
                                      ? item?.customerProfile?.currency
                                      : "-"
                                  }
                                  arrow
                                  placement="top"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [10, -2],
                                          },
                                        },
                                      ],
                                    },
                                  }}
                                >
                                  {item?.customerProfile?.currency}
                                </Tooltip>
                                <Box
                                  component={"div"}
                                  className="pdf-show"
                                  sx={{ display: "none !important" }}
                                >
                                  {item?.customerProfile?.currency === "Usd"
                                    ? item?.customerProfile?.currency
                                    : "-"}
                                </Box>
                              </Cell>
                              <Cell
                                sx={{
                                  flexDirection: "row",
                                  display: "flex",
                                  gap: 1,
                                  textDecorationLine: "underline",
                                  textDecorationColor: Colors.green,
                                }}
                                className=""
                              >
                                {/* <Box
                                  component={"div"}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {}}
                                >
                                  <span className="">
                                    <DownloadIcon />
                                  </span>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: "700",
                                      color: Colors.green,
                                    }}
                                  >
                                    {"Attachment"}
                                  </Typography>
                                </Box>
                                <Box
                                  component={"div"}
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {}}
                                >
                                  <span className="">
                                    <EditIcon />
                                  </span>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: "700",
                                      color: Colors.green,
                                    }}
                                  >
                                    {"Edit"}
                                  </Typography>
                                </Box> */}
                              </Cell>
                            </Row>
                          ))}
                        </Fragment>
                      ) : (
                        <Row>
                          <Cell
                            colSpan={tableHead.length + 1}
                            align="center"
                            sx={{ fontWeight: 600 }}
                          >
                            No Data Found
                          </Cell>
                        </Row>
                      )
                    ) : (
                      <Row>
                        <Cell
                          colSpan={tableHead.length + 2}
                          align="center"
                          sx={{ fontWeight: 600 }}
                        >
                          <Box className={classes.loaderWrap}>
                            <CircularProgress />
                          </Box>
                        </Cell>
                      </Row>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </PDFExport>
            {/* ========== Pagination ========== */}
            <Pagination
              currentPage={currentPage}
              pageSize={pageLimit}
              onPageSizeChange={(size) => getApprovalList(1, size.target.value)}
              tableCount={approvalList?.length}
              totalCount={totalCount}
              onPageChange={(page) => getApprovalList(page, "")}
            />
          </Fragment>
        ) : (
          <CircleLoading />
        )}
      </Box>
    </Box>
  );
}

export default VendorAgentList;
