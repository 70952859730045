import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";
import { FontFamily } from "assets";
const ShippingRA = ({ resultData }) => {
  const classes = useStyles();

  return (
    <>
      <TableBody>
        {resultData?.map((item, index) => (
          <>
            <TableRow
              sx={{
                fontSize: "8px !important",
                background: index % 2 !== 0 ? "#EFF8E7" : "",
              }}
            >
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.ref_id}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.nationality ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item.name}
              </TableCell>{" "}
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {CommaSeparator(parseFloat(item?.unpaidBooking).toFixed(2)) ??
                  "-"}
              </TableCell>
              <TableCell
                colSpan={2}
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                $
                {CommaSeparator(parseFloat(item?.totalReceivable).toFixed(2)) ??
                  "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                $
                {CommaSeparator(parseFloat(item?.shippingVault).toFixed(2)) ??
                  "-"}
              </TableCell>{" "}
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                $
                {CommaSeparator(parseFloat(item?.netReceivable).toFixed(2)) ??
                  "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {CommaSeparator(parseFloat(item?.otw).toFixed(2)) ?? "-"}{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                ${CommaSeparator(parseFloat(item?.otwValue).toFixed(2)) ?? "-"}{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {CommaSeparator(parseFloat(item?.yard).toFixed(2)) ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                ${CommaSeparator(parseFloat(item?.yardValue).toFixed(2)) ?? "-"}{" "}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.netReceivable == 0
                  ? parseFloat(0).toFixed(2)
                  : CommaSeparator(
                      parseFloat(
                        (parseFloat(item?.otwValue) +
                          parseFloat(item?.yardValue)) /
                          parseFloat(item?.netReceivable)
                      ).toFixed(2)
                    ) ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.unpaidBooking == 0
                  ? parseFloat(0).toFixed(2)
                  : CommaSeparator(
                      parseFloat(
                        (parseFloat(item?.otw) + parseFloat(item?.yard)) /
                          parseFloat(item?.unpaidBooking)
                      ).toFixed(2)
                    ) ?? "-"}
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "6px !important",
                  fontWeight: 600,
                  fontFamily: FontFamily.openSans,
                  margin: "0px 4px !important",
                  padding: "4px 8px !important",
                  textAlign: "center",
                }}
                className={`${classes.columnStyle}`}
              >
                {item?.netReceivable == 0
                  ? parseFloat(0).toFixed(2)
                  : CommaSeparator(
                      parseFloat(
                        (parseFloat(item?.shippingVault).toFixed(2) /
                          parseFloat(item?.totalReceivable).toFixed(2)) *
                          100
                      ).toFixed(2)
                    ) ?? "-"}%
              </TableCell>
            </TableRow>
          </>
        ))}
      </TableBody>
    </>
  );
};

export default ShippingRA;
