import React from 'react';
import { Box, CardMedia, Container, Grid, IconButton, Typography } from '@mui/material';
import { FontFamily, Images, PlaneIcon, ShieldIcon, WorldIcon } from 'assets';
import { makeStyles } from '@mui/styles';
import Colors from 'assets/Style/Colors';

const useStyles = makeStyles({
  iconWrap: {
    width: 60,
    height: 60,
    backgroundColor: 'transparent',
    border: `1px dashed ${Colors.primary} !important`,
  },
  callBox: {
    padding: '24px 12px',
    textAlign: 'center',
    position: 'relative',
    zIndex: '1',
    cursor: 'pointer',
    "&:before": {
      content: "''",
      backgroundColor: Colors.primary,
      transform: `skewX(-17deg)`,
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      zIndex: '-1'
    }
  }
});

function TopBar() {

  const classes = useStyles();

  const services = [
    { icon: <PlaneIcon />, title: 'Domestic and International Service' },
    { icon: <WorldIcon />, title: 'End to End Tracking Service' },
    { icon: <ShieldIcon />, title: 'Safe and Guaranteed Assured' },
  ]

  return (
    <CardMedia image={Images.footerTopBg} sx={{ py: '70px', borderBottom: `1px solid ${Colors.white + '1f'}` }}>
      <Container>
        <Grid container spacing={1} alignItems={'center'} justifyContent={'space-between'}>
          {services.map((item, index) => (
            <Grid key={index} item md={2.6} sx={{ textAlign: 'center' }} width={'100%'}>
              <IconButton className={classes.iconWrap}>
                {item.icon}
              </IconButton>
              <Typography variant="h6" sx={{ color: Colors.white, mt: 2 }}>
                {item.title}
              </Typography>
            </Grid>
          ))}
          <Grid item md={4} width={'99%'}>
            <Box className={classes.callBox}>
              <Typography variant="h3" sx={{ color: Colors.white }}>
                (+971) 6 510 2000
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 1, color: Colors.white, textTransform: 'capitalize', fontFamily: FontFamily.NunitoRegular }}>
                Feel free to call us
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </CardMedia>
  );
}

export default TopBar;