import { TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { CommaSeparator, LedgerLinking } from "utils";
import { useStyles } from "./styles";
import { FontFamily } from "assets";
import moment from "moment";
const CustomerVehicleLedger = ({ resultData }) => {
  const classes = useStyles();
  let Balance = 0;

  return (
    <>
      <TableBody>
        {resultData?.map((item, index) => {
          let page = LedgerLinking(item?.entry?.reference_module);
          const balance =
            item?.account?.nature === "credit"
              ? (
                  parseFloat(item?.credit_cur) - parseFloat(item?.debit_cur)
                ).toFixed(2)
              : (
                  parseFloat(item?.debit_cur) - parseFloat(item?.credit_cur)
                ).toFixed(2);
          Balance += parseFloat(balance);
          return (
            <>
              <TableRow
                sx={{
                  fontSize: "8px !important",
                  background: index % 2 !== 0 ? "#EFF8E7" : "",
                }}
              >
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.created_at
                    ? moment(item?.created_at).format("DD/MM/YY")
                    : "-"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.journal_id ? item?.series_id + item?.journal_id : "-"}{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    wordBreak: "break-all",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item.entry?.reference_no}{" "}
                </TableCell>{" "}
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.type?.type_name ?? "-"}{" "}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.description}
                </TableCell>{" "}
                <TableCell
                  colSpan={2}
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  {item?.comment}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(item?.debit_cur)}{" "}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(item?.credit_cur)}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "6px !important",
                    fontWeight: 600,
                    fontFamily: FontFamily.openSans,
                    margin: "0px 4px !important",
                    padding: "4px 8px !important",
                    textAlign: "center",
                  }}
                  className={`${classes.columnStyle}`}
                >
                  ${CommaSeparator(Balance)}
                </TableCell>
              </TableRow>
            </>
          );
        })}
      </TableBody>
    </>
  );
};

export default CustomerVehicleLedger;
