const routes = {
  createVendor: 'vendors/add',
  getVendorCenter: 'vendors',
  getVendorDropdown: 'vendors/dropdown',
  getShippingRates: 'vendors/shippingRates',
  getShippingRatesComparison: 'vendors/shippingRates/comparison',
  updateShippingRate: 'vendors/shippingRates/update',
  uploadShippingRate: 'vendors/shippingRates/upload',
  getTowingRates: 'vendors/towingRates',
  getTowingRatesComparison: 'vendors/towingRates/comparison',
  updateTowingRate: 'vendors/towingRates/update',
  uploadTowingRate: 'vendors/towingRates/upload',
  getClearanceRates: 'vendors/clearanceRates',
  getClearanceRatesComparison: 'vendors/clearanceRates/comparison',
  updateClearanceRate: 'vendors/clearanceRates/update',
  uploadClearanceRate: 'vendors/clearanceRates/upload',
  getVendorCosting: 'vendors/costings',
  updateVendorCosting: 'vendors/costings/update',
  createTT: 'vendors/tt/add',
  getTT: 'vendors/tt',
  getTTDetail: 'vendors/tt/details',
  getTTPreview: 'vendors/tt/preview',
  applyFund: 'vendors/funds/apply',
  getVendorFundApproval: 'vendors/funds/approvals',
  approveRejectStatus: 'vendors/funds/approve',
  getVendorAppliedFunds: 'vendors/funds',
  getVendorAppliedFundsDetail: 'vendors/funds/details',
  CreateDamage:'vendors/damages/book',
  getDamages:'vendors/damages',
  getDamageDetail:'vendors/damages/details',
  UpdateTT:'vendors/tt/update',
  getDamagePreview:'vendors/damages/preview'
};

export default routes